<template>
  <v-app>
    <v-main>
      <v-dialog v-model="dialogData" persistent class="v-dialog">
        <v-toolbar color="#004c89" style="color: white" :title="title"></v-toolbar>
        <v-card class="div-infos">
          <h1 id="linha-1">
            {{ resource.linha1 }}
          </h1>

          <h2 class="outras-linhas">
            {{ resource.linha2 }}
          </h2>

          <h2 v-if="estilo_apontamento == 'PROCESSO_PRODUTIVO' ||
            estilo_apontamento == 'Processo Produtivo'
            " class="outras-linhas">
            Tp. Serv.: {{ resource.tiposervico }}
          </h2>

          <h2 class="outras-linhas">{{ dataEntrega }}</h2>

          <div v-if="estilo_apontamento == 'PROCESSO_PRODUTIVO' ||
            estilo_apontamento == 'Processo Produtivo'" style="display: flex;">
            <h2 class="outras-linhas">
              Nº Proc. Prod.: {{ resource.linha4 }}
            </h2>
  
            <h2 class="outras-linhas" style="margin-left: 30px">
              Nº da Seq.: {{ resource.seq }}
            </h2>
          </div>

          <h2 class="outras-linhas">
            {{ resource.cliente }}
          </h2>
        </v-card>
        <div class="modal-footer" style="background-color: #004c89">
          <button class="btns-modal" id="btn-cancelar" @click="$emit('FecharModal')" style="margin-left: 5px; background: #0B5ED7; color: #fff; width: 30% !important;">Cancelar</button>

          <div class="infosCard-div-btns" style="width: 60%; display: flex; justify-content: space-between; margin-right: 5px">
            <button @click="OnClickMotivosDeParada" class="btns-modal" id="btn-mp-infosCard"
              style="width: 45px; color: #fff; background: black">MP</button>
            <button @click="OnClickAbrirApontamento" class="btns-modal" id="btn-abrir-infosCard"
              style="width: 45px; color: #fff; background: #157347">Abrir</button>
          </div>
        </div>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { RequestGETTIPOServicos, ResquestGETMotivoDeParada } from "../../../../../../CPSWeb/front-end/services/ApiRequest"

export default {
  name: "infosCard",

  props: {
    dialog: Boolean,
    resource: Object,
    dataEntrega: String,
    host: String,
  },

  emits: ["OnMotivoDeParada", "FecharModal", "OnShowModalTPServicos", "OnHabilitaBtnsCards", "OnHabilitaDIVPrincipal", "AbrindoApontamento", "OnAbrirApontamento"],

  data(){
    return{
      estilo_apontamento: null,
      title: "",
      dialogData : false
    }
  },

  created(){
    if (!(localStorage.getItem("estilo_apontamento_client") == undefined)) {
      if (localStorage.getItem("estilo_apontamento_client") == "Padrão") {
        this.estilo_apontamento = localStorage.getItem("estilo_apontamento");
      } else {
        this.estilo_apontamento = localStorage.getItem(
          "estilo_apontamento_client"
        );
      }
    } else {
      this.estilo_apontamento = localStorage.getItem("estilo_apontamento");
    }

    if (localStorage.getItem("estilo_apontamento") == "OS"){
      this.title = "Detalhes do Serviço";
    }else{
      this.title = "Detalhes do Processo";
    }
  },

  mounted(){
    this.DicasAtalho();
  },

  methods: {
    OnClickAbrirApontamento(e) {
      this.disabledBtn(true);
      this.AnimacaoBtns("#" + e.target.id);

      localStorage.setItem("cod_os", this.resource.cod_os);
      sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
      sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);

      this.$emit("OnHabilitaBtnsCards", true);
      this.$emit("OnHabilitaDIVPrincipal", true);

      sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
      localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
      sessionStorage.setItem("OS", this.resource.cod_os_completo);
      localStorage.setItem("qtdPecas", 0);

      if (localStorage.getItem("informar_tipo_servico") == "true") {
        this.GETTiposDeServico();
      } else {
        this.$emit('FecharModal');
        this.$emit("AbrindoApontamento", true);
        this.$emit("OnAbrirApontamento");
      }
    },

    OnClickMotivosDeParada(e) {
      this.disabledBtn(true);
      this.AnimacaoBtns("#" + e.target.id);

      localStorage.setItem("cod_os", this.resource.cod_os);
      sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
      this.$emit("OnHabilitaBtnsCards", true);
      this.$emit("OnHabilitaDIVPrincipal", true);
      sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
      localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
      sessionStorage.setItem("OS", this.resource.cod_os_completo);
      localStorage.setItem("qtdPecas", 0);
      this.GETMotivosDeParada();
    },


    GETTiposDeServico() {
      RequestGETTIPOServicos(
        this.host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave")
      )
        .then(this.afterGetTpServicos)
        .catch(this.alertErro);
    },

    afterGetTpServicos(res) {
      this.$emit("OnShowModalTPServicos", res.data);
    },

    GETMotivosDeParada() {
      ResquestGETMotivoDeParada(
        this.host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave")
      )
        .catch(this.alertErro)
        .then(this.afterGetMotivosDeParada);
    },

    afterGetMotivosDeParada(res) {
      this.$emit("OnMotivoDeParada", res.data, "apontamento_normal");
    },

    disabledBtn(value){
      if (value){
        document.querySelector("#btn-abrir-infosCard").setAttribute("disabled", true);
        document.querySelector("#btn-mp-infosCard").setAttribute("disabled", true);
        document.querySelector("#btn-cancelar").setAttribute("disabled", true);
      }
      else{
        document.querySelector("#btn-abrir-infosCard").removeAttribute("disabled");
        document.querySelector("#btn-mp-infosCard").removeAttribute("disabled");
        document.querySelector("#btn-cancelar").removeAttribute("disabled");
      }
    },

    AnimacaoBtns(btn) {
      $(btn).css("animation", "1s");
      $(btn).css("opacity", "0.5");

      setTimeout(function () {
        $(btn).css("animation", "1s");
        $(btn).css("opacity", "1");
      }, 200);
    },

    DicasAtalho(){
      const btnAbrir = document.querySelector("#btn-abrir-infosCard");
      const btnMP = document.querySelector("#btn-mp-infosCard");

      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) == false
      ) {
        btnMP.innerHTML = "MP &lt;F2&gt;";
        btnAbrir.innerHTML = "Abrir &lt;E&gt;";
      }
    }
  }
}
</script>

<style scoped>
.div-infos{
  height: 575px; 
  overflow-y: auto; 
  margin-bottom: 46px;
}

#linha-1 {
  font-size: clamp(1.2rem, 0.3rem + 1vw, 1vw);
  margin: 5px 0 5px 9px;
  word-wrap: break-word;
  font-weight: 900;
  font-family: "Poppins";
  color: blue;
}

.outras-linhas {
  font-size: clamp(1.2rem, 0.3rem + 1vw, 1vw);
  margin: 0 0 5px 9px;
  word-wrap: break-word;
  font-weight: 900;
  font-family: "Poppins";
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.btns-modal {
  margin: 5px 0;
  width: 40% !important;
  font-size: 16px;
  padding: 6px 0;
  color: #fff;
  border: none;
  font-weight: 600;
  border-radius: 5px;
}

@media (width < 480px){
  .btns-modal {
    width: 40%;
    font-size: 13px;
  }

  .div-infos{
    margin-bottom: 40px;
  }
}
</style>