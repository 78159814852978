<template>
    <div class="progressChart" style="width: 100%; display: flex; justify-content: center; align-items: center; margin: 20px; border: none">
        <v-progress-circular :color="color" indeterminate :size="size" :width="width"></v-progress-circular>
    </div>
</template>

<script>

export default {
    name : "ProgressCharts",
    props : {
        size :Number,
        width:Number,
        color:String
    }
}
</script>