<template>
  <v-app>
    <v-main>
      <v-dialog v-model="dialog" persistent class="v-dialog">
        <v-toolbar color="#004c89" style="color: white" title="Ações"></v-toolbar>
        <v-card :height="200">
          <v-card-text style="padding: 0; position: relative;">
            <div class="main-container">
              <div class="container-list">
                <ul id="lista">
                  <li :id="'o' + resource.codigo" v-for="resource in resources" :key="resource.codigo"
                    @click="OnClickModal">
                    {{ resource.linha1 }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="modal-footer">
              <button id="btnCloseModalMP" class="btn btn-primary" @click="OnCloseFecharModal">
                Cancelar
              </button>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { JSONFechaApontamentoOS,JSONFechaApontamentoProcessoProdutivo } from "../../../../../CPSWeb/front-end/library/Publics.js";

export default {
  emits: ["OnCloseModalFecharFinalizarApontamento", "OnSelectFecharFinalizarApontamento"],
  props: {
    ShowFecharApontamento: Boolean
  },
  data() {
    return {
      resources: [],
      dialog: false
    };
  },
  methods: {
    OnCloseFecharModal() {
      this.$emit("OnCloseModalFecharFinalizarApontamento");
    },
    OnClickModal(event) {
      this.dialog = false;
      localStorage.setItem("codigo_fechamento", event.target.id.replace("o", ""));
      this.$emit("OnSelectFecharFinalizarApontamento");
    }
  },
  created() {
    this.dialog = this.ShowFecharApontamento;
    if (localStorage.getItem('estilo_apontamento') == 'OS') {
      this.resources = JSONFechaApontamentoOS;
    }
    else {
      this.resources = JSONFechaApontamentoProcessoProdutivo;
    }
  }
};
</script>

<style>
.v-dialog{
  width: 40%;
}

.container-list{
  overflow: hidden;
}

.modal-footer{
  width: 100%;
  position: absolute;
  bottom: 0;
}

#btnCloseModalMP{
  margin: 0 10px 10px 0;
}

@media (max-width: 600px) {
  .v-dialog{
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 1000px) {
  .v-dialog{
    width: 70%;
  }
}
</style>