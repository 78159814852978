<template>
  <div class="container-pai" :style="styleLiberaFuncionamento">
    <div class="modals" style="width: 100vw">
      <ModalMP v-if="ShowMP" :showMP="ShowMP" :origemMP="origemMP" :resource="resourcesModal"
        :dataModal="'motivo_parada'" :versaoClient="versaoClient" @OnCloseModalMP="OnCloseModalMP"
        @OnSelectMP="OnSelectMP" :host="Host" @ResetaBtnsMPApontaAgrupado="ResetaBtnsMPApontaAgrupado"
        @ErroMPApontaAgrupado="ErroMPApontaAgrupado" @CancelMPApontaAgrupado="CancelMPApontaAgrupado"
        @OnVoltar="OnEscClick" @resetaShowMP="this.ShowMP = false" />

      <ModalTPServicos v-if="ShowTPServicos" :showTP="ShowTPServicos" :resource="resourcesModal"
        :dataModal="'tp_servicos'" @OnVoltar="OnEscClick" @OnCloseModalTP="OnCloseModalTPServico"
        @OnSelectTP="OnSelectTP" />

      <ModalFecharFinalizarApontamentoVue v-if="ShowModalFecharFinalizarApontamento"
        :ShowFecharApontamento="ShowModalFecharFinalizarApontamento" @OnCloseModalFecharFinalizarApontamento="OnCloseModalFecharFinalizarApontamento
    " @OnSelectFecharFinalizarApontamento="OnSelectFecharFinalizarApontamento" />

      <Popup v-cloak :ShowGridApontamentoAberto="ShowGridApontamentoAberto" :resource="resourceModalBTNS"
        :descricao-modal="descricaoModalBTNS" :acaoModal="AcaoModalPopup" :dataModal="dataModal" :host="Host"
        @OnMotivoDeParada="OnMotivoDeParada" @OnHabilitaBtnsCards="OnHabilitaBtnsCards"
        @OnHabilitaDIVPrincipal="OnHabilitaDIVPrincipal" @UpdateResourceShowModal="UpdateResourceShowModal"
        @OnEvent="OnEventModal" @OnAbrirMotivoDeParada="OnAbrirMotivoDeParada"
        :FuncionarioSupervisor="FuncionarioSupervisor" @OnFecharApontamentoAberto="OnFecharApontamentoAberto" />

      <DialogErro :show="ShowDialogErro" :message="MessageDialogErro" :acao="AcaoErroModal"
        @OnHabilitaBtnsCards="OnHabilitaBtnsCards" @OnHabilitaDIVPrincipal="OnHabilitaDIVPrincipal"
        @OnEvent="OnEventModal" @acao-modal="AcaoModalErroVue" @showDialog="ShowDialog" />

      <LoadingModal v-cloak v-if="ShowModalLoadCards" :title="titleModalLoadCards" :message="MensagemModalLoadCard" />

      <ModalLoadMP v-if="ShowModalMP" :showMP="ShowModalMP" @OnResetMP="OnResetMP"></ModalLoadMP>

      <ModalMensagemWsClient />

      <infosCardApontAbertos ref="infosCardApontAbertos" v-if="showInfosCardApontAberto"
        :dialog="showInfosCardApontAberto" :ShowGridApontamentoAberto="ShowGridApontamentoAberto" :host="Host"
        :inicioApontamento="DataInicioModalInfosServicos" :resource="resourceModalInfosServicos"
        @FecharModal="this.showInfosCardApontAberto = false" :FuncionarioSupervisor="FuncionarioSupervisor"
        @OnMotivoDeParada="OnMotivoDeParada" @OnHabilitaBtnsCards="OnHabilitaBtnsCards"
        @OnHabilitaDIVPrincipal="OnHabilitaDIVPrincipal" @UpdateResourceShowModal="UpdateResourceShowModal"
        @OnAbrirMotivoDeParada="OnAbrirMotivoDeParada" @RecebeShowModalLoadCards="RecebeShowModalLoadCards"
        @on-show-detalhes-o-s="OnShowDetalhesOS" @OnShowDetalhesApontaOS="OnShowDetalhesApontaOS"
        @OnFecharApontamentoAberto="OnFecharApontamentoAberto" />

      <infosCardServicos ref="infosCardServicos" v-if="showInfosCardServicos" :host="Host"
        :dialog="showInfosCardServicos" :dataEntrega="DataEntregaModalInfosServicos"
        :resource="resourceModalInfosServicos" @FecharModal="this.showInfosCardServicos = false"
        @OnShowModalTPServicos="OnShowModalTPServicos" @OnHabilitaBtnsCards="OnHabilitaBtnsCards"
        @OnHabilitaDIVPrincipal="OnHabilitaDIVPrincipal" @AbrindoApontamento="AbrindoApontamento"
        @OnAbrirApontamento="OnAbrirApontamento" @OnMotivoDeParada="OnMotivoDeParada" />

      <ApontamentoAgrupado ref="ApontamentoAgrupado" v-if="ativar_modal" :host="Host" :ativar_modal="ativar_modal"
        @FecharModalApontamentoAgrupado="ativar_modal = false" :versaoClient="versaoClient"
        @OnMotivoDeParada="OnMotivoDeParada" @OnTodasMaquinas="OnTodasMaquinas" />
    </div>

    <div class="alertGRV">
      <alertGRV ref="alert" />
    </div>


    <div class="header" id="myHeader">
      <router-link v-if="pwa" id="routerLink" to="/config">
        <div id="imgConfig">
          <i class="bi bi-gear"></i>
        </div>
      </router-link>

      <div class="header-funcionario">
        <div class="header-funcionario-user">
          <div style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
            ">
            <div v-if="checkimageFunc" class="img flex-shrink-0"
              :style="{ backgroundImage: 'url(' + adjustedimgFunc + ')' }"></div>

            <div class="h1-header">
              <h1 class="h1-funcionario-InfosUser">
                {{ FuncionarioSelecionado }}
              </h1>
            </div>
          </div>

          <div style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 100%;
            ">
            <div v-if="checkimageMaquina" id="ImgMaquina" class="img flex-shrink-0"
              :style="{ backgroundImage: 'url(' + adjustedimgMaquina + ')' }"></div>

            <div class="h1-header">
              <h1 class="h1-funcionario-InfosUser">
                {{ MaquinaSelecionada }}
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!ShowDetalhesOS" class="input-group">
        <input v-model="filterInput" ref="inputElement" class="form-control" id="floatingInputGroup1"
          style="height: 90%" :placeholder="placeholder_pesquisa" @keydown="handleKeyDown" @keypress="OnEnterClick" />

        <span @click="OnClearFilter" class="input-group-text">
          <i class="bi bi-x-circle"></i>
        </span>
      </div>
    </div>
    <div v-if="!ShowDetalhesOS" class="content">
      <MPLoad></MPLoad>
      <Transition>
        <div class="container-central" id="container-central" v-if="!ShowModalLoadCards">
          <p v-if="ShowMessageSemConexaoServidor" id="descricao-sem-conexao">
            Sem Conexão com o servidor.
          </p>
          <Transition v-if="ShowGridApontamentoAberto && ShowCardMaquina">
            <div id="container-apontamentos-abertos">
              <h5>Apontamentos aberto de {{ FuncionarioSelecionado }}</h5>

              <div id="container-grid-apontamento" class="row row-cols-1 row-cols-sm-2 row-cols-lg-2">
                <GridCardsApontamentosAbertosVue v-cloak v-for="resourceApontamento in ascSortedResourcesApontamentos"
                  :key="resourceApontamento.codigo" :resource="resourceApontamento" :host="Host"
                  :numeroCard="resourceApontamento.codigo.toString()"
                  :class_linha1="'linha1-' + resourceApontamento.codigo"
                  :class_linha2="'linha2-' + resourceApontamento.codigo"
                  :ShowGridApontamentoAberto="ShowGridApontamentoAberto" :versaoClient="versaoClient"
                  :FuncionarioSupervisor="FuncionarioSupervisor" @OnMotivoDeParada="OnMotivoDeParada"
                  @OnHabilitaBtnsCards="OnHabilitaBtnsCards" @OnHabilitaDIVPrincipal="OnHabilitaDIVPrincipal"
                  @UpdateResourceShowModal="UpdateResourceShowModal" @OnAbrirMotivoDeParada="OnAbrirMotivoDeParada"
                  @RecebeShowModalLoadCards="RecebeShowModalLoadCards" @on-show-detalhes-o-s="OnShowDetalhesOS"
                  @OnShowDetalhesApontaOS="OnShowDetalhesApontaOS"
                  @OnFecharApontamentoAberto="OnFecharApontamentoAberto" @VimDoCardServicos="VimDoCardServicos"
                  @MostrarInfosCardApontAberto="MostrarInfosCardApontAberto"
                  @OnAtualizandoMotivosDeParada="OnAtualizandoMotivosDeParada" />
              </div>
            </div>
          </Transition>
          <div id="container-cards" v-if="ShowCards" v-bind:style="styleContainerCards" style="position: relative">
            <div v-if="ShowCardFunc" id="container-gridcards1" class="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
              <GridCardsFuncionariosVue v-cloak v-for="resourceFuncionario in ascSortedResourcesFuncionarios.slice(
    0,
    qtd_cards
  )" :key="resourceFuncionario.codigo" :resource="resourceFuncionario"
                :numeroCard="resourceFuncionario.codigo.toString()"
                @FuncionarioSelecionado="UpdateFuncionarioSelecionado" @OnHabilitaBtnsCards="OnHabilitaBtnsCards"
                @OnHabilitaDIVPrincipal="OnHabilitaDIVPrincipal" />

              <h1 v-if="qtd_cards < ascSortedResourcesFuncionarios.length" class="h1_qtdcards">
                Só foram exibidos {{ qtd_cards }} funcionários de
                {{ ascSortedResourcesFuncionarios.length }}. Para encontrar os
                demais utilizar a pesquisa.
              </h1>
            </div>

            <h5 v-if="ShowCardMaquina">
              Escolha uma nova máquina para apontamento
            </h5>

            <div v-if="ShowCardMaquina" id="container-gridcards2" class="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
              <GridCardsMaquinaVue v-cloak v-for="resourceMaquina in ascSortedResourcesMaquinas.slice(
    0,
    qtd_cards
  )" :key="resourceMaquina.codigo" :resource="resourceMaquina" :numeroCard="resourceMaquina.codigo.toString()"
                @maquina-selecionada="UpdateMaquinaSelecionada" @OnHabilitaBtnsCards="OnHabilitaBtnsCards"
                @OnHabilitaDIVPrincipal="OnHabilitaDIVPrincipal" @get-info-maquina="GetInfoMaquinaAptSimultaneo"
                :host="this.Host" />

              <h1 v-if="qtd_cards < ascSortedResourcesMaquinas.length" class="h1_qtdcards">
                Só foram exibidas {{ qtd_cards }} máquinas de
                {{ ascSortedResourcesMaquinas.length }}. Para encontrar as
                demais utilizar a pesquisa.
              </h1>
            </div>

            <div v-if="this.vPC" id="container-apt_simultaneo">
              <div v-if="ShowCardServico" id="container-gridcards3" :class="classDoubleColumn">
                <div v-if="MostraAptSimultaneo" class="cards_title_apt_simultaneo">
                  <h5 class="cards_title_text_apt_simultaneo">
                    Itens Disponíveis: {{ ContadorItensDisponiveis }}
                  </h5>
                </div>

                <GridCardsApontamentosServicosVue v-cloak v-for="resourceServicos in ascSortedResourcesServicos.slice(
    0,
    qtd_cards
  )" :key="resourceServicos.codigo" :resource="resourceServicos" :host="Host"
                  :class_linha1="'linha1-' + resourceServicos.codigo"
                  :class_linha2="'linha2-' + resourceServicos.codigo"
                  :class_linha5="'linha5-' + resourceServicos.codigo" :numeroCard="resourceServicos.codigo.toString()"
                  @OnMotivoDeParada="OnMotivoDeParada" @OnHabilitaBtnsCards="OnHabilitaBtnsCards"
                  @OnHabilitaDIVPrincipal="OnHabilitaDIVPrincipal" @UpdateResourceShowModal="UpdateResourceShowModal"
                  @OnShowModalTPServicos="OnShowModalTPServicos" @on-abrir-apontamento="OnAbrirApontamento"
                  @on-show-detalhes-o-s="OnShowDetalhesOS" @VimDoCardServicos="VimDoCardServicos"
                  @AbrindoApontamento="AbrindoApontamento" @MostrarInfosCardServicos="MostrarInfosCardServicos"
                  @OnAtualizandoMotivosDeParada="OnAtualizandoMotivosDeParada"
                  :habilita-apt-simultaneo="this.HabilitaAptSimultaneo"
                  @add-array-apt-simultaneo="GetArrayAptSimultaneo" @altura-cards-servico="GetAlturaCardsServico" />

                <div style="color: black; width: 100%">
                  <h1 v-if="estilo_apontamento == 'PROCESSO_PRODUTIVO' &&
    qtd_cards < ascSortedResourcesServicos.length
    " class="h1_qtdcards">
                    Só foram exibidos {{ qtd_cards }} processos produtivos de
                    {{ ascSortedResourcesServicos.length }}. Para encontrar os
                    demais utilizar a pesquisa.
                  </h1>
                  <h1 v-if="estilo_apontamento == 'OS' &&
    qtd_cards < ascSortedResourcesServicos.length
    " class="h1_qtdcards">
                    Só foram exibidos {{ qtd_cards }} serviços de
                    {{ ascSortedResourcesServicos.length }}. Para encontrar os
                    demais utilizar a pesquisa.
                  </h1>
                </div>
              </div>

              <v-divider v-if="MostraAptSimultaneo" vertical style="color: black"></v-divider>

              <div v-if="MostraAptSimultaneo" id="cards_apt_simultaneo">
                <div class="cards_title_apt_simultaneo">
                  <h5 class="cards_title_text_apt_simultaneo">
                    Itens Selecionados: {{ ArrayAptSimultaneo.length }}
                  </h5>
                </div>
                <div>
                  <GridCardsServicosAptSimultaneoVue :resource="EnviaArrayAptSimultaneo" v-for="EnviaArrayAptSimultaneo in ArrayAptSimultaneo.slice(
    0,
    ArrayAptSimultaneo.length
  )" :key="EnviaArrayAptSimultaneo.codigo" @remove-apt-simultaneo="GetRemoveAptSimultaneo" />
                </div>
              </div>
            </div>

            <div v-if="this.vMobile" id="container-apt_simultaneo_mob">
              <div v-if="ShowCardServico" id="container-gridcards3" :class="classDoubleColumn">
                <div v-if="MostraAptSimultaneo" class="cards_title_apt_simultaneo_mob">
                  <h5>Itens Disponíveis: {{ ContadorItensDisponiveis }}</h5>
                </div>

                <GridCardsApontamentosServicosVue v-cloak v-for="resourceServicos in ascSortedResourcesServicos.slice(
    0,
    qtd_cards
  )" :key="resourceServicos.codigo" :resource="resourceServicos" :host="Host"
                  :class_linha1="'linha1-' + resourceServicos.codigo"
                  :class_linha2="'linha2-' + resourceServicos.codigo"
                  :class_linha5="'linha5-' + resourceServicos.codigo" :numeroCard="resourceServicos.codigo.toString()"
                  @OnMotivoDeParada="OnMotivoDeParada" @OnHabilitaBtnsCards="OnHabilitaBtnsCards"
                  @OnHabilitaDIVPrincipal="OnHabilitaDIVPrincipal" @UpdateResourceShowModal="UpdateResourceShowModal"
                  @OnShowModalTPServicos="OnShowModalTPServicos" @on-abrir-apontamento="OnAbrirApontamento"
                  @on-show-detalhes-o-s="OnShowDetalhesOS" @VimDoCardServicos="VimDoCardServicos"
                  @AbrindoApontamento="AbrindoApontamento" @MostrarInfosCardServicos="MostrarInfosCardServicos"
                  @OnAtualizandoMotivosDeParada="OnAtualizandoMotivosDeParada"
                  :habilita-apt-simultaneo="this.HabilitaAptSimultaneo"
                  @add-array-apt-simultaneo="GetArrayAptSimultaneo" @altura-cards-servico="GetAlturaCardsServico" />

                <div style="color: black; width: 100%">
                  <h1 v-if="estilo_apontamento == 'PROCESSO_PRODUTIVO' &&
    qtd_cards < ascSortedResourcesServicos.length
    " class="h1_qtdcards">
                    Só foram exibidos {{ qtd_cards }} processos produtivos de
                    {{ ascSortedResourcesServicos.length }}. Para encontrar os
                    demais utilizar a pesquisa.
                  </h1>
                  <h1 v-if="estilo_apontamento == 'OS' &&
    qtd_cards < ascSortedResourcesServicos.length
    " class="h1_qtdcards">
                    Só foram exibidos {{ qtd_cards }} serviços de
                    {{ ascSortedResourcesServicos.length }}. Para encontrar os
                    demais utilizar a pesquisa.
                  </h1>
                </div>
              </div>

              <div v-if="MostraAptSimultaneo" id="cards_apt_simultaneo_mob">
                <div class="cards_title_apt_simultaneo_mob">
                  <h5 id="cards_title_text_apt_simultaneo">
                    Itens Selecionados: {{ ArrayAptSimultaneo.length }}
                  </h5>
                </div>
                <div :class="this.classDoubleColumn">
                  <GridCardsServicosAptSimultaneoVue :resource="EnviaArrayAptSimultaneo" v-for="EnviaArrayAptSimultaneo in ArrayAptSimultaneo.slice(
    0,
    ArrayAptSimultaneo.length
  )" :key="EnviaArrayAptSimultaneo.codigo" @remove-apt-simultaneo="GetRemoveAptSimultaneo" />
                </div>
              </div>
            </div>

            <calculator v-cloak v-if="ShowCalculadora" :div_btn_agrupados="div_btn_agrupados"
              @SendValueCalculator="sendResquestCalculator" @VerificaCaixaFiltro="VerificaCaixaFiltro"
              @AbriModalApontamentoAgrupado="AbriModalApontamentoAgrupado"
              :div_btn_simultaneo="HabilitaBtnAptSimultaneo" @habilitar-apt-simultaneo="GetHabilitaAptSimultaneo"
              @abrir-apont-simultaneo="GetAbrirAptSimultaneo" />
          </div>
        </div>
      </Transition>
    </div>

    <div v-if="ShowDetalhesOS" class="os">
      <OS v-cloak :resource="resourceDetalhesOS" :resourceFiles="resourceFilesOS" :host="Host"
        :ShowGridApontamentoAberto="ShowGridApontamentoAberto" :vimDo_cardServico="vimDo_cardServico"
        @OnAbrirApontamento="OnAbrirApontamento" @OnHabilitaBtnsCards="OnHabilitaBtnsCards"
        @OnHabilitaDIVPrincipal="OnHabilitaDIVPrincipal" @OnMotivoDeParada="OnMotivoDeParada"
        @UpdateResourceShowModal="UpdateResourceShowModal" @OnAbrirMotivoDeParada="OnAbrirMotivoDeParada"
        @OnEvent="OnEventModal" @OnModalGRV="OnModalGRV" @on-reset="OnResetVariaveisOS"
        @desabilitaHeaderFooter="desabilitaHeaderFooter" />
    </div>

    <div class="footer justify-start">
      <v-row class="ml-0">
        <v-col cols="12" class="pb-0 pt-0">
          <h1 v-if="ShowVersao && !showBtnTodasMaquinas">
            S: {{ versaoServer }} C: {{ versaoClient }} Chave: {{ Chave }}
          </h1>
        </v-col>
        <v-col cols="12" class="pb-0 pt-0 ml-2">
          <small style="font-weight: 600;color : white"> {{ id_client_ws }} </small>
        </v-col>
      </v-row>


      <button v-if="ShowCardFunc && ShowBtnMostrarTodosFuncionarios" @click="OnAtualizaTodosFuncionarios"
        class="btn-MostrarTodosFuncionarios">
        Mostrar todos funcionários
      </button>

      <button v-if="ShowCardMaquina && showBtnTodasMaquinas" @click="OnTodasMaquinas" class="btn-MostrarTodasMaquinas">
        Mostrar todas máquinas
      </button>

      <div class="div-buttons-footer" v-if="AtualizacaoGrid != 'funcionarios'">
        <button v-if="ShowBtnInicio" @click="OnClickBtnsUpdategrid('btnInicio')" class="btn-inicio">
          Inicio
        </button>
        <button v-if="ShowBtnVoltar" @click="OnClickBtnsUpdategrid('btnVoltar')" class="btn-voltar">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
const cstsUuarioNãoEncontrado = "Usuário não encontrado";

const cstEstiloApontamento = {
  OS: "OS",
  PADRAO: "Padrão",
  PROCESSO_PRODUTIVO: "Processo Produtivo",
};

const cstShowCards = {
  SHOWFUNCIONARIOS: "ShowFuncionarios",
  SHOWMAQUINAS: "ShowMaquinas",
  SHOWSERVICOS: "ShowServicos",
};

import {
  Log,
  GenerateCrypt,
  dateFormat,
} from "../../../../CPSWeb/front-end/library/utils";

import calculator from "@/components/Calculator/calculator.vue";

import GridCardsFuncionariosVue from "@/components/GridCards/GridCardFuncionario.vue";

import GridCardsMaquinaVue from "@/components/GridCards/GridCardMaquina.vue";

import GridCardsApontamentosAbertosVue from "@/components/GridCards/GridCardsApontamentosAbertos.vue";

import OS from "@/components/OS/os.vue";

import GridCardsApontamentosServicosVue from "@/components/GridCards/GridCardsServicos.vue";

import GridCardsServicosAptSimultaneoVue from "@/components/GridCards/GridCardsServicosAptSimultaneo.vue";

import LoadingModal from "../../../../CPSWeb/front-end/Componentes/Modal/LoadingModal.vue";

import DialogErro from "@/components/Modal/DialogErro.vue";

import ModalMP from "@/components/Modal/ModalMP.vue";

import ModalFecharFinalizarApontamentoVue from "@/components/Modal/ModalFecharFinalizarApontamento.vue";

import ModalTPServicos from "@/components/Modal/ModalTPServicos.vue";

import ModalMensagemWsClient from "@/components/Modal/ModalMensagemWs.vue"

import ApontamentoAgrupado from "@/components/Modal/ApontamentoAgrupado/ApontamentoAgrupado.vue";

import { Hosts } from "../../../../CPSWeb/front-end/library/hosts.js";

import { HostsV3Up } from "../../../../CPSWeb/front-end/library/hostsv3.js";

import infosCardApontAbertos from "@/components/Modal/CardDeInfos/infosCardApontAbertos.vue";

import infosCardServicos from "@/components/Modal/CardDeInfos/infosCardServicos.vue";

import ControllerEventsApontamento from "../controllers/controllerApontamento.js";

import ControllerEvents from '../controllers/controllersEventsTeclado'

import {
  RequestGETSQL,
  RequestGETMaquinas,
  ResquestGETFuncionariosProducao,
  RequestGETServicos,
  login,
  RequestPostAbrirApontamento,
  RequestPostFecharApontamento,
  RequestGETTIPOServicos,
  ResquestGETFuncionariosProducaoSetor,
  PostLogGRV,
  PostErroQuedaAppLogGRV,
} from "../../../../CPSWeb/front-end/services/ApiRequest";

import alertGRV from "../../../../CPSWeb/front-end/Componentes/alert/alert.vue";

import Popup from "@/components/Modal/Popup.vue";

import ModalLoadMP from "@/components/Modal/ModalLoadMP.vue";


import { useAppConnectionWs } from "../store/appConnectionWs"

function ValidarProcessoJaTrabalhando(pProcessos, pNumeroProcesso) {
  return !pProcessos.includes(pNumeroProcesso);
}

import { ref } from "vue";

export default {
  el: "floatingInputGroup1",
  name: "Home",
  emits: ["SendRequest", "SendValueCalculator", "SendRemoveCaracter"],
  components: {
    calculator,
    alertGRV,
    LoadingModal,
    GridCardsFuncionariosVue,
    GridCardsMaquinaVue,
    GridCardsApontamentosAbertosVue,
    GridCardsApontamentosServicosVue,
    GridCardsServicosAptSimultaneoVue,
    ApontamentoAgrupado,
    DialogErro,
    Popup,
    OS,
    ModalMP,
    ModalFecharFinalizarApontamentoVue,
    ModalTPServicos,
    infosCardApontAbertos,
    infosCardServicos,
    ModalLoadMP,
    ModalMensagemWsClient
  },

  data() {
    return {
      placeholder_pesquisa: "Pesquisar Funcionário",
      proximaAcao: cstShowCards.SHOWFUNCIONARIOS,
      setores: "",
      alertServicos: false,
      seqBarr: "",
      resourceModalBTNS: [],
      resourceDetalhesOS: [],
      resourceFilesOS: [],
      FuncionarioSelecionado: "",
      FuncionarioSupervisor: false,
      ShowCardFunc: false,
      ShowCardMaquina: false,
      ShowCardServico: false,
      ShowTPServicos: false,
      ShowDetalhesOS: false,
      ShowMP: false,
      ShowModalMP: false,
      ShowModalFecharFinalizarApontamento: false,
      ShowCalculadora: true,
      styleLiberaFuncionamento: {
        pointerEvents: "visible",
      },
      AtualizacaoGrid: "funcionarios",
      ShowModalLoadCards: true,
      titleModalLoadCards: "Atualizando Funcionários",
      MensagemModalLoadCard: "Aguarde..",
      imageFunc: "",
      imageMaquina: "",
      tipoAtualizacao: "",
      PlaceHolderInput: "Pesquisa",
      TipoInput: "text",
      CaixaFiltroFocada: false,
      filterInput: "",
      resourceFuncionario: [],
      resourceMaquina: [],
      resourceApontamento: [],
      resourceServicos: [],
      styleContainerCards: {
        width: 100 + "%",
        height: 100 + "%",
        overflowY: "scroll",
        flexFlow: "column",
      },
      titleModal: "",
      dataModal: "",
      resourcesModal: [],
      sizeModal: 8,
      ShowBtnVoltar: false,
      ShowBtnInicio: false,
      ShowModalGRV: true,
      ShowDialogErro: false,
      MessageDialogErro: "",
      ShowBtnMostrarTodosFuncionarios: false,
      showBtnTodasMaquinas: false,
      pwa: false,
      teclado_virtual: false,
      sessao: "",
      ShowVersao: false,
      MensagemErroModal: "",
      processosAbertos: [],
      Chave: sessionStorage.getItem("chave"),
      AcaoErroModal: "",
      AcaoModalPopup: "",
      descricaoModalBTNS: "",
      resourceAbrirMotivoParada: [],
      ShowMessageSemConexaoServidor: false,
      ShowGridApontamentoAberto: false,
      vimDo_cardServico: null,
      keyCodes: [
        8, 9, 13, 16, 17, 18, 19, 20, 27, 33, 34, 35, 36, 37, 38, 39, 40, 45,
        46, 91, 92, 93, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122,
        123, 144, 145, 187, 188, 189, 190, 191, 192, 219, 220, 221, 222,
      ],
      btn_aponta_agrupado: false,
      div_btn_agrupados: false,
      ativar_modal: false,
      CarregandoComponentesGRID: false,
      timerId: null,
      Host: "http://localhost:9095", //"https://apontamento.grv.net.br:9081",
      HostOn: {
        host: 0,
        online: true,
      },
      HostsMensageria: null,
      estilo_apontamento: localStorage.getItem("estilo_apontamento"),
      CancelarEventKeyUp: false,

      // Modal infosServicos
      showInfosCardServicos: false,
      resourceModalInfosServicos: {},
      DataEntregaModalInfosServicos: "",
      DataInicioModalInfosServicos: "",

      // Modal infosApontaAberto
      showInfosCardApontAberto: false,

      // número de cards exibidos
      qtd_cards: [],
      HabilitaBtnAptSimultaneo: false,
      HabilitaAptSimultaneo: false,
      ArrayAptSimultaneo: [],
      MostraAptSimultaneo: false,
      classDoubleColumn: "row row-cols-1 row-cols-sm-2 row-cols-lg-2",
      vPC: false,
      vMobile: true,
      arrayPesquisa: [],
      ArrayCardsAptSimultaneo: [],
    };
  },

  updated() {
    if (window.screen.width > 600 && window.screen.height > 370) {
      this.vPC = true;
      this.vMobile = false;
    } else {
      this.vPC = false;
      this.vMobile = true;
    }

    if (this.AtualizacaoGrid == "funcionarios") {
      this.ShowCardMaquina = false;
      this.ShowCardServico = false;
      this.ActionCodBarras = "";
    }
    if (this.AtualizacaoGrid == "maquinas") {
      this.ShowCardFunc = false;
      this.ShowCardServico = false;
    }
    if (this.AtualizacaoGrid == "serviços") {
      this.ShowCardFunc = false;
      this.ShowCardMaquina = false;
    }

    if (!this.ShowDetalhesOS) {
      this.OnFocusInputFilter();
    }

    if ($("#loading-modal")[0] != undefined) {
      if ($("#loading-modal")[0].className == "loading-modal") {
        this.CarregandoComponentesGRID = true;
      } else {
        this.CarregandoComponentesGRID = false;
      }
    } else {
      this.CarregandoComponentesGRID = false;
    }
  },
  computed: {
    ContadorItensDisponiveis() {
      let contador = this.resourceServicos.length;
      for (let i = 0; i < this.resourceServicos.length; i++) {
        if (this.resourceServicos[i].aptSimultaneo == true) {
          contador--;
        }
      }

      return contador;
    },

    id_client_ws() {
      return useAppConnectionWs().id_client_ws
    },

    ArrayDepoisPesquisa() {
      if (
        localStorage.getItem("estilo_apontamento_client") ==
        cstEstiloApontamento.PROCESSO_PRODUTIVO
      ) {
        return this.resourceServicos
          .map((e) => e)
          .sort((a, b) => a.priority - b.priority)
          .filter(
            ({ linha1, linha2, data_prevista_os_formatada, linha4 }) =>
              linha1.includes(this.filterInput.toUpperCase()) ||
              linha4.toString().includes(this.filterInput.toUpperCase()) ||
              linha2.includes(this.filterInput.toUpperCase()) ||
              (data_prevista_os_formatada.includes(
                this.filterInput.toUpperCase()
              ) &&
                ValidarProcessoJaTrabalhando(this.processosAbertos, linha4))
          );
      } else if (
        localStorage.getItem("estilo_apontamento_client") ==
        cstEstiloApontamento.OS
      ) {
        return this.resourceServicos
          .map((e) => e)
          .sort((a, b) => a.priority - b.priority)
          .filter(
            ({ linha1, linha2, data_prevista_os_formatada }) =>
              linha1.includes(this.filterInput.toUpperCase()) ||
              linha2.includes(this.filterInput.toUpperCase()) ||
              data_prevista_os_formatada.includes(
                this.filterInput.toUpperCase()
              )
          );
      }
    },

    versaoServer() {
      return localStorage.getItem("versaoServer");
    },

    versaoClient() {
      return process.env.VUE_APP_VERSION;
    },

    adjustedimgFunc() {
      if (this.tipoAtualizacao != "servicos") {
        if (this.imageFunc.substring(0, 4) == "http") {
          // AWS
          return this.imageFunc;
        } else {
          // base 64
          return `data:image;base64,${this.imageFunc.replace(/\n/g, "")}`;
        }
      }
    },

    adjustedimgMaquina() {
      if (this.tipoAtualizacao != "servicos") {
        if (this.imageMaquina.substring(0, 4) == "http") {
          // AWS
          return this.imageMaquina;
        } else {
          // base 64
          return `data:image;base64,${this.imageMaquina.replace(/\n/g, "")}`;
        }
      }
    },

    checkimageMaquina() {
      if (this.imageMaquina == null) {
        return 0;
      } else {
        return 1;
      }
    },

    checkimageFunc() {
      if (this.imageFunc == null) {
        return 0;
      } else {
        return 1;
      }
    },

    ascSortedResourcesApontamentos() {
      return this.resourceApontamento
        .map((e) => e)
        .sort((a, b) => a.priority - b.priority);
    },

    ascSortedResourcesFuncionarios() {
      if (this.resourceFuncionario.length > 0) {
        if (this.filterInput.indexOf('<') == -1) {
          {
            return this.resourceFuncionario
              .map((e) => e)
              .sort((a, b) => a.priority - b.priority)
              .filter(({ linha1 }) =>
                linha1.includes(this.filterInput.toUpperCase())
              );
          }
        } else {
          return this.resourceFuncionario
            .map((e) => e)
            .sort((a, b) => a.priority - b.priority);
        }
      } else {
        return this.resourceFuncionario
          .map((e) => e)
          .sort((a, b) => a.priority - b.priority);
      }
    },

    ascSortedResourcesMaquinas() {
      if (this.resourceMaquina.length > 0) {
        if (this.pesquisaLeitor()) {
          return this.resourceMaquina
            .map((e) => e)
            .sort((a, b) => a.priority - b.priority)
            .filter(({ linha1 }) =>
              linha1.includes(this.filterInput.toUpperCase())
            );
        } else {
          return this.resourceMaquina
            .map((e) => e)
            .sort((a, b) => a.priority - b.priority);
        }
      } else {
        return this.resourceMaquina
          .map((e) => e)
          .sort((a, b) => a.priority - b.priority);
      }
    },

    ascSortedResourcesServicos() {
      this.btnAbrir = document.querySelectorAll(".btn-abrir");
      this.btnMP = document.querySelectorAll(".btn-MP");

      this.alertServicos = true;

      if (this.resourceServicos.length > 0) {
        if (this.pesquisaLeitor()) {
          if (
            localStorage.getItem("estilo_apontamento_client") ==
            cstEstiloApontamento.OS
          ) {
            return this.resourceServicos
              .map((e) => e)
              .sort((a, b) => a.priority - b.priority)
              .filter(
                ({ linha1, linha2, data_prevista_os_formatada }) =>
                  linha1.includes(this.filterInput.split("<")[0].toUpperCase()) ||
                  linha2.includes(this.filterInput.split("<")[0].toUpperCase()) ||
                  data_prevista_os_formatada.includes(
                    this.filterInput.toUpperCase()
                  )
              );
          } else if (
            localStorage.getItem("estilo_apontamento_client") ==
            cstEstiloApontamento.PROCESSO_PRODUTIVO
          ) {
            return this.resourceServicos
              .map((e) => e)
              .sort((a, b) => a.priority - b.priority)
              .filter(
                ({ linha1, linha2, data_prevista_os_formatada, linha4 }) =>
                  linha1.includes(this.filterInput.split("<")[0].toUpperCase()) ||
                  linha4.toString().includes(this.filterInput.split("<")[0].toUpperCase()) ||
                  linha2.includes(this.filterInput.split("<")[0].toUpperCase()) ||
                  (data_prevista_os_formatada.includes(
                    this.filterInput.toUpperCase()
                  ) &&
                    ValidarProcessoJaTrabalhando(this.processosAbertos, linha4))
              );
          } else {
            if (localStorage.getItem("estilo_apontamento") == "OS") {
              return this.resourceServicos
                .map((e) => e)
                .sort((a, b) => a.priority - b.priority)
                .filter(
                  ({ linha1, linha2, data_prevista_os_formatada }) =>
                    linha1.includes(this.filterInput.split("<")[0].toUpperCase()) ||
                    linha2.includes(this.filterInput.split("<")[0].toUpperCase()) ||
                    data_prevista_os_formatada.includes(
                      this.filterInput.toUpperCase()
                    )
                );
            } else {
              return this.resourceServicos
                .map((e) => e)
                .sort((a, b) => a.priority - b.priority)
                .filter(
                  ({ linha1, linha2, data_prevista_os_formatada, linha4 }) =>
                    linha1.includes(this.filterInput.split("<")[0].toUpperCase()) ||
                    linha4.toString().includes(this.filterInput.split("<")[0].toUpperCase()) ||
                    linha2.includes(this.filterInput.split("<")[0].toUpperCase()) ||
                    (data_prevista_os_formatada.includes(
                      this.filterInput.toUpperCase()
                    ) &&
                      ValidarProcessoJaTrabalhando(this.processosAbertos, linha4))
                );
            }
          }
        }
        else {
          return this.resourceServicos
            .map((e) => e)
            .sort((a, b) => a.priority - b.priority);
        }
      } else {
        return this.resourceServicos
          .map((e) => e)
          .sort((a, b) => a.priority - b.priority);
      }
    },

    dadosfiltrados() {
      return this.filterInput.toUpperCase();
    },
  },
  setup() {
    const btnAbrir = ref(null);
    const btnMP = ref(null);

    return {
      btnAbrir,
      btnMP,
    };
  },
  watch: {
    CarregandoComponentesGRID(newVal) {
      if (newVal) {
        if (
          this.AtualizacaoGrid == "funcionarios" ||
          this.AtualizacaoGrid == "maquinas"
        ) {
          this.timerId = setInterval(() => {
            if (this.CarregandoComponentesGRID) {
              this.atualizarTela();
            } else {
              clearInterval(this.timerId);
            }
          }, 20000);
        } else {
          this.timerId = setInterval(() => {
            if (this.CarregandoComponentesGRID) {
              this.atualizarTela();
            } else {
              clearInterval(this.timerId);
            }
          }, 20000);
        }
      } else {
        clearInterval(this.timerId);
      }
    },

    filterInput(newValue) {
      if (newValue == null) {
        this.filterInput = "";
      }
    },

    ShowDialogErro(newValue) {
      if (newValue) {
        this.OnHabilitaBtnsCards("disabled", true);
        this.OnHabilitaDIVPrincipal(true);
      } else {
        this.OnHabilitaBtnsCards("disabled", false);
        this.OnHabilitaDIVPrincipal(false);
      }
    },

    alertServicos(newValue) {
      if (this.HabilitaAptSimultaneo == false) {
        let containerPai = document.querySelector("#container-gridcards3");

        if (containerPai.children[0] != undefined) {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            ) == false
          ) {
            containerPai.children[0].children[1].children[0].children[1].children[0].innerHTML =
              "MP &lt;F2&gt;";

            containerPai.children[0].children[1].children[0].children[1].children[1].innerHTML =
              "Abrir &lt;E&gt;";

            containerPai.children[0].children[1].children[0].children[1].children[1].setAttribute(
              "title",
              "Pressione F9 ou Enter para abrir o apontamento"
            );
          }

          if (newValue) {
            try {
              this.changeBtnModalCardContent();
            } catch (error) {
              this.alertServicos = false;
            }
          }
        }

        this.alertServicos = false;
      }
    },
  },
  methods: {
    GetAbrirAptSimultaneo(pAbrirAptSimultaneo) {
      if (pAbrirAptSimultaneo) {
        if (this.ArrayAptSimultaneo.length > 0) {
          sessionStorage.setItem(
            "ApontSimultaneo",
            JSON.stringify(this.ArrayAptSimultaneo)
          );

          this.AbrindoApontamento(true);

          this.AbrirApontamentoSimultaneo();
        }
      }
    },

    async AbrirApontamentoSimultaneo() {
      this.ArrayCardsAptSimultaneo = [];

      const cardsApontSimultaneo = JSON.parse(
        sessionStorage.getItem("ApontSimultaneo")
      );

      for (let i = 0; i < cardsApontSimultaneo.length; i++) {
        if (
          localStorage.getItem("estilo_apontamento_client") ==
          cstEstiloApontamento.PROCESSO_PRODUTIVO
        ) {
          var JSONApontamento = {
            versao: this.versaoClient,
            acao: parseInt(localStorage.getItem("acao")),
            cod_empresa: cardsApontSimultaneo[i].cod_empresa,
            OS: cardsApontSimultaneo[i].cod_os,
            COD_OS_COMPLETO: cardsApontSimultaneo[i].cod_os_completo,
            apontamento_normal: false,
            PP: cardsApontSimultaneo[i].linha4,
            CodFuncionario: parseInt(sessionStorage.getItem("cod_funcionario")),
            CodMaquina: parseInt(sessionStorage.getItem("cod_maquina")),
            CodTpServico: parseInt(
              sessionStorage.getItem("tp_servicos_selecionado")
            ),
            CodTpServicoMaquina: sessionStorage.getItem("tp_servico_maquina"),
            QtdePecas: cardsApontSimultaneo[i].qtde,
          };
        } else if (
          localStorage.getItem("estilo_apontamento_client") ==
          cstEstiloApontamento.OS
        ) {
          var JSONApontamento = {
            versao: this.versaoClient,
            acao: 100,
            cod_empresa: cardsApontSimultaneo[i].cod_empresa,
            OS: cardsApontSimultaneo[i].cod_os,
            COD_OS_COMPLETO: cardsApontSimultaneo[i].cod_os_completo,
            apontamento_normal: false,
            OS_AUX: cardsApontSimultaneo[i].cod_os_aux,
            CodFuncionario: parseInt(sessionStorage.getItem("cod_funcionario")),
            CodMaquina: parseInt(sessionStorage.getItem("cod_maquina")),
            CodTpServico: parseInt(
              sessionStorage.getItem("tp_servicos_selecionado")
            ),
            CodTpServicoMaquina: sessionStorage.getItem("tp_servico_maquina"),
            QtdePecas: cardsApontSimultaneo[i].qtde,
          };
        } else {
          if (
            localStorage.getItem("estilo_apontamento") == "PROCESSO_PRODUTIVO"
          ) {
            var JSONApontamento = {
              versao: this.versaoClient,
              acao: parseInt(localStorage.getItem("acao")),
              cod_empresa: cardsApontSimultaneo[i].cod_empresa,
              OS: cardsApontSimultaneo[i].cod_os,
              COD_OS_COMPLETO: cardsApontSimultaneo[i].cod_os_completo,
              apontamento_normal: false,
              PP: cardsApontSimultaneo[i].linha4,
              CodFuncionario: parseInt(
                sessionStorage.getItem("cod_funcionario")
              ),
              CodMaquina: parseInt(sessionStorage.getItem("cod_maquina")),
              CodTpServico: parseInt(
                sessionStorage.getItem("tp_servicos_selecionado")
              ),
              CodTpServicoMaquina: sessionStorage.getItem("tp_servico_maquina"),
              QtdePecas: cardsApontSimultaneo[i].qtde,
            };
          } else {
            var JSONApontamento = {
              versao: this.versaoClient,
              acao: 100,
              cod_empresa: cardsApontSimultaneo[i].cod_empresa,
              OS: cardsApontSimultaneo[i].cod_os,
              COD_OS_COMPLETO: cardsApontSimultaneo[i].cod_os_completo,
              apontamento_normal: false,
              OS_AUX: cardsApontSimultaneo[i].cod_os_aux,
              CodFuncionario: parseInt(
                sessionStorage.getItem("cod_funcionario")
              ),
              CodMaquina: parseInt(sessionStorage.getItem("cod_maquina")),
              CodTpServico: parseInt(
                sessionStorage.getItem("tp_servicos_selecionado")
              ),
              CodTpServicoMaquina: sessionStorage.getItem("tp_servico_maquina"),
              QtdePecas: cardsApontSimultaneo[i].qtde,
            };
          }
        }

        this.ArrayCardsAptSimultaneo[i] = JSONApontamento;
      }

      try {
        const AptAbertosBefore =
          await ControllerEventsApontamento.GetApontamentosAbertos(
            this.Host,
            localStorage.getItem("token"),
            localStorage.getItem("chave_mensageria"),
            this.ArrayCardsAptSimultaneo[0].CodFuncionario
          );

        const res = await ControllerEventsApontamento.ApontamentoSimultaneo(
          this.Host,
          localStorage.getItem("token"),
          localStorage.getItem("chave_mensageria"),
          this.ArrayCardsAptSimultaneo
        );

        if (res.sucess == res.qteApontamentos) {
          this.OnHabilitaDIVPrincipal(true);
          this.afterAbrirApontamento();
          this.GetHabilitaAptSimultaneo(false);
        }
        else {
          if (res.sucess == 1) {
            res.messageErro = `${res.messageErro} \n 
            Foi aberto ${res.sucess} apontamento dos ${res.qteApontamentos} selecionados.
          `
          }
          else {
            res.messageErro = `${res.messageErro} \n 
            Foram abertos ${res.sucess} apontamentos dos ${res.qteApontamentos} selecionados.
          `
          }

          this.OnHabilitaDIVPrincipal(true);
          this.GetHabilitaAptSimultaneo(false);
          this.afterAbrirApontamento();
          this.alertErro(res);
        }
      } catch (error) {
        console.log(error);
      }
    },

    OnEnterClick(e) {
      if (this.ShowCardServico == true && this.HabilitaAptSimultaneo == true) {
        if (e.code == "Enter" || e.code == "NumpadEnter") {
          for (let i = 0; i < this.ArrayDepoisPesquisa.length; i++) {
            if (this.ArrayDepoisPesquisa[i].aptSimultaneo == false) {
              this.ArrayAptSimultaneo.push(this.ArrayDepoisPesquisa[i]);

              this.ArrayDepoisPesquisa[i].aptSimultaneo = true;

              i = this.ArrayDepoisPesquisa.length;
            }
          }
          if (this.ArrayDepoisPesquisa.length > 0) {
            this.filterInput = "";
          }
        }
      }
    },

    GetRemoveAptSimultaneo(pRemoveAptSimultaneo) {
      let index = -1;
      for (let i = 0; i < this.ArrayAptSimultaneo.length; i++) {
        if (pRemoveAptSimultaneo.codigo == this.ArrayAptSimultaneo[i].codigo) {
          index = i;
        }
      }

      if (index > -1) {
        this.ArrayAptSimultaneo.splice(index, 1);
      }

      for (let i = 0; i < this.resourceServicos.length; i++) {
        if (pRemoveAptSimultaneo.codigo == this.resourceServicos[i].codigo) {
          this.resourceServicos[i].aptSimultaneo = false;
        }
      }
    },

    GetArrayAptSimultaneo(pArrayAptSimultaneo) {
      this.ArrayAptSimultaneo.push(pArrayAptSimultaneo);
    },

    GetHabilitaAptSimultaneo(pHabilitaAptSimultaneo) {
      this.HabilitaAptSimultaneo = pHabilitaAptSimultaneo;
      this.MostraAptSimultaneo = pHabilitaAptSimultaneo;

      if (window.screen.width > 600 && window.screen.height > 370) {
        if (this.MostraAptSimultaneo == true) {
          document.querySelector("#container-gridcards3").style.width = "45%";
          this.classDoubleColumn = "";
        } else {
          this.classDoubleColumn = "row row-cols-1 row-cols-sm-2 row-cols-lg-2";

          if (document.querySelector("#container-gridcards3") != null) {
            document.querySelector("#container-gridcards3").style.width =
              "100%";
          }
        }
      } else {
        if (this.MostraAptSimultaneo == true) {
          document.querySelector("#container-gridcards3").style.height = "50%";
        } else {
          if (document.querySelector("#container-gridcards3") != null) {
            document.querySelector("#container-gridcards3").style.height =
              "100%";
          }
        }
      }

      for (let i = 0; i < this.resourceServicos.length; i++) {
        this.resourceServicos[i].aptSimultaneo = false;
      }
      this.ArrayAptSimultaneo = [];
    },

    ResetaBtnsMPApontaAgrupado() {
      this.$refs.ApontamentoAgrupado.ResetaMP();
    },

    ErroMPApontaAgrupado(mensagem) {
      this.$refs.ApontamentoAgrupado.ErroMP(mensagem);
    },

    CancelMPApontaAgrupado() {
      this.$refs.ApontamentoAgrupado.CancelaMP();
    },

    MostrarInfosCardApontAberto(resource, DataInicio) {
      this.resourceModalInfosServicos = resource;
      this.DataInicioModalInfosServicos = DataInicio;

      this.showInfosCardApontAberto = true;
    },

    MostrarInfosCardServicos(resource, DataEntrega) {
      this.resourceModalInfosServicos = resource;
      this.DataEntregaModalInfosServicos = DataEntrega;

      this.showInfosCardServicos = true;
    },
    ConfirmarTecladoVirtual() {
      if (localStorage.getItem("teclado_virtual") != null) {
        if (localStorage.getItem("teclado_virtual") == "true") {
          this.teclado_virtual = true;
        } else if (localStorage.getItem("teclado_virtual") == "false") {
          this.teclado_virtual = false;
        }
      } else {
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) == false
        ) {
          this.teclado_virtual = true;
        }
      }
    },
    async AtualizaHost() {
      await this.OnCheckHostMensageria();

      if (localStorage.getItem("HostOn") != null) {
        this.HostOn = JSON.parse(localStorage.getItem("HostOn"));
        await this.OnCheckUltHost().then(this.OnCheckHost);
      } else {
        await this.OnCheckUltHost();
      }

      this.OnConfigHostLocal();
    },
    async OnCheckHostMensageria() {
      if (localStorage.getItem("mensageria") != undefined) {
        this.HostsMensageria = HostsV3Up;
      }
    },
    OnConfigHostLocal() {
      if (this.HostOn.local) {
        this.Host = `${localStorage.getItem(
          "caminhoRest"
        )}:${localStorage.getItem("portaRest")}`;
      }
    },
    async OnCheckUltHost() {
      if (this.HostOn.host == 0 && this.HostOn.online == true) {
        this.Host = this.HostsMensageria.host;
      }
      if (this.HostOn.host == 1 && this.HostOn.online == true) {
        this.Host = this.HostsMensageria.host_alt1;
      }

      if (this.HostOn.host == 2 && this.HostOn.online == true) {
        this.Host = this.HostsMensageria.host_alt2;
      }

      if (this.HostOn.host == 3 && this.HostOn.online == true) {
        this.Host = this.HostsMensageria.host_alt3;
      }
    },
    async SetHostOn(pURL, pOnline) {
      if (pURL == this.HostsMensageria.host) {
        this.HostOn.host = 0;
        this.HostOn.online = pOnline;
      }

      if (pURL == this.HostsMensageria.host_alt1) {
        this.HostOn.host = 1;
        this.HostOn.online = pOnline;
      }

      if (pURL == this.HostsMensageria.host_alt2) {
        this.HostOn.host = 2;
        this.HostOn.online = pOnline;
      }

      if (pURL == this.HostsMensageria.host_alt3) {
        this.HostOn.host = 3;
        this.HostOn.online = pOnline;
      }
    },
    async OnCheckHost() {
      if (this.HostOn.host == 0 && this.HostOn.online == false) {
        this.Host = this.HostsMensageria.host_alt1;
        this.HostOn.host = 1;
        this.HostOn.online = true;
      }

      if (this.HostOn.host == 1 && this.HostOn.online == false) {
        this.Host = this.HostsMensageria.host_alt2;
        this.HostOn.host = 2;
        this.HostOn.online = true;
      }

      if (this.HostOn.host == 2 && this.HostOn.online == false) {
        this.Host = this.HostsMensageria.host_alt3;
        this.HostOn.host = 3;
        this.HostOn.online = true;
      }

      if (this.HostOn.host == 3 && this.HostOn.online == false) {
        this.Host = this.HostsMensageria.host;
        this.HostOn.host = 0;
        this.HostOn.online = true;
      }

      localStorage.setItem("HostOn", JSON.stringify(this.HostOn));
    },

    atualizarTela() {
      PostErroQuedaAppLogGRV(this.versaoClient).catch((erro) => {
        console.log(erro);
      });
      document.location.reload(true);
    },
    AbriModalApontamentoAgrupado() {
      this.ativar_modal = true;
    },
    OnCloseModalMP() {
      this.ShowMP = false;
      this.styleLiberaFuncionamento.pointerEvents = "visible";

      if (this.showInfosCardApontAberto) {
        this.$refs.infosCardApontAbertos.disabledBtn(false);
      } else if (this.showInfosCardServicos) {
        this.$refs.infosCardServicos.disabledBtn(false);
      }
    },
    OnCloseModalTPServico() {
      this.ShowTPServicos = false;
      this.styleLiberaFuncionamento.pointerEvents = "visible";

      if (this.showInfosCardApontAberto) {
        this.$refs.infosCardApontAbertos.disabledBtn(false);
      } else if (this.showInfosCardServicos) {
        this.$refs.infosCardServicos.disabledBtn(false);
      }
    },

    OnFocusInputFilter() {
      if (this.teclado_virtual == true) {
        if (
          this.ShowMP == false &&
          this.ShowModalFecharFinalizarApontamento == false &&
          !this.showInfosCardApontAberto &&
          !this.showInfosCardServicos
        ) {
          this.$refs.inputElement.focus();
        }
      }
    },

    OnCloseModalFecharFinalizarApontamento() {
      if (this.showInfosCardApontAberto) {
        this.$refs.infosCardApontAbertos.disabledBtn(false);
      }

      this.styleLiberaFuncionamento.pointerEvents = "visible";
      this.ShowModalFecharFinalizarApontamento = false;
    },

    OnResetMP(event) {
      document.location.reload(true); // Passando o parametro como verdadeiro para não deixar cache em memoria
      event.preventDefault();
    },
    OnClearFilter() {
      this.filterInput = "";
    },
    pesquisaLeitor() {
      if (this.filterInput.indexOf('<') == -1) {
        return true
      }
      else {
        if (this.filterInput.indexOf('<') > 0) {
          return true
        }
        else {
          return false
        }
      }
    },
    CampoBuscaLeitor() {
      const IniIndex = this.filterInput.indexOf('<');
      const FimIndex = this.filterInput.indexOf('>');
      const CampoField = this.filterInput.substring((IniIndex + 1), FimIndex);
      return CampoField
    },
    changeBtnModalCardContent() {
      this.btnAbrir.forEach((btnModalCard) => {
        btnModalCard.innerHTML = "Abrir";

        btnModalCard.removeAttribute("title");
      });

      this.btnMP.forEach((btnMP) => {
        btnMP.innerHTML = "MP";
      });
    },

    VerificaCaixaFiltro(valor) {
      this.CaixaFiltroFocada = valor;
    },

    async OnClickAtalhosTeclados(event) {
      if (!this.ativar_modal) {
        const LeitorBusca = this.pesquisaLeitor();
        const f5Pesquisa = this.CampoBuscaLeitor();

        if (LeitorBusca && this.filterInput == '<f5>' || this.filterInput == 'f5') {
          this.filterInput == null;
          this.ShowDialogErro = false;
          this.OnClickBtnsUpdategrid('btnInicio');
        } else {
          if (this.ShowMP == false && this.ShowTPServicos == false &&
            !this.showInfosCardApontAberto && !this.showInfosCardServicos &&
            !this.ShowDetalhesOS) {

            if (event.key == "Enter") {
              if (LeitorBusca) {
                if (this.ActionCodBarras == '<F5>') {
                  this.ShowDialogErro = false;
                  this.OnClickBtnsUpdategrid('btnInicio');
                }
                if ((this.ActionCodBarras != '<ESC>')) {
                  if (this.filterInput.indexOf('<') > 0) {
                    switch (this.CampoBuscaLeitor()) {
                      case 'f2':
                        this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                        ControllerEvents.F2MotivoParadaMaquinas()
                        break;
                      case 'f4':
                        this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                        ControllerEvents.F4FecharApontamentoMotivoParadaMaquinas()
                        break;
                      case 'f9':
                        this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                        ControllerEvents.F9AbrirMotivodeParadaMaquinas()
                        break;
                      case 'esc':
                        this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                        this.OnEscClick();
                        break
                      default:
                        this.filterInput = this.filterInput.replace(';', '/').substring((this.filterInput.indexOf('<') + 1), this.filterInput.indexOf('>'))
                        if (this.AtualizacaoGrid == 'servicos') {
                          // ControllerEvents.F9AbrirMotivodeParadaMaquinas();
                        }
                    }
                  } else {
                    if (f5Pesquisa == 'f5' || f5Pesquisa == '<f5>') {
                      this.filterInput == null;
                      this.ShowDialogErro = false;
                      this.OnClickBtnsUpdategrid('btnInicio');
                    }
                    else if (!this.CancelarEventKeyUp) {
                      ControllerEvents.EnterOnApp(!this.ShowDialogErro);
                    }

                    this.CancelarEventKeyUp = false;
                  }
                }
                else {
                  if (this.filterInput.indexOf('<') > 0) {
                    const LeitorBusca = this.CampoBuscaLeitor();
                    switch (LeitorBusca) {
                      case 'f2':
                        this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                        ControllerEvents.F2MotivoParadaMaquinas()
                        break;
                      case 'f4':
                        this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                        ControllerEvents.F4FecharApontamentoMotivoParadaMaquinas()
                        break;
                      case 'f9':
                        this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                        ControllerEvents.F9AbrirMotivodeParadaMaquinas()
                        break;
                      case 'esc':
                        this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                        this.OnEscClick();
                        break
                      default:
                        this.filterInput = this.filterInput.replace(';', '/').substring((this.filterInput.indexOf('<') + 1), this.filterInput.indexOf('>'))
                        if (this.AtualizacaoGrid == 'servicos') {
                          // ControllerEvents.F9AbrirMotivodeParadaMaquinas();
                        }
                    }
                  }
                }
              }
              else {
                const LeitorBusca = this.CampoBuscaLeitor()
                switch (LeitorBusca) {
                  case 'f2':
                    this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                    ControllerEvents.F2MotivoParadaMaquinas()
                    break;
                  case 'f4':
                    this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                    ControllerEvents.F4FecharApontamentoMotivoParadaMaquinas()
                    break;
                  case 'f9':
                    this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                    ControllerEvents.F9AbrirMotivodeParadaMaquinas()
                    break;
                  case 'esc':
                    this.filterInput = this.filterInput.substring(0, this.filterInput.indexOf('<'))
                    this.OnEscClick();
                    break
                  default:
                    this.filterInput = this.filterInput.replace(';', '/').substring((this.filterInput.indexOf('<') + 1), this.filterInput.indexOf('>'))

                    if (this.AtualizacaoGrid == 'servicos') {
                      await new Promise(resolve => setTimeout(resolve, 750));
                      ControllerEvents.F9AbrirMotivodeParadaMaquinas();
                    }
                }
              }
            }
            if (event.key == "F2") {
              ControllerEvents.F2MotivoParadaMaquinas()
            }

            if (event.key == "F4") {
              ControllerEvents.F4FecharApontamentoMotivoParadaMaquinas()
            }

            if (event.key == "F9") {
              ControllerEvents.F9AbrirMotivodeParadaMaquinas()
            }
          } else {
            if (this.showInfosCardApontAberto && this.ShowMP == false && this.ShowTPServicos == false) {
              if (event.key == "F2") {
                ControllerEvents.F2AbrirMotivoDeParadaApontamentoAberto();
              }

              if (event.key == "F4") {
                ControllerEvents.F4FecharMPApontentoAberto();
              }

              if (event.key == "F9") {
                ControllerEvents.F9AbrirMPApontamentoAberto()
              }
            }
            else if (this.showInfosCardServicos && this.ShowMP == false && this.ShowTPServicos == false) {
              if (event.key == "F2") {
                ControllerEvents.F2AbrirMpInfoOS()
              }

              if (event.key == "Enter" || event.key == "F9") {
                ControllerEvents.F9AbrirApontamentoInfoOs()
              }
            }
            else if (this.ShowDetalhesOS && !this.ShowMP && !this.ShowTPServicos && // Atalhos dentro da aba do PDF
              !this.showInfosCardApontAberto && !this.showInfosCardServicos) {
              if (event.key == "Enter" || event.key == "F9") {
                ControllerEvents.F9AbrirApontamentoDetalheOS();
              }
              else if (event.key == "F4") {
                ControllerEvents.F4FecharApontamentoMPDetalheOS()
              }
              else if (event.key == "F2") {
                ControllerEvents.F2AbrirApontamentoMPDetalhesOS();
              }
            }
            else {
              if (event.key == "Enter") {
                ControllerEvents.EnterModals(
                  !this.ShowDialogErro,
                  this.ShowMP,
                  this.ShowTPServicos
                );
              }
            }
          }

          if (this.ShowModalFecharFinalizarApontamento) {
            ControllerEvents.FinalizarFecharApontamento(event.key)
          }

          if (event.key == "Escape") {
            this.OnEscClick();
          }
        }
      }
    },

    OnEscClick(event) {
      if (event != undefined) {
        if (event == '<ESC>' || event.indexOf('<ESC>') > 0) {
          this.ActionCodBarras = '<ESC>';
        }
      }

      if (event != undefined) {
        if (event == '<F5>' || event.indexOf('<F5>') > 0) {
          this.ActionCodBarras = '<F5>';
          this.filterInput == null;
          this.showMP = false;
          this.ShowTPServicos = false;
          this.ShowDialogErro = false;
          this.OnClickBtnsUpdategrid('btnInicio');
        }
      }

      this.styleLiberaFuncionamento.pointerEvents = "visible";

      if (this.showInfosCardApontAberto) {
        this.$refs.infosCardApontAbertos.disabledBtn(false);
      } else if (this.showInfosCardServicos) {
        this.$refs.infosCardServicos.disabledBtn(false);
      }

      if (this.ShowTPServicos) {
        document.querySelector(".btn-abrir").disabled = false;
      }

      if (this.ShowMP) {
        if (this.ativar_modal) {
          this.$refs.ApontamentoAgrupado.CancelaMP();
        }

        this.ActionCodBarras = "";
        this.ShowMP = false;
        this.CancelarEventKeyUp = true;

      } else if (this.ShowModalFecharFinalizarApontamento) {
        if (this.ativar_modal) {
          this.$refs.ApontamentoAgrupado.CancelaMP();
        }

        this.ShowModalFecharFinalizarApontamento = false;
      } else if (this.ShowTPServicos) {
        if (this.ativar_modal) {
          this.$refs.ApontamentoAgrupado.CancelaMP();
        }

        this.ShowTPServicos = false;
        this.styleLiberaFuncionamento.pointerEvents = "visible";
      } else if (this.showInfosCardApontAberto) {
        this.showInfosCardApontAberto = false;
      } else if (this.showInfosCardServicos) {
        this.showInfosCardServicos = false;
      } else if (this.ShowDialogErro) {
        document.querySelector(".btnModalOK").click();
      } else {
        ControllerEvents.Voltar();
      }
    },

    AtalhosTeclado() {
      document.body.addEventListener("keyup", this.OnClickAtalhosTeclados);
    },

    // Recebe a varialvel ShowModalLoadCards do componente GridCardsApontamentosAbertos
    RecebeShowModalLoadCards(valor) {
      this.ShowVersao = true;
      this.showBtnTodasMaquinas = false;
      this.ShowGridApontamentoAberto = valor;
    },

    VimDoCardServicos(valor) {
      this.vimDo_cardServico = valor;
    },

    OnUpdateResourcePopUp(res) {
      if (res.length > 0) {
        this.resourceModalBTNS = res;
      }
    },
    OnFecharApontamentoAberto() {
      localStorage.setItem("codigo_fechamento", 2);
      document.querySelector(".btn-mp").disabled = false;
      this.OnSelectModal("fechamento_apontamento");
    },

    // Vem do GridCardsServicos
    OnShowDetalhesOS(pResource, pResourceFiles, pMostraDetalhes) {
      if (pMostraDetalhes) {
        this.ShowCardMaquina = !pMostraDetalhes;
        this.ShowModalLoadCards = !pMostraDetalhes;
        this.ShowCardFunc = !pMostraDetalhes;
        this.ShowCardServico = !pMostraDetalhes;
        this.ShowCalculadora = !pMostraDetalhes;
        this.resourceDetalhesOS = pResource;
        this.resourceFilesOS = pResourceFiles;
        this.ShowDetalhesOS = pMostraDetalhes;
        this.ShowModalGRV = !pMostraDetalhes;
        this.AtualizacaoGrid = "os_detalhes";
      }
    },

    // Vem do GridCardsApontamentosAbertos
    OnShowDetalhesApontaOS(pResource, pResourceFiles, pMostraDetalhes) {
      if (pMostraDetalhes) {
        this.ShowCardMaquina = !pMostraDetalhes;
        this.ShowModalLoadCards = !pMostraDetalhes;
        this.ShowCardFunc = !pMostraDetalhes;
        this.ShowCardServico = !pMostraDetalhes;
        this.ShowCalculadora = !pMostraDetalhes;
        this.resourceDetalhesOS = pResource;
        this.resourceFilesOS = pResourceFiles;
        this.ShowDetalhesOS = pMostraDetalhes;
        this.ShowModalGRV = !pMostraDetalhes;
        this.ShowBtnInicio = true;
        this.AtualizacaoGrid = "os_detalhes_apont_abertos";
      }
    },
    AcaoModalErroVue(event) {
      if (event == "voltar_maquinas") {
        $(".btn-voltar").click();
      }

      this.AcaoErroModal = "";
    },
    async forceReloadUltVersao(event) {
      sessionStorage.setItem("dt_atualizacao", new Date());
      document.location.reload(true); // Passando o parametro como verdadeiro para não deixar cache em memoria
    },
    async AtualizaUltimaVersao() {
      let dataHoraAtual = new Date();

      let dataUltAtualizacao = new Date(
        sessionStorage.getItem("dt_atualizacao")
      );

      let diferencams = new Date(dataHoraAtual - dataUltAtualizacao);

      let diffMins = Math.round(((diferencams % 86400000) % 3600000) / 60000);

      if (diffMins >= 15) {
        return true;
      } else {
        return false;
      }
    },

    OnAbrirApontamento() {
      this.dataModal = "tp_servicos";
      this.AbrindoApontamento(true).then(this.OnIniciarApontamento);
    },

    OnAbrirMotivoDeParada(res) {
      this.showInfosCardApontAberto = false;

      let JSONApontamento = {
        versao: this.versaoClient,
        Acao: 200,
        cod_empresa: res.cod_empresa,
        estilo_apontamento: localStorage.getItem("estilo_apontamento"),
        OS: sessionStorage.getItem("OS"),
        PP: localStorage.getItem("PROCESSO_PRODUTIVO"),
        cod_os: res.cod_os,
        cod_os_aux: res.cod_os_aux,
        CodFuncionario: parseInt(sessionStorage.getItem("cod_funcionario")),
        CodMaquina: parseInt(res.cod_maquina),
        CodTpServico: parseInt(
          sessionStorage.getItem("tp_servicos_selecionado")
        ),
        CodTpServicoMaquina: parseInt(
          sessionStorage.getItem("tp_servico_maquina")
        ),
        Finalizar: true,
      };

      this.resourceAbrirMotivoParada = res;

      const body = GenerateCrypt(
        localStorage.getItem("token"),
        JSON.stringify(JSONApontamento)
      );

      RequestPostFecharApontamento(
        this.Host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave"),
        body
      )
        .then(this.OnAfterAbrirMotivoDeParada)
        .catch(this.alertErro);
    },

    GETTiposDeServico() {
      RequestGETTIPOServicos(
        this.Host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave")
      )
        .then(this.afterGetAbrirApontamento)
        .catch(this.alertErro);
    },

    afterGetAbrirApontamento(res) {
      this.SemConexaoServidor(false);
      this.UpdateResourceShowModal(res.data, "tp_servicos");
    },

    OnAfterAbrirMotivoDeParada() {
      localStorage.setItem("cod_os", this.resourceAbrirMotivoParada.cod_os);
      localStorage.setItem(
        "cod_os_aux",
        this.resourceAbrirMotivoParada.cod_os_aux
      );
      sessionStorage.setItem(
        "cod_empresa",
        this.resourceAbrirMotivoParada.cod_empresa
      );

      localStorage.setItem(
        "informar_tipo_servico",
        this.resourceAbrirMotivoParada.informar_tipo_servico
      );

      localStorage.setItem(
        "apontamento_por_peca",
        this.resourceAbrirMotivoParada.apontamento_por_peca
      );

      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resourceAbrirMotivoParada.cod_tp_servico
      );

      localStorage.setItem(
        "PROCESSO_PRODUTIVO",
        this.resourceAbrirMotivoParada.linha4
      );
      localStorage.setItem(
        "OS",
        this.resourceAbrirMotivoParada.cod_os_completo
      );
      localStorage.setItem("cod_os", this.resourceAbrirMotivoParada.cod_os);
      localStorage.setItem(
        "cod_os_aux",
        this.resourceAbrirMotivoParada.cod_os_aux
      );
      sessionStorage.setItem(
        "cod_maquina",
        this.resourceAbrirMotivoParada.cod_maquina
      );
      localStorage.setItem("acao", 100);

      this.OnAbrirApontamento();
    },

    OnFecharApontamento() {
      this.showInfosCardApontAberto = false;

      this.OnHabilitaDIVPrincipal(true);
      if (localStorage.getItem("codigo_fechamento") == 1) {
        var acao = 100; // motivo parada
      }

      if (localStorage.getItem("codigo_fechamento") == 2) {
        var acao = 200; // abrir apontamento
      }

      sessionStorage.setItem("CodigoFecharApontamento", acao);

      let JSONApontamento = {
        versao: this.versaoClient,
        cod_empresa: sessionStorage.getItem("cod_empresa"),
        Acao: acao,
        estilo_apontamento: localStorage.getItem("estilo_apontamento"),
        OS: sessionStorage.getItem("OS"),
        PP: localStorage.getItem("PROCESSO_PRODUTIVO"),
        cod_os: localStorage.getItem("cod_os"),
        cod_os_aux: sessionStorage.getItem("cod_os_aux"),
        CodFuncionario: parseInt(sessionStorage.getItem("cod_funcionario")),
        CodMaquina: parseInt(sessionStorage.getItem("cod_maquina")),
        CodTpServico: parseInt(
          sessionStorage.getItem("tp_servicos_selecionado")
        ),
        CodTpServicoMaquina: parseInt(
          sessionStorage.getItem("tp_servico_maquina")
        ),
        Finalizar: true,
      };

      const body = GenerateCrypt(
        localStorage.getItem("token"),
        JSON.stringify(JSONApontamento)
      );

      RequestPostFecharApontamento(
        this.Host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave"),
        body
      )
        .then(this.afterFechamentoApontamento)
        .catch(this.alertErro);
    },

    async afterFechamentoApontamento() {
      this.SemConexaoServidor(false);
      if (parseInt(sessionStorage.getItem("CodigoFecharApontamento")) == 100) {
        this.$refs.alert.alertGRV(
          "info",
          "Apontamento finalizado com sucesso!!",
          this.Modulo
        );
      } else {
        this.$refs.alert.alertGRV(
          "info",
          "Apontamento fechado com sucesso!!",
          this.Modulo
        );
      }
      this.OnHabilitaDIVPrincipal(false);

      const Atualiza = await this.AtualizaUltimaVersao();

      if (Atualiza) {
        await this.forceReloadUltVersao();
      } else {
        this.processosAbertos = [];

        this.OnResetVariaveis();
      }
    },

    AnimacaoBtns(btn) {
      $(btn).css("animation", "1s");
      $(btn).css("opacity", "0.5");

      setTimeout(function () {
        $(btn).css("animation", "1s");
        $(btn).css("opacity", "1");
      }, 200);
    },

    async OnClickBtnsUpdategrid(pbtn) {
      this.ShowGridApontamentoAberto = false;

      // Verifica em qual botão ocorrerá a animação
      if (pbtn == "btnVoltar") {
        this.AnimacaoBtns(".btn-voltar");
      } else {
        this.AnimacaoBtns(".btn-inicio");
      }

      if (this.AtualizacaoGrid == "maquinas" && pbtn == "btnVoltar") {
        if (this.showBtnTodasMaquinas) {
          $(".footer").css("justify-content", "flex-end");
        }

        this.imageFunc = "";
        this.MaquinaSelecionada = "";
        this.FuncionarioSelecionado = "";
        this.filterInput = "";

        const Atualiza = await this.AtualizaUltimaVersao();

        if (Atualiza) {
          await this.forceReloadUltVersao();
        } else {
          this.OnClearImgFuncMaquina();

          this.proximaAcao = cstShowCards.SHOWFUNCIONARIOS;
          this.OnAtualizaFuncionarios();
        }
      }

      if (this.AtualizacaoGrid == "servicos" && pbtn == "btnVoltar") {
        this.filterInput = "";
        this.MaquinaSelecionada = "";
        this.proximaAcao = cstShowCards.SHOWMAQUINAS;
        this.OnClearImgFuncMaquina().then(this.OnMaquinas);
      }

      if (this.AtualizacaoGrid == "os_detalhes") {
        this.ShowDetalhesOS = false;
        this.ShowModalGRV = !this.ShowDetalhesOS;
        this.ShowCardServico = !this.ShowDetalhesOS;
        this.ShowCalculadora = !this.ShowDetalhesOS;
        this.proximaAcao = cstShowCards.SHOWSERVICOS;
        this.AtualizacaoGrid = "servicos";
      }

      if (
        pbtn == "btnVoltar" &&
        this.AtualizacaoGrid == "os_detalhes_apont_abertos"
      ) {
        this.filterInput = "";
        this.MaquinaSelecionada = "";
        this.proximaAcao = cstShowCards.SHOWMAQUINAS;
        this.OnClearImgFuncMaquina().then(this.OnMaquinas);

        this.ShowDetalhesOS = false;
        this.ShowModalGRV = !this.ShowDetalhesOS;
        this.ShowCalculadora = !this.ShowDetalhesOS;
        this.AtualizacaoGrid = "maquinas";
      }

      if (
        pbtn == "btnInicio" &&
        this.AtualizacaoGrid == "os_detalhes_apont_abertos"
      ) {
        document.location.reload(true);
        this.proximaAcao = cstShowCards.SHOWFUNCIONARIOS;
      }

      if (pbtn == "btnInicio") {
        sessionStorage.setItem("OnServicos", false);
        this.MaquinaSelecionada = "";
        this.FuncionarioSelecionado = "";
        this.filterInput = "";
        this.imageFunc = "";
        this.imageMaquina = "";

        const Atualiza = await this.AtualizaUltimaVersao();

        if (Atualiza) {
          await this.forceReloadUltVersao();
        } else {
          this.OnClearImgFuncMaquina();
          this.proximaAcao = cstShowCards.SHOWFUNCIONARIOS;
          this.OnAtualizaFuncionarios();
        }
      }

      document.querySelector(".btn-voltar").blur();

      this.GetHabilitaAptSimultaneo(false);
    },

    async OnClearImgFuncMaquina() {
      // Limpar imagens do funcionário e máquina do header
      if (this.imageMaquina == null) {
        this.imageFunc = "";
      } else {
        this.imageMaquina = "";
      }
    },

    OnIniciarApontamento() {
      this.OnHabilitaDIVPrincipal(true);

      if (
        localStorage.getItem("estilo_apontamento_client") ==
        cstEstiloApontamento.PROCESSO_PRODUTIVO
      ) {
        var JSONApontamento = {
          versao: this.versaoClient,
          acao: parseInt(localStorage.getItem("acao")),
          cod_empresa: parseInt(sessionStorage.getItem("cod_empresa")),
          OS: sessionStorage.getItem("OS"),
          COD_OS_COMPLETO: sessionStorage.getItem("COD_OS_COMPLETO"),
          apontamento_normal: true,
          PP: localStorage.getItem("PROCESSO_PRODUTIVO"),
          CodFuncionario: parseInt(sessionStorage.getItem("cod_funcionario")),
          CodMaquina: parseInt(sessionStorage.getItem("cod_maquina")),
          CodTpServico: parseInt(
            sessionStorage.getItem("tp_servicos_selecionado")
          ),
          CodTpServicoMaquina: sessionStorage.getItem("tp_servico_maquina"),
          QtdePecas: parseFloat(localStorage.getItem("qtdPecas")),
        };
      } else if (
        localStorage.getItem("estilo_apontamento_client") ==
        cstEstiloApontamento.OS
      ) {
        var JSONApontamento = {
          versao: this.versaoClient,
          acao: 100,
          cod_empresa: parseInt(sessionStorage.getItem("cod_empresa")),
          OS: parseInt(localStorage.getItem("cod_os")),
          COD_OS_COMPLETO: sessionStorage.getItem("COD_OS_COMPLETO"),
          apontamento_normal: true,
          OS_AUX: parseInt(sessionStorage.getItem("cod_os_aux")),
          CodFuncionario: parseInt(sessionStorage.getItem("cod_funcionario")),
          CodMaquina: parseInt(sessionStorage.getItem("cod_maquina")),
          CodTpServico: parseInt(
            sessionStorage.getItem("tp_servicos_selecionado")
          ),
          CodTpServicoMaquina: sessionStorage.getItem("tp_servico_maquina"),
          QtdePecas: parseInt(localStorage.getItem("qtdPecas")),
        };
      } else {
        if (
          localStorage.getItem("estilo_apontamento") == "PROCESSO_PRODUTIVO"
        ) {
          var JSONApontamento = {
            versao: this.versaoClient,
            acao: parseInt(localStorage.getItem("acao")),
            cod_empresa: parseInt(sessionStorage.getItem("cod_empresa")),
            OS: sessionStorage.getItem("OS"),
            COD_OS_COMPLETO: sessionStorage.getItem("COD_OS_COMPLETO"),
            apontamento_normal: true,
            PP: localStorage.getItem("PROCESSO_PRODUTIVO"),
            CodFuncionario: parseInt(sessionStorage.getItem("cod_funcionario")),
            CodMaquina: parseInt(sessionStorage.getItem("cod_maquina")),
            CodTpServico: parseInt(
              sessionStorage.getItem("tp_servicos_selecionado")
            ),
            CodTpServicoMaquina: sessionStorage.getItem("tp_servico_maquina"),
            QtdePecas: parseFloat(localStorage.getItem("qtdPecas")),
          };
        } else {
          var JSONApontamento = {
            versao: this.versaoClient,
            acao: 100,
            cod_empresa: parseInt(sessionStorage.getItem("cod_empresa")),
            OS: parseInt(localStorage.getItem("cod_os")),
            COD_OS_COMPLETO: sessionStorage.getItem("COD_OS_COMPLETO"),
			apontamento_normal: true,
            OS_AUX: parseInt(sessionStorage.getItem("cod_os_aux")),
            CodFuncionario: parseInt(sessionStorage.getItem("cod_funcionario")),
            CodMaquina: parseInt(sessionStorage.getItem("cod_maquina")),
            CodTpServico: parseInt(
              sessionStorage.getItem("tp_servicos_selecionado")
            ),
            CodTpServicoMaquina: sessionStorage.getItem("tp_servico_maquina"),
            QtdePecas: parseInt(localStorage.getItem("qtdPecas")),
          };
        }
      }

      localStorage.setItem("jsonSend", JSON.stringify(JSONApontamento));

      const Body = GenerateCrypt(
        localStorage.getItem("token"),
        JSON.stringify(JSONApontamento)
      );

      if (this.dataModal == "tp_servicos") {
        try {
          RequestPostAbrirApontamento(
            this.Host,
            localStorage.getItem("token"),
            sessionStorage.getItem("chave"),
            Body
          )
            .then(this.afterAbrirApontamento)
            .catch(this.alertErro);
        } catch (error) {
          console.log(error);
        }
      }

      if (this.dataModal == "motivo_parada") {
        RequestPostAbrirApontamento(
          this.Host,
          localStorage.getItem("token"),
          sessionStorage.getItem("chave"),
          Body
        )
          .then(this.afterAbrirMotivoParada)
          .catch(this.alertErro);
      }
    },

    async afterAbrirMotivoParada() {
      this.SemConexaoServidor(false);
      this.$refs.alert.alertGRV(
        "info",
        "Motivo de parada aberto com sucesso!!",
        this.Modulo
      );

      const Atualiza = await this.AtualizaUltimaVersao();

      if (Atualiza) {
        await this.forceReloadUltVersao();
      } else {
        this.OnHabilitaDIVPrincipal(false).then(this.OnResetVariaveis);
      }
    },

    async afterAbrirApontamento() {
      this.SemConexaoServidor(false);

      PostLogGRV(this.versaoClient).catch((erro) => {
        console.log(erro);
      });

      this.$refs.alert.alertGRV(
        "info",
        "Apontamento aberto com sucesso!!",
        this.Modulo
      );

      const Atualiza = await this.AtualizaUltimaVersao();

      if (Atualiza) {
        await this.forceReloadUltVersao();
      } else {
        this.OnHabilitaDIVPrincipal(true).then(this.OnResetVariaveis);
      }
    },

    SemConexaoServidor(pShowMessage) {
      this.ShowMessageSemConexaoServidor = pShowMessage;
    },

    OnEventModal(event) {
      if (event == "AbrirApontamento") {
        this.OnIniciarApontamento();
      }
      if (event == "CancelModal") {
        Log("CancelModal Home");

        this.OnHabilitaBtnsCards("disabled", false).then(
          this.OnHabilitaDIVPrincipal(false)
        );

        this.titleModal = "";
      }
      if (event == "CancelModalSemConexaoServidor") {
        this.OnHabilitaBtnsCards("disabled", false).then(
          this.OnHabilitaDIVPrincipal(false)
        );
      }
    },

    OnSelectMP() {
      this.showInfosCardServicos = false;
      this.showInfosCardApontAberto = false;
      this.ShowMP = false;
      this.styleLiberaFuncionamento.pointerEvents = "visible";
      this.AbrindoMP(true).then(this.OnIniciarApontamento);
    },
    OnSelectFecharFinalizarApontamento() {
      this.showInfosCardApontAberto = false;
      this.OnFecharApontamento();
    },

    OnSelectTP() {
      this.showInfosCardServicos = false;
      this.showInfosCardApontAberto = false;

      this.OnCloseModalTPServico();
      this.AbrindoApontamento(true)
        .then(this.OnIniciarApontamento)
        .catch(this.alertErro);
    },

    OnSelectModal(pDataModal) {
      $("#grv-modal").modal("hide");

      if (pDataModal == "tp_servicos") {
        this.AbrindoApontamento(true)
          .then(this.OnIniciarApontamento)
          .catch(this.alertErro);
      }

      if (pDataModal == "fechamento_apontamento") {
        localStorage.setItem("codigo_fechamento", 2);
        this.OnFecharApontamento();
      }
    },

    OnShowModalTPServicos(res) {
      this.dataModal = "tp_servicos";
      this.titleModal = "Tipo de Serviço";
      this.typeModal = "input-list";
      this.resourcesModal = res;

      this.ShowTPServicos = true;
    },

    UpdateResourceShowModal(res, pChamada) {
      if (pChamada == "tp_servicos") {
        if (localStorage.getItem("informar_tipo_servico") == "true") {
          this.dataModal = "tp_servicos";
          this.titleModal = "Tipo de Serviço";
          this.typeModal = "input-list";
          this.resourcesModal = res;
          $("#grv-modal").modal("show");
        } else {
          this.dataModal = "tp_servicos";
          this.AbrirApontamento();
        }
      }

      if (pChamada == "fechar_mp") {
        localStorage.setItem("codigo_fechamento", 2);
        this.OnFecharApontamento();
      }

      if (pChamada == "modalfechamento") {
        this.titleModal = "Ação";
        this.typeModal = "input-list";
        this.dataModal = "fechamento_apontamento";
        this.ShowModalFecharFinalizarApontamento = true;
        document.querySelector(".btn-mp").disabled = false;
        this.OnHabilitaBtnsCards("disabled", false);
      }

      if (pChamada == "modal-popup") {
        this.AcaoModalPopup = "btnsLayoutMobile";
        this.descricaoModalBTNS = "O que você deseja fazer?";
        this.resourceModalBTNS = res;
        $("#modal-popup").modal("show");
      }

      if (pChamada == "MP_da_os") {
        this.titleModal = "Motivo de Parada";
        this.typeModal = "input-list";
        this.dataModal = "motivo_parada";

        this.OnMotivoDeParada(res, "apontamento_normal");
      }
    },

    async OnModalGRV() {
      this.ShowModalGRV = true;
    },

    OnMotivoDeParada(res, origem) {
      this.origemMP = origem;
      this.dataModal = "motivo_parada";
      this.resourcesModal = res;

      if (document.querySelector(".btn-fechar") != null) {
        document.querySelector(".btn-fechar").disabled = false;
      }

      this.ShowMP = true;
    },

    UpdateMaquinaSelecionada(pMaquina, pImagem) {
      this.MaquinaSelecionada = pMaquina;
      this.imageMaquina = pImagem;
      this.OnShowCard("Maquinas");
      this.MaquinaSelecionada = pMaquina;
    },

    async OnServicos() {
      this.titleModalLoadCards = "Atualizando Serviços.";

      let xEstiloApontamento;

      if (
        localStorage.getItem("estilo_apontamento_client") ==
        cstEstiloApontamento.OS
      ) {
        xEstiloApontamento = cstEstiloApontamento.OS;
      }

      if (
        localStorage.getItem("estilo_apontamento_client") ==
        cstEstiloApontamento.PROCESSO_PRODUTIVO
      ) {
        xEstiloApontamento = "PROCESSO_PRODUTIVO";
      }

      if (
        localStorage.getItem("estilo_apontamento_client") ==
        cstEstiloApontamento.PADRAO
      ) {
        xEstiloApontamento = localStorage.getItem("estilo_apontamento");
      }

      RequestGETServicos(
        this.Host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave"),
        xEstiloApontamento
      )
        .then(this.AtualizaGridServico)
        .catch(this.alertErro);
    },
    OnTodasMaquinas() {
      this.ShowCardFunc = false;
      this.ShowCardServico = false;
      this.ShowModalLoadCards = true;
      this.titleModalLoadCards = "Atualizando Máquinas.";

      RequestGETMaquinas(
        this.Host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave"),
        true
      )
        .then(this.AtualizaGridMaquina)
        .catch(this.alertErro);
    },
    OnAtualizandoMotivosDeParada(pShow) {
      this.ShowModalMP = pShow;
    },
    OnMaquinas() {
      this.ShowCardFunc = false;
      this.ShowCardServico = false;
      this.ShowModalLoadCards = true;
      this.titleModalLoadCards = "Atualizando Máquinas.";

      RequestGETMaquinas(
        this.Host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave"),
        false
      )
        .then(this.AtualizaGridMaquina)
        .catch(this.alertErro);
    },

    OnListenEventPortrait(event) {
      if (event.matches) {
        // Portrait mode
        this.mostrarCalc = false;
      } else {
        // Landscape
        this.mostrarCalc = true;
      }
    },

    OnShowCalc() {
      let portrait = window.matchMedia("(orientation: portrait)");
      portrait.addEventListener("change", this.OnListenEventPortrait);

      if (portrait.matches) {
        // Portrait mode
        this.mostrarCalc = false;
      } else {
        // Landscape
        this.mostrarCalc = true;
      }
    },
    async OnUpdateResourceServicos(res) {
      this.resourceServicos = res;
    },

    OnAfterUpdateResourceServicos() {
      this.ShowModalLoadCards = false;
      this.ShowCards = true;
      this.ShowCardServico = true;
      this.AtualizacaoGrid = "servicos";
      this.OnShowBtns();
    },

    AtualizaGridServico(res) {
      this.SemConexaoServidor(false);
      $(".footer").css("justify-content", "space-between");
      this.ShowVersao = true;
      this.showBtnTodasMaquinas = false;

      this.filterInput = "";
      this.ShowCardMaquina = false;
      this.ShowGridApontamentoAberto = false;

      for (let index = 0; index < res.data.length; index++) {
        if (res.data[index].dt_prevista_os != null) {
          let dataPrevistaOSFormatada = new Date(
            res.data[index].dt_prevista_os
          );
          dataPrevistaOSFormatada = dateFormat(
            dataPrevistaOSFormatada,
            "dd/MM/yyyy"
          );
          res.data[index].data_prevista_os_formatada =
            dataPrevistaOSFormatada.toString();
        } else {
          res.data[index].data_prevista_os_formatada = "";
        }
      }

      if (res.data.length > 0) {
        if (
          res.data
            .map((e) => e)
            .sort((a, b) => a.priority - b.priority)
            .filter((item) => !this.processosAbertos.includes(item.linha4))
            .length > 0
        ) {
          this.styleContainerCards.height = "100%";
          this.OnUpdateResourceServicos(res.data).then(
            this.OnAfterUpdateResourceServicos
          );
        } else {
          this.MessageDialogErro = "NÃO EXISTE PROCESSO PRODUTIVO PARA O TIPO DE SERVIÇO SELECIONADO";
          this.AcaoErroModal = "voltar_maquinas";
          this.ShowDialogErro = true;
          this.AtualizacaoGrid = "servicos";
          this.OnShowBtns();
        }
      } else {
        this.MessageDialogErro = "NÃO EXISTE PROCESSO PRODUTIVO PARA O TIPO DE SERVIÇO SELECIONADO";
        this.AcaoErroModal = "voltar_maquinas";
        this.ShowDialogErro = true;
        this.AtualizacaoGrid = "servicos";
        this.OnShowBtns();
      }
    },

    async AtualizaGridMaquina(res) {
      this.SemConexaoServidor(false);
      this.resourceMaquina = res.data[0].maquinas;
      this.showBtnTodasMaquinas = res.data[0].maquinas[0].filter;

      if (this.ShowCardMaquina && this.showBtnTodasMaquinas) {
        this.showBtnTodasMaquinas = false;
        $(".footer").css("justify-content", "flex-end");
      } else {
        $(".footer").css("justify-content", "space-between");
      }

      this.resourceApontamento = [];
      this.resourceModalBTNS = [];

      if (res.data[0].apontamentos_abertos != null) {
        this.resourceApontamento = res.data[0].apontamentos_abertos;
        this.processosAbertos = [];

        for (let index = 0; index < this.resourceApontamento.length; index++) {
          this.processosAbertos.push(this.resourceApontamento[index].linha4);
        }

        this.styleContainerCards.height = "50%";
        this.ShowGridApontamentoAberto = true;
      } else {
        this.resourceApontamento = [];
        this.resourceModalBTNS = [];
        this.ShowGridApontamentoAberto = false;
        this.styleContainerCards.height = "100%";
      }

      this.afterAtualizaGridMaquina();
    },

    afterAtualizaGridMaquina() {
      this.ShowModalLoadCards = false;
      this.ShowCards = true;
      this.ShowCardMaquina = true;
      this.AtualizacaoGrid = "maquinas";
      this.OnShowBtns();
    },

    async AbrindoApontamento(pShow) {
      if (pShow == true) {
        $(".container-central").css("display", "none");
      } else {
        $(".container-central").css("display", "block");
      }

      (this.titleModalLoadCards = "Abrindo Apontamento"),
        (this.MensagemModalLoadCard = `Aguarde...`),
        (this.ShowModalLoadCards = pShow);
    },

    async AbrindoMP(pShow) {
      if (pShow == true) {
        $(".container-central").css("display", "none");
      } else {
        $(".container-central").css("display", "block");
      }

      (this.titleModalLoadCards = "Abrindo Motivo de Parada"),
        (this.MensagemModalLoadCard = `Aguarde...`),
        (this.ShowModalLoadCards = pShow);
    },

    async OnLoadCardPadrao(pShow) {
      (this.titleModalLoadCards = "Atualizando Funcionários"),
        (this.MensagemModalLoadCard = `Aguarde...`),
        (this.ShowModalLoadCards = pShow);
    },

    async OnShowCard(event) {
      if (event == "Funcionarios") {
        this.filterInput = "";
        this.ShowCardFunc = false;
        this.proximaAcao = cstShowCards.SHOWMAQUINAS;
        this.OnLoadCardPadrao(true).then(this.OnMaquinas).catch(this.alertErro);
      }
      if (event == "Maquinas") {
        this.proximaAcao = cstShowCards.SHOWSERVICOS;
        this.OnLoadCardPadrao(true).then(this.OnServicos).catch(this.alertErro);
      }
    },

    async OnHabilitaDIVPrincipal(value) {
      if (value) {
        this.styleLiberaFuncionamento.pointerEvents = "none";
      } else {
        this.styleLiberaFuncionamento.pointerEvents = "visible";
      }
    },

    UpdateFuncionarioSelecionado(
      pFuncionario,
      pImgFuncionario,
      pFuncionarioSupervidor
    ) {
      this.FuncionarioSelecionado = pFuncionario;
      this.imageFunc = pImgFuncionario;
      this.FuncionarioSupervisor = pFuncionarioSupervidor;
      this.OnShowCard("Funcionarios");
    },

    async OnHabilitaBtnsCards(disabled, value) {
      $(".btnModalCard").attr(disabled, value);
    },

    OnResetVariaveisOS(pOrigem) {
      this.OnResetVariaveis();

      if (pOrigem == "Motivo_Parada") {
        this.$refs.alert.alertGRV(
          "info",
          "Motivo de parada aberto com sucesso!!",
          this.Modulo
        );
      } else {
        this.$refs.alert.alertGRV(
          "info",
          "Apontamento aberto com sucesso!!",
          this.Modulo
        );
      }
    },

    async OnResetVariaveis() {
      this.OnHabilitaDIVPrincipal(false);
      this.ativar_modal = false;
      this.titleModal = "";
      this.ShowDialogErro = false;
      this.MessageDialogErro = "";
      this.AcaoErroModal = "";
      this.showInfosCardServicos = false;
      this.showInfosCardApontAberto = false;
      this.ShowModalFecharFinalizarApontamento = false;
      this.vimDo_cardServico = null;
      this.processosAbertos = [];
      this.resourceDetalhesOS = [];
      this.FuncionarioSupervisor = false;
      this.resourceFilesOS = [];
      this.resourceModalBTNS = [];
      this.ShowCardFunc = true;
      this.ShowMessageSemConexaoServidor = false;
      this.mensagemSemProcesso = false;
      this.ShowCardMaquina = false;
      this.ShowCardServico = false;
      this.ShowDetalhesOS = false;
      this.showBtnTodasMaquinas = false;
      this.ShowBtnMostrarTodosFuncionarios = false;
      this.ShowCalculadora = true;
      this.valueCalculator = "";
      this.ShowCards = false;
      this.ShowModalGRV = true;
      this.ShowModalLoadCards = true;
      this.titleModalLoadCards = "Atualizando Funcionários";
      this.MensagemModalLoadCard = "Aguarde..";
      this.FuncionarioSelecionado = "";
      this.MaquinaSelecionada = "";
      this.imageFunc = "";
      this.imageMaquina = "";
      this.tipoAtualizacao = "";
      this.PlaceHolderInput = "Pesquisa";
      this.TipoInput = "text";
      this.atualizouMaquinas = false;
      this.filterInput = "";
      this.resourceMaquina = [];
      this.resourceApontamento = [];
      this.resourceServicos = [];
      this.ShowGridApontamentoAberto = false;
      this.AtualizacaoGrid = "funcionarios";
      this.HabilitaBtnAptSimultaneo = false;
      sessionStorage.setItem("tipos_de_servicos", "");
      sessionStorage.setItem("cod_funcionario", "");
      sessionStorage.setItem("tp_servico_maquina", "");
      sessionStorage.setItem("tp_servicos_selecionado", "");
      localStorage.setItem("codigo_fechamento", "");
      sessionStorage.setItem("cod_maquina", "");
      localStorage.setItem("qtdPecas", "");
      localStorage.setItem("MensagemErro", "");
      localStorage.setItem("PROCESSO_PRODUTIVO", "");
      sessionStorage.setItem("OS", "");
      localStorage.setItem("apontamento_por_peca", "");
      localStorage.setItem("acao", "");
      localStorage.setItem("cod_os", "");
      sessionStorage.setItem("cod_os_aux", "");

      await this.AtualizaHost();
      this.OnAtualizaFuncionarios();
    },

    OnShowBtns() {
      if (this.AtualizacaoGrid == "funcionarios") {
        this.placeholder_pesquisa = "Pesquisar Funcionário";
        this.div_btn_agrupados = false;
        this.ShowBtnVoltar = false;
        this.ShowBtnInicio = false;
        this.HabilitaBtnAptSimultaneo = false;
      }

      if (this.AtualizacaoGrid == "maquinas") {
        if (this.$store.state.funcionario_apont_grupo == 1) {
          this.div_btn_agrupados = true;
        } else {
          this.div_btn_agrupados = false;
        }

        this.placeholder_pesquisa = "Pesquisar Máquina";
        this.ShowBtnVoltar = true;
        this.ShowBtnInicio = false;
        this.HabilitaBtnAptSimultaneo = false;
      }

      if (this.AtualizacaoGrid == "servicos") {
        if (
          localStorage.getItem("estilo_apontamento") == "PROCESSO_PRODUTIVO"
        ) {
          this.placeholder_pesquisa = "Pesquisar Processo Produtivo";
        } else {
          this.placeholder_pesquisa = "Pesquisar Serviço";
        }

        this.div_btn_agrupados = false;
        this.ShowBtnInicio = true;
        this.ShowBtnVoltar = true;

        this.BuscaStatus();
      }
    },

    BuscaStatus() {
      let status = parseInt(
        sessionStorage.getItem("ExecutaServicosSimultaneamente")
      );

      if (status == 1) {
        this.HabilitaBtnAptSimultaneo = true;
      } else {
        this.HabilitaBtnAptSimultaneo = false;
      }
    },

    OnAtualizaTodosFuncionarios() {
      if (localStorage.getItem("versaoServer") >= "1.1.0.7") {
        try {
          this.titleModalLoadCards = "Atualizando Funcionários";
          this.ShowCardMaquina = false;
          this.ShowCardServico = false;
          this.ShowModalLoadCards = true;

          ResquestGETFuncionariosProducao(
            this.Host,
            localStorage.getItem("token"),
            sessionStorage.getItem("chave"),
            ""
          )
            .then(this.AtualizaGridFuncionario)
            .catch(this.alertErro);
        } catch (error) { }
        console.log(error);
      } else {
        try {
          this.titleModalLoadCards = "Atualizando Funcionários";
          this.ShowCardMaquina = false;
          this.ShowCardServico = false;
          this.ShowModalLoadCards = true;

          ResquestGETFuncionariosProducao(
            this.Host,
            localStorage.getItem("token"),
            sessionStorage.getItem("chave")
          )
            .then(this.AtualizaGridFuncionario)
            .catch(this.alertErro);
        } catch (error) {
          console.log(error);
        }
      }
    },
    OnAtualizaFuncionarios() {
      if (localStorage.getItem("versaoServer") >= "1.1.0.7") {
        try {
          this.titleModalLoadCards = "Atualizando Funcionários";
          this.ShowCardMaquina = false;
          this.ShowCardServico = false;
          this.ShowModalLoadCards = true;
          this.ShowDetalhesOS = false;
          this.ShowCalculadora = true;

          if (localStorage.getItem("setores") != undefined) {
            this.setores = localStorage.getItem("setores");
          }

          ResquestGETFuncionariosProducao(
            this.Host,
            localStorage.getItem("token"),
            sessionStorage.getItem("chave"),
            this.setores
          )
            .then(this.AtualizaGridFuncionario)
            .catch(this.alertErro);
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          this.titleModalLoadCards = "Atualizando Funcionários";
          this.ShowCardMaquina = false;
          this.ShowCardServico = false;
          this.ShowModalLoadCards = true;
          if (
            localStorage.getItem("setores") == "" ||
            localStorage.getItem("setores") == undefined
          ) {
            this.ShowBtnMostrarTodosFuncionarios = false;

            ResquestGETFuncionariosProducao(
              this.Host,
              localStorage.getItem("token"),
              sessionStorage.getItem("chave"),
              ""
            )
              .then(this.AtualizaGridFuncionario)
              .catch(this.alertErro);
          } else {
            this.ShowBtnMostrarTodosFuncionarios = true;
            ResquestGETFuncionariosProducaoSetor(
              this.Host,
              localStorage.getItem("token"),
              sessionStorage.getItem("chave"),
              localStorage.getItem("setores")
            )
              .then(this.AtualizaGridFuncionario)
              .catch(this.alertErro);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },

    AtualizaTokenInfos(res) {
      if (res.status === 200) {
        localStorage.setItem("token", res.data.accessToken);
        // localStorage.setItem("cnpj", res.data.cnpj);
        localStorage.setItem("local", res.data.ip);
        localStorage.setItem("versaoServer", res.data.versao);

        if (res.data.email != undefined) {
          localStorage.setItem("emailUser", res.data.email);
        }

        localStorage.setItem(
          "PossuiChaoFabricaSemPapel",
          res.data.PossuiChaoFabricaSemPapel
        );

        this.ShowVersao = true;

        localStorage.setItem(
          "ordenacao_apontamento",
          res.data.ordenacao_apontamento
        );
        localStorage.setItem(
          "visualizacao_apontamento",
          res.data.visualizacao_apontamento
        );
        localStorage.setItem("exibe_cliente", res.data.exibe_cliente);
        localStorage.setItem("estilo_apontamento", res.data.estilo_apontamento);

        if (this.proximaAcao == cstShowCards.SHOWFUNCIONARIOS) {
          this.OnAtualizaFuncionarios();
        } else if (this.proximaAcao == cstShowCards.SHOWMAQUINAS) {
          this.OnClearImgFuncMaquina().then(this.OnMaquinas);
        } else if (this.proximaAcao == cstShowCards.SHOWSERVICOS) {
          this.OnLoadCardPadrao(true)
            .then(this.OnServicos)
            .catch(this.alertErro);
        }
      }
    },

    AtualizaToken(res) {
      this.SemConexaoServidor(false);

      if (res.status === 200) {
        localStorage.setItem("token", res.data.accessToken);
        // localStorage.setItem("cnpj", res.data.cnpj);
        localStorage.setItem("local", res.data.ip);
        localStorage.setItem("versaoServer", res.data.versao);

        if (res.data.email != undefined) {
          localStorage.setItem("emailUser", res.data.email);
        }

        localStorage.setItem(
          "PossuiChaoFabricaSemPapel",
          res.data.PossuiChaoFabricaSemPapel
        );

        this.ShowVersao = true;

        localStorage.setItem(
          "ordenacao_apontamento",
          res.data.ordenacao_apontamento
        );
        localStorage.setItem(
          "visualizacao_apontamento",
          res.data.visualizacao_apontamento
        );
        localStorage.setItem("exibe_cliente", res.data.exibe_cliente);
        localStorage.setItem("estilo_apontamento", res.data.estilo_apontamento);

        useAppConnectionWs().connectar()

        this.OnAtualizaFuncionarios();
      }
    },

    AtualizaGridFuncionario(res) {
      this.SemConexaoServidor(false);
      try {
        this.resourceFuncionario = res.data;

        this.ShowVersao = true;
        this.showBtnTodasMaquinas = false;
        $(".footer").css("justify-content", "space-between");

        this.styleContainerCards.height = "100%";
        this.ShowModalLoadCards = false;
        this.ShowCards = true;
        this.ShowCardFunc = true;

        if (
          this.setores != "" &&
          localStorage.getItem("versaoServer") >= "1.1.0.7"
        ) {
          this.ShowBtnMostrarTodosFuncionarios = true;
        }

        this.AtualizacaoGrid = "funcionarios";
        this.OnShowBtns();
      } catch (error) {
        console.log(error);
      }
    },

    Login(res) {
      this.AtualizacaoGrid = "token";
      sessionStorage.setItem("chave", res.data);
      login(
        sessionStorage.getItem("chave"),
        1,
        localStorage.getItem("usuario"),
        localStorage.getItem("usuariocps"),
        localStorage.getItem("5f4dcc3b5aa765d61d8327deb882cf99")
      )
        .then(this.AtualizaToken)
        .catch(this.alertErro);
    },

    async SetTentativaConexao() {
      if (parseInt(sessionStorage.getItem("tentativas_conexao")) < 3) {
        let lNumerosTentativas =
          parseInt(sessionStorage.getItem("tentativas_conexao")) + 1;

        this.ShowModalLoadCards = true;
        this.MensagemModalLoadCard = lNumerosTentativas + " / 3";
        this.titleModalLoadCards = "Conectando...";
        sessionStorage.setItem("tentativas_conexao", lNumerosTentativas);

        await this.AtualizaHost();
        this.OnResetVariaveis();
        //  setTimeout(this.OnLogin, 1000);
      } else {
        this.AcaoModalPopup = "config";
        this.descricaoModalBTNS =
          "Erro na conexão com o servidor deseja ir até as configurações ?";
        $("#modal-popup").modal("show");
      }
    },

    async alertErro(error) {
      this.MessageDialogErro = "";
      this.showInfosCardServicos = false;
      this.showInfosCardApontAberto = false;

      console.log(error);

      if (error.hasOwnProperty('messageErro')) {
        localStorage.setItem("MensagemErro", error.messageErro);
        this.MessageDialogErro = error.messageErro;
        this.ShowDialogErro = true;
        this.AbrindoApontamento(false);
      }

      if (error.response.status == 0) {
        this.SemConexaoServidor(true);
        this.OnHabilitaBtnsCards("disabled", false);
        this.OnHabilitaDIVPrincipal(false);
        this.AcaoModalPopup = "config";
        await this.SetHostOn(this.Host, false).then(this.OnCheckHost);
        setTimeout(this.SetTentativaConexao, 2000);
        this.AbrindoApontamento(false);
      }

      if (error.response.status == 503) {
        this.OnHabilitaBtnsCards("disabled", false);
        this.OnHabilitaDIVPrincipal(false);
        this.AcaoModalPopup = "config";
        await this.SetHostOn(this.Host, false).then(this.OnCheckHost);
        setTimeout(this.SetTentativaConexao, 2000);
        this.AbrindoApontamento(false);
      }

      if (error.response.status == 403) {
        localStorage.setItem("MensagemErro", error.response.data.mensagem);
        this.MessageDialogErro = error.response.data.mensagem;
        this.ShowDialogErro = true;
        this.AbrindoApontamento(false);
      }

      if (error.response.status == 404) {
        this.OnHabilitaBtnsCards("disabled", false);
        this.OnHabilitaDIVPrincipal(false);
        await this.SetHostOn(this.Host, false).then(this.OnCheckHost);
        this.AcaoModalPopup = "config";
        setTimeout(this.SetTentativaConexao, 2000);
        this.AbrindoApontamento(false);
      }

      if (error.response.status == 400) {
        if (error.response.data.mensagem == cstsUuarioNãoEncontrado) {
          login(
            this.Host,
            localStorage.getItem("chave_mensageria"),
            1,
            localStorage.getItem("usuario"),
            localStorage.getItem("usuariocps"),
            localStorage.getItem("5f4dcc3b5aa765d61d8327deb882cf99")
          )
            .then(this.AtualizaTokenInfos)
            .catch(this.alertErro);
        } else {
          this.OnHabilitaBtnsCards("disabled", true);
          this.OnHabilitaDIVPrincipal(true);
          localStorage.setItem("MensagemErro", error.response.data.mensagem);
          this.AcaoModalPopup = "config";
          this.descricaoModalBTNS = error.response.data.mensagem;

          this.descricaoModalBTNS =
            this.descricaoModalBTNS + " deseja ir ate as configurações ?";

          $("#modal-popup").modal("show");
          this.AbrindoApontamento(false);
        }
      }

      if (error.response.status == 504) {
        this.OnHabilitaBtnsCards("disabled", false);
        this.OnHabilitaDIVPrincipal(false);
        this.AcaoModalPopup = "config";
        setTimeout(this.SetTentativaConexao, 2000);
        this.AbrindoApontamento(false);
      }
    },

    async OnLogin() {
      await this.AtualizaHost();

      login(
        this.Host,
        localStorage.getItem("chave_mensageria"),
        1,
        localStorage.getItem("usuario"),
        localStorage.getItem("usuariocps"),
        localStorage.getItem("5f4dcc3b5aa765d61d8327deb882cf99")
      )
        .then(this.AtualizaToken)
        .catch(this.alertErro);
    },

    SendRequestHome(event) {
      let JaCaiu = false;

      // Atualiza a variável quando é alterado a caixa de pesquisa pelo teclado
      if (this.CaixaFiltroFocada == false) {
        this.filterInput = event;

        JaCaiu = true;
      }

      this.$el.addEventListener("keydown", this.OnDelete);

      if (this.filterInput.length > 0) {
        if (this.atualizaInput && JaCaiu == false) {
          this.filterInput = this.filterInput.concat(event.substr(-1));
          this.atualizaInput = true;
        }
      } else {
        if (event != "delete") {
          this.filterInput = event;
        } else {
          this.filterInput = "";
        }
      }
    },

    OnDelete(event) {
      if (
        event.key == "Backspace" &&
        document.querySelector("body").classList.contains("modal-open") == false
      ) {
        // Tecla Backspace e Modal fechado
        this.filterInput = this.filterInput.slice(0, -1);
        this.atualizaInput = false;
        event.preventDefault();
      } else {
        this.atualizaInput = true;
      }
    },
    SendBackSpace(event) {
      if (event == "backspace") {
        this.filterInput = "";
        return;
      }
    },

    sendResquestCalculator(event) {
      if (event == "C") {
        this.filterInput = this.filterInput.slice(0, -1);
        return;
      } else {
        this.filterInput = this.filterInput.concat(event);
      }
    },

    // Verifica modo de tela
    OnListenEventBrowser(event) {
      if (event.matches) {
        this.pwa = false;
      } else {
        this.pwa = true;
      }
    },

    // Caso o modo de tela troque ele faz o botão de config aparecer
    OnShowBtnConfig() {
      let tela = window.matchMedia("(display-mode: browser)");
      tela.addEventListener("change", this.OnListenEventBrowser);

      if (tela.matches) {
        this.pwa = false;
      } else {
        // Landscape
        this.pwa = true;
      }
    },

    OnConfig() {
      if (localStorage.getItem("portaRest") == undefined) {
        this.$router.push("/config");
        throw "";
      }
    },

    OnServicos() {
      this.titleModalLoadCards = "Atualizando Serviços.";

      let xEstiloApontamento;

      if (
        localStorage.getItem("estilo_apontamento_client") ==
        cstEstiloApontamento.OS
      ) {
        xEstiloApontamento = cstEstiloApontamento.OS;
      }

      if (
        localStorage.getItem("estilo_apontamento_client") ==
        cstEstiloApontamento.PROCESSO_PRODUTIVO
      ) {
        xEstiloApontamento = "PROCESSO_PRODUTIVO";
      }

      if (
        localStorage.getItem("estilo_apontamento_client") ==
        cstEstiloApontamento.PADRAO
      ) {
        xEstiloApontamento = localStorage.getItem("estilo_apontamento");
      }

      RequestGETServicos(
        this.Host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave"),
        xEstiloApontamento
      )
        .then(this.AtualizaGridServico)
        .catch(this.alertErro);
    },

    desabilitaHeaderFooter(e) {
      if (e == true) {
        $(".header").css("display", "none");
        $(".footer").css("display", "none");
      } else {
        $(".header").css("display", "flex");
        $(".footer").css("display", "flex");
      }
    },

    GravaDataAtual(event) {
      sessionStorage.setItem("dt_atualizacao_interacao", new Date());

      this.seqBarr = `${this.seqBarr}${event.keyCode}`
      if ((this.seqBarr.includes('1774'))) {
        this.seqBarr = '';
        event.preventDefault()
      }
    },

    checaInatividade() {
      let dt_atual = new Date();
      let dt_atualizacao = new Date(
        sessionStorage.getItem("dt_atualizacao_interacao")
      );

      sessionStorage.setItem("dt_atual", dt_atual);

      let diferencams = new Date(dt_atual - dt_atualizacao);
      let diffMins = ((diferencams % 86400000) % 3600000) / 60000;

      if (diffMins >= 3 && this.AtualizacaoGrid != "funcionarios") {
        sessionStorage.setItem("dt_atualizacao_interacao", dt_atual);
        window.location.reload(true);
      }
    },

    ShowDialog(value) {
      this.ShowDialogErro = value;
    }
  },

  mounted() {
    sessionStorage.setItem("ClickCancelar", "0");
    this.OnShowCalc();
    this.OnShowBtnConfig();

    this.OnFocusInputFilter();

    // Código referente ao reload na tela por inatividade
    document.body.addEventListener("click", this.GravaDataAtual);
    document.body.addEventListener("keypress", this.GravaDataAtual);
    document.body.addEventListener("keydown", this.GravaDataAtual);
    setInterval(this.checaInatividade, 5000);

    if (window.screen.width > 480 && window.screen.height > 480) {
      this.AtalhosTeclado();
    }
  },

  async created() {
    this.ConfirmarTecladoVirtual();

    if (localStorage.getItem("qtd_cards") == null) {
      localStorage.setItem("qtd_cards", 15);
    }

    this.qtd_cards = parseInt(localStorage.getItem("qtd_cards"));

    if (
      sessionStorage.getItem("OnServicos") == undefined ||
      sessionStorage.getItem("OnServicos") == "false"
    ) {
      if (
        sessionStorage.getItem("tentativas_conexao") == null ||
        sessionStorage.getItem("tentativas_conexao") == "3"
      ) {
        sessionStorage.setItem("tentativas_conexao", 0);
      }

      if (localStorage.getItem("estilo_apontamento_client") == null) {
        localStorage.setItem(
          "estilo_apontamento_client",
          cstEstiloApontamento.PADRAO
        );
      }

      this.chave = sessionStorage.getItem("chave");

      window.localStorage.setItem("sessao", this.sessao);

      this.OnConfig();

      if (localStorage.getItem("chave_mensageria") == "") {
        this.$router.push("/config");
      } else {
        this.OnShowBtns();

        sessionStorage.setItem(
          "chave",
          localStorage.getItem("chave_mensageria")
        );

        await this.AtualizaHost();

        const Atualiza = await this.AtualizaUltimaVersao();

        if (Atualiza) {
          await this.forceReloadUltVersao();
        } else {
          sessionStorage.setItem("dt_atualizacao", new Date());
          sessionStorage.setItem("dt_atualizacao_interacao", new Date());

          try {
            login(
              this.Host,
              localStorage.getItem("chave_mensageria"),
              1,
              localStorage.getItem("usuario"),
              localStorage.getItem("usuariocps"),
              localStorage.getItem("5f4dcc3b5aa765d61d8327deb882cf99")
            )
              .then(this.AtualizaToken)
              .catch(this.alertErro);

            this.EventUpdradeGridBtns = "funcionarios";
          } catch (error) {
            console.log(error);
          }
        }
      }
    } else {
      sessionStorage.setItem("OnServicos", false);
      this.ShowVersao = true;
      this.ShowCardFunc = false; // Desativa o GridFuncionarios
      this.ShowCardServico = true; // Ativa o GridServicos
      this.OnServicos();
    }
  },
};
</script>

<style scoped>
#cards_title_text_apt_simultaneo {
  margin-left: 10px;
}

.cards_title_apt_simultaneo {
  background-color: #545454;
  border-radius: 5px;
  margin-top: 5px;
}

.cards_title_apt_simultaneo_mob {
  background-color: #545454;
  position: sticky;
  top: 0;
  overflow-y: auto;
  max-height: 20px;
  width: 100%;
}

#container-apt_simultaneo {
  display: flex;
  justify-content: space-around;
}

#container-apt_simultaneo_mob {
  display: block;
  height: 100%;
  overflow-y: scroll;
}

#cards_apt_simultaneo {
  width: 45%;
  color: #fff;
}

#cards_apt_simultaneo_mob {
  width: 100%;
  height: 50%;
  color: #fff;
  position: sticky;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.v-divider {
  opacity: unset;
  color: #808080;
}

.img {
  width: 5vw;
  height: 6vh;
  margin-left: 1%;
  border-radius: 10%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.container-pai {
  width: 100vw;
  height: 100vh;
}

.header {
  position: fixed;
  top: 0;
  background-color: #004c89;
  display: flex;
  align-items: center;
  width: 100%;
  height: 8%;
  z-index: 1;
}

#routerLink {
  margin-left: 2%;
}

#imgConfig {
  font-size: 6vh;
  color: #fff;
}

.inputVue {
  position: absolute;
  right: 0;
}

.h1-header {
  width: max-content;
  height: 80%;
}

.h1_qtdcards {
  font-family: "Poppins";
  font-size: 22px;
  width: 90%;
  text-align: center;
  margin: 10px auto;
  background: #ffff00;
  padding: 5px 10px;
  border-radius: 10px;
}

.header-funcionario {
  width: 60%;
}

.header-funcionario h1 {
  font-size: clamp(0.5em, 1.1vw, 1.1vw);
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  color: #fff;
  font-weight: bold;
  margin: 0 0 0 1%;
}

.h1-funcionario-InfosUser {
  width: 100%;
}

.header-funcionario-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2%;
  width: 100%;
  height: 10%;
}

.container-central {
  position: fixed;
  top: 8%;
  height: 84%;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  display: center;
  justify-content: center;
}

.container-central #descricao-sem-conexao {
  top: 0;
  margin: 0;
  font-size: 30px;
  font-family: "Poppins";
  color: black;
}

.container-central h5 {
  position: sticky;
  top: 0;
  z-index: 1;
  margin: 0;
  font-size: clamp(0.6rem, 1vw, 1.5vw);
  font-family: "Poppins";
  background: #545454;
  color: #fff;
  padding: 0.7%;
}

#container-apontamentos-abertos {
  width: 100%;
  height: 50%;
  overflow-y: scroll;
  flex-flow: column;
}

#container-grid-apontamento {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

#container-gridcards1,
#container-gridcards2,
#container-gridcards3 {
  width: 100%;
  flex-flow: row wrap;
}

#container-gridcards3 {
  width: 100%;
  color: #fff;
  overflow-y: auto;
  margin: 0;
}

.footer {
  width: 100%;
  height: 8%;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #004c89;
  z-index: 1;
}

.container-pai .footer button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-size: 3vw;
  font-weight: bold;
  width: 50%;
  height: 80%;
  border-radius: 5px;
  border: none;
  margin: 1%;
}

.footer h1 {
  font-size: clamp(0.2rem, 1em, 2.5em);
  color: #fff;
  margin: 0 0 0 10px;
}

.v-field__clearable {
  color: white;
  background-color: white;
}

.div-buttons-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
  height: 100%;
  margin-right: 0.5%;
}

.btn-inicio {
  color: #000;
  background-color: orange;
}

.btn-MostrarTodosFuncionarios {
  color: #000;
  background-color: yellow;
  width: 20%;
}

.btn-MostrarTodasMaquinas {
  color: #000;
  background-color: yellow;
  width: 20%;
}

.btn-voltar {
  color: #000;
  background-color: yellow;
  width: 20px;
}

.input-group {
  width: 35%;
  height: 90%;
  display: flex;
  align-items: center;
  margin: 0 1%;
}

.input-group-text {
  display: flex;
  justify-content: center;
  width: 15%;
  height: 90%;
  cursor: pointer;
}

/* Quando estiver com o celular em pé */
@media (orientation: portrait) {
  .input-group {
    width: 34%;
  }

  .input-group-text {
    width: 25%;
  }
}

@media (orientation: portrait) {
  .header {
    height: 6%;
  }

  .h1-header {
    width: 23%;
  }

  .header-funcionario h1 {
    font-size: clamp(0.1em, 0.5em + 1vw, 1.6vw);
    margin-left: 3px;
  }

  #imgConfig {
    font-size: 4vh;
  }

  .container-central {
    height: 58%;
    width: 100%;
    top: 6%;
  }

  .container-central h5 {
    font-size: clamp(0.4rem, 2vw, 1.8rem);
  }

  .img {
    margin-left: 2%;
    width: 8vw;
    height: 4vh;
    border-radius: 20%;
  }

  .footer {
    width: 100%;
    height: 6%;
  }

  .div-buttons-footer {
    width: 40%;
  }
}

@media (orientation: portrait) and (max-width: 480px) {
  .header {
    height: 10%;
  }

  .header-funcionario h1 {
    font-size: clamp(0.1em, 0.5em + 1vw, 3vw);
    margin-left: 3px;
  }

  .container-central h5 {
    font-size: clamp(0.8em, 1.8vw, 2vw);
  }

  .footer {
    height: 10%;
  }

  .img {
    width: 10vw;
    height: 6vh;
  }

  .container-central {
    top: 10%;
    width: 100%;
    height: 48%;
  }
}

@media (orientation: landscape) and (max-width: 480px) {
  .header {
    height: 10%;
  }

  .header-funcionario h1 {
    font-size: clamp(0.1em, 0.5em + 1vw, 3vw);
    margin-left: 3px;
  }

  .img {
    width: 5vw;
    height: 7vh;
  }

  .container-central {
    top: 10%;
    height: 80%;
  }

  .container-central h5 {
    font-size: clamp(0.6rem, 1.8vw, 2vw);
  }

  .footer {
    height: 10%;
  }
}

@media (orientation: landscape) and (max-height: 480px) {
  .h1_qtdcards {
    width: 92%;
  }
}
</style>
