<template>
  <div class="cols">
    <div :style="style" :id="'Card' + numeroCard">
      <div class="conteudoCards">
        <div class="div-conteudo" @click="RemoveAptSimultaneo">
          <h1 id="linha-1">
            {{ resource.linha1 }}
          </h1>
          <h2 id="linha-2">
            {{ resource.linha2 }}
          </h2>

          <h2
            v-if="
              estilo_apontamento == 'PROCESSO_PRODUTIVO' ||
              estilo_apontamento == 'Processo Produtivo'
            "
            id="linha_tipo_servico"
          >
            Tp. Serv.: {{ resource.tiposervico }}
          </h2>

          <h2 id="linha-3">{{ dataEntrega }}</h2>

          <div
            v-if="
              estilo_apontamento == 'PROCESSO_PRODUTIVO' ||
              estilo_apontamento == 'Processo Produtivo'
            "
            style="display: flex"
          >
            <h2 id="linha-4">Nº Proc. Prod.: {{ resource.linha4 }}</h2>

            <h2 id="linha-4" style="margin-left: 30px">
              Nº da Seq.: {{ resource.seq }}
            </h2>
          </div>

          <h2 id="linha-5" :class="this.class_linha5">
            {{ resource.cliente }}
          </h2>
        </div>
        <div class="conteudoCards-btns" style="justify-content: flex-end">
          <button type="button" class="btn-MP" @click="RemoveAptSimultaneo">
            Remover
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import { FormatDate } from "../../../../../CPSWeb/front-end/library/utils";

export default {
  name: "GridCardsApontamentosServicosVue",

  emits: ["RemoveAptSimultaneo"],

  props: {
    resource: Object,
  },

  data() {
    return {
      numeroCard: "",
      heightCard: "",
      style: {},
      estilo_apontamento: null,
    };
  },

  methods: {
    RemoveAptSimultaneo() {
      this.$emit("RemoveAptSimultaneo", this.resource);
    },
  },

  mounted() {
    this.style = {
      width: 100 + "%",
      height: 100 + "%",
      padding: 0 + " " + 12 + "px",
    };
  },

  created() {
    if (!(localStorage.getItem("estilo_apontamento_client") == undefined)) {
      if (localStorage.getItem("estilo_apontamento_client") == "Padrão") {
        this.estilo_apontamento = localStorage.getItem("estilo_apontamento");
      } else {
        this.estilo_apontamento = localStorage.getItem(
          "estilo_apontamento_client"
        );
      }
    } else {
      this.estilo_apontamento = localStorage.getItem("estilo_apontamento");
    }
  },

  computed: {
    dataEntrega() {
      let dataEntrega = new Date(this.resource.dt_prevista_os);

      if (
        localStorage.getItem("ordenacao_apontamento") == "DTENTREGAPRIORIDADE"
      ) {
        dataEntrega = new Date(this.resource.dt_prevista_os);
        dataEntrega.setHours(dataEntrega.getHours() + 3);

        if (dataEntrega > new Date(1989, 12, 29)) {
          return `Previsão de entrega: ${FormatDate(
            this.resource.dt_prevista_os.toLocaleString().substring(0, 10)
          )}`;
        } else {
          return "Sem data prevista para entrega.";
        }
      } else {
        dataEntrega = new Date(this.resource.dt_incio_previsto);
        dataEntrega.setHours(dataEntrega.getHours() + 3);

        if (dataEntrega > new Date(1989, 12, 29)) {
          const dataHora = new Date(dataEntrega);
          const dia = dataHora.getDate().toString().padStart(2, "0");
          const mes = (dataHora.getMonth() + 1).toString().padStart(2, "0");
          const ano = dataHora.getFullYear();
          const hora = dataHora.getHours().toString().padStart(2, "0");
          const minuto = dataHora.getMinutes().toString().padStart(2, "0");
          const segundo = dataHora.getSeconds().toString().padStart(2, "0");

          const dataHoraFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;

          return `Previsão de início: ` + dataHoraFormatada;
        } else {
          return "Sem data prevista de início.";
        }
      }
    },
  },
};
</script>
  
<style scoped>
.cols {
  margin: 10px 0;
}

.conteudoCards {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 2px 5px 12px #a0a0a0;
  border: 1px solid #808080;
  transition: 0.5s;
}

.div-conteudo {
  cursor: pointer;
  width: 100%;
  height: auto;
}

#linha-1,
.linha-1 {
  font-size: clamp(0.7rem, 0.3rem + 1vw, 1vw);
  margin: 5px 0 5px 9px;
  overflow: hidden;
  word-wrap: break-word;
  font-weight: 900;
  font-family: "Poppins";
  color: blue;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.linha-1,
.linha-2 {
  display: -webkit-box;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

#linha-2,
.linha-2,
#linha_tipo_servico,
#linha-3,
#linha-4,
#linha-seq,
#linha-5 {
  font-size: clamp(0.7rem, 0.3rem + 1vw, 1vw);
  margin: 0 0 5px 9px;
  word-wrap: break-word;
  overflow: hidden;
  font-weight: 900;
  font-family: "Poppins";

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  color: black;
}

#linha-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  color: black;
}

#linha-5 {
  margin: 0 0 0 9px;
}

.conteudoCards-btns {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-MP {
  width: 38%;
  margin: 0 5px;
  height: 100%;
  font-size: 12px;
  padding: 6px 12px;
  color: #fff;
  border: none;
  border-radius: 5px;
  background: black;
}

@media handheld and (orientation: portrait) {
  #linha-1,
  #linha-2,
  #linha_tipo_servico,
  #linha-3,
  #linha-4,
  #linha-seq,
  #linha-5 {
    font-size: clamp(0.6rem, 0.2rem + 1vw, 3vw);
  }
}

@media handheld and (max-width: 480px), (max-height: 480px) {
  .conteudoCards-btns {
    margin-bottom: 10px;
  }

  #linha-1 {
    margin: 5px 0 0.3rem 9px;
  }

  #linha-2,
  #linha_tipo_servico,
  #linha-3,
  #linha-4,
  #linha-seq {
    margin: 0 0 0.3rem 9px;
  }

  #linha-5 {
    margin: 0 0 0 9px;
  }
}
</style>