<template >
  <div class="modals">
    <PopupPDF ref="PopUPPDF" :url="url" :nomeArquivo="nomeArquivo"
    /> 
  </div>
  <div class="container-pdf">
    <div v-if="false"  ref="pdfContainer" id="viewer"></div>
    <button type="button" class="btns" id="btnCloseModal" @click="TelaCheia">
      <i class="bi bi-arrows-fullscreen" id="closefullscreen1"></i>
    </button>
    <button type="button" class="btns" id="ZoomOut" @click="zoomOut">
      <i class="bi bi-zoom-out"></i>
    </button>
    <button type="button" class="btns" id="ZoomIn" @click="zoomIn">
      <i class="bi bi-zoom-in"></i>
    </button>

    <button type="button" class="btns" id="rotate-right" @click="RotateRight">
      <i class="bi bi-arrow-clockwise"></i>
    </button>
    <button type="button" class="btns" id="rotate-left" @click="RotateLeft">
      <i class="bi bi-arrow-counterclockwise"></i>
    </button>
  </div>
</template>

<script>
import {
  HABILITALOGS,
  AMBIENTETESTE,
  JSONMaquinas,
  JSONFuncionarios,
  JSONServicos,
  MensagemErroUsuarioNEncontrado,
  MensagemErroTokenNPreechido,
  MensagemTimeOut,
} from "../../../../../CPSWeb/front-end/library/Publics";

import {
  getCNPJServidorRest,
  Log,
  BASE_URL,
  GenerateCrypt,
  dateFormat,
} from "../../../../../CPSWeb/front-end/library/utils";

import PopupPDF from "../Modal/PopupPDF.vue";

export default {
  name: "pdf",
  components: {PopupPDF},
  props: {
    url: String,
    nomeArquivo: String,
    OnPopUP : Boolean
    
    // HeightBodyPDF: Number,
  },
  emits: [],

  data() {
    return {
      adobeApiReady: false,
      previewFilePromise: null,
      blob: null,
      base64: "",
    };
  },

  updated() {
    if ($("#popupPDF").is(":visible")) {
      $("#popupPDF").modal("hide");
    }

    // if ($("#erro-modal").is(":visible")) {
    //   $("#erro-modal").modal("hide");
    // }

    this.adobeApiReady = true;
    this.renderPdf(this.url, this.nomeArquivo);
  },

  mounted() {
    document.addEventListener("adobe_dc_view_sdk.ready", () => {
      this.adobeApiReady = true;
    });

    this.adobeApiReady = true;
    this.renderPdf(this.url, this.nomeArquivo);
  },

  created() {
    Log(HABILITALOGS, "url blob do created pdf", this.url);
    Log(HABILITALOGS, "nome do arquivo pdf ", this.nomeArquivo);
  },

  methods: {
    MaisZoom(){
      this.zoomIn();
    },

    MenosZoom(){
      this.zoomOut();
    },

    RotateRight(){
      AdobeDC.pdfViewer.getInstance().rotatePages(90);
    },

    RotateLeft(){
      AdobeDC.pdfViewer.getInstance().rotatePages(-90);
    },

    renderPdf(url, fileName) {
      if (!this.adobeApiReady) {
        return;
      }
      const previewConfig = {
        defaultViewMode: "FIT_WIDTH",
        showAnnotationTools: false,
      };
      this.$refs.pdfContainer.innerHTML = "";
      let viewer = document.createElement("div");
      viewer.id = "viewer";
      this.$refs.pdfContainer.appendChild(viewer);

     
      if (process.env.NODE_ENV === "development") {
        console.log("Running in development mode");
        var ChaveAcesso = "a2cda9737c464b90a0d06b13060ca0c3"; // Aqui minha chave
      } else {
        console.log("Running in production mode");
        var ChaveAcesso = "84ad86d8ef254c0481a1cb46fa27e527";
      }

      let adobeDCView = new AdobeDC.View({
        clientId: ChaveAcesso,
        divId: "viewer",
      });

      this.previewFilePromise = adobeDCView.previewFile(
        {
          content: {
            location: {
              url: url,
            },
          },
          metaData: {
            fileName: fileName,
            id: fileName,
          },
        },
        {
          // embedMode: "SIZED_CONTAINER",
          showDownloadPDF: false,
          showPrintPDF: false,
          showAnnotationTools: false,
        },
        previewConfig
      );
      
    },

    alertErro(ERRO) {
      alert(ERRO);
    },

    nextPage() {
      this.previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAPIs().then((apis) => {
          apis
            .getCurrentPage()
            .then((currentPage) => apis.gotoLocation(currentPage + 1))
            .catch((error) => console.error(error));
        });
      });
    },

    previousPage() {
      this.previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAPIs().then((apis) => {
          apis
            .getCurrentPage()
            .then((currentPage) => {
              if (currentPage > 1) {
                return apis.gotoLocation(currentPage - 1);
              }
            })
            .catch((error) => console.error(error));
        });
      });
    },

    zoomIn() {
      this.previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAPIs().then((apis) => {
          apis
            .getZoomAPIs()
            .zoomIn()
            .catch((error) => console.error(error));
        });
      });
    },

    zoomOut() {
      this.previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAPIs().then((apis) => {
          apis
            .getZoomAPIs()
            .zoomOut()
            .catch((error) => console.error(error));
        });
      });
    },

    TelaCheia() {
      $("#popupPDF").modal("show");
      this.$refs.PopUPPDF.OnShowPDF();
    },
  },
};
</script>

<style scoped>
.container-pdf{
  position: relative;
  width: 100%;
  height: 100%;
}

#viewer {
  width: 100%;
  height: 100%;
  position: relative;
}

.btns {
  width: 50px;
  height: 50px;
  background: #0d6efd;
  color: #fff;
  border-radius: 50%;
  border: none;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 20px 20px;
}

#ZoomOut {
  margin: 0 0 80px 20px;
}

#ZoomIn {
  margin: 0 0 140px 20px;
}

#rotate-right{
  margin: 0 0 200px 20px;
}

#rotate-left{
  margin: 0 0 260px 20px;
}

@media (max-width: 480px), (max-height: 480px) {
  .btns {
    width: 40px;
    height: 40px;
  }

  #ZoomOut {
    margin: 0 0 70px 20px;
  }

  #ZoomIn {
    margin: 0 0 120px 20px;
  }

  #rotate-right{
    margin: 0 0 180px 20px;
  }

  #rotate-left{
    margin: 0 0 240px 20px;
  }
}
</style>