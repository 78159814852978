<template>
  <DialogErro :show="this.showDialog" @show-dialog="GetShowDialog" :message="this.mensagemAviso"/>
  <PopupFinalizar :show="this.ModalFinalizar" @FecharModalFinalizar="FecharModalFinalizar" 
  @FinalizarApontamentoAgrupadoCompleto="FinalizarApontamentoAgrupadoCompleto" 
  @AbrirAbaFinalizarApontamentoAgrupadoParcial="AbrirAbaFinalizarApontamentoAgrupadoParcial()"/>

  <div v-if="FinalizarApontamentoParcial" style="height: 100%; margin-top: 0">
    <FinalizarParcial :host="host" :length_array="length_array" :arrayItensApontamentos="itensFinalizarApontamento"
    @FecharFinalizarParcial="FecharFinalizarParcial" @AddItemRemovido="AddItemRemovido"
    @RemoverItemSelecionado="RemoverItemSelecionado" @OnError="OnError" @AtualizaLista="this.$emit('AtualizaLista')"/>
  </div>
  <div v-else style="height: 100%; margin-top: -18">
    <v-row v-if="TemApontamentoAgrupado" cols="12" style="height: 100%; margin-top: 0">
      <v-col class="div_aponta_agrupado" cols="12" xs="12" sm="3" style="border-right: 2px solid #808080; padding: 0">
        <div v-if="show_lista_aponta_agrupado" class="aponta_agrupados">
          <div style="margin-left: 10px">
            <v-text-field style="margin: 10px" class="filter" v-model="filter" @keyup="FilterUpperCase()"
              @click:clear="filter = ''" clearable label="Pesquisar" hide-details="auto"></v-text-field>
          </div>
  
          <div @click="
            MostrarListaOS(resourceAgrupamento)
            " :id="'aponta_agrupados' + resourceAgrupamento.codigo"
            v-for="resourceAgrupamento in resourceAgrupaApontamento" :key="resourceAgrupamento.codigo" style="
              border-bottom: 2px solid #a9a9a9;
              display: flex;
              align-items: center;
              cursor: pointer;
              margin-left: 10px;
            ">
            <div v-if="resourceAgrupamento.qtde_apont == 1" style="
                width: 40px;
                height: 40px;
                background: #157347;
                margin: 5px 0 5px 5px;
              "></div>
            <div v-else style="
                width: 40px;
                height: 40px;
                background: #bb2d3b;
                margin: 5px 0 5px 5px;
              "></div>
  
            <h1 id="linha-1">
              {{ resourceAgrupamento.codigo }} -
              {{ resourceAgrupamento.descricao }}
            </h1>
          </div>
        </div>
        <div v-else style="display: flex; align-items: center; height: 100%">
          <ProgressCharts color="#004689" :size="100" :width="6" />
        </div>
      </v-col>
      <v-col cols="12" xs="12" sm="7" style="padding: 0">
        <div v-if="show_itens_aponta_agrupado">
          <h5 style="margin-bottom: 1px">{{ aponta_agrupado_selecionado }}</h5>
          <h5>Máquina Selecionada: {{ MaquinaSelecionada }}</h5>
          <div class="div_lista_os" v-for="resource in infosOS" :key="resource.guid_linha"
            style="border-bottom: 2px solid #a9a9a9">
            <h1>
              <b>OS: {{ resource.cod_os_completo }} -
                {{ resource.produto_nome }} - QTDE: {{ resource.qtde.toFixed(2) }}
              </b>
            </h1>
            <h1>
              OP: {{ resource.pp_seq }} - [{{ resource.n_desenho }}({{
                resource.revisao_desenho
              }})({{ resource.posicao_desenho }})] - {{ resource.tipo_servico }} -
              N°Proc: {{ resource.cod_pp }}
            </h1>
            <h1>
              {{ resource.cod_os_completo }} - {{ resource.produto_cod_interno }}
              {{ resource.produto_nome }}
            </h1>
            <h1 v-if="resource.pp_obs != null">
              {{ resource.pp_obs }}
            </h1>
          </div>
        </div>
  
        <div v-else style="display: flex; align-items: center; height: 100%">
          <ProgressCharts color="#004689" :size="100" :width="6" />
        </div>
      </v-col>
      <v-col cols="12" xs="12" sm="2" justify="center" style="border-left: 2px solid #a9a9a9; padding: 0">
        <div v-if="show_btns_aponta_agrupado" class="div-btns">
          <v-btn v-if="mostrarBtns != 0 || status == 0" class="btns-aponta-agrupado" id="btn0" size="x-large"
            style="background: #157347; color: #fff" @click="AbrirApontamentoAgrupado()">
            Abrir
          </v-btn>
          <v-btn v-else class="btns-aponta-agrupado" id="btn0" size="x-large" disabled
            style="background: #157347; color: #fff">
            Abrir
          </v-btn>
  
          <!-- MP -->
          <v-btn v-if="!(mostrarBtns > 0)" class="btns-aponta-agrupado" id="btn1" size="x-large"
            style="background: #000; color: #fff" @click="MPApontamentoAgrupado()">
            MP
          </v-btn>
          <v-btn v-else class="btns-aponta-agrupado" id="btn1" size="x-large" disabled
            style="background: #000; color: #fff">
            MP
          </v-btn>
  
          <!-- Fechar MP -->
          <v-btn v-if="mostrarBtns > 0" class="btns-aponta-agrupado" id="btn2" size="x-large"
            style="background: #ffca2c; color: #000" @click="FecharMPApontamentoAgrupado()">
            Fechar MP
          </v-btn>
          <v-btn v-else class="btns-aponta-agrupado" id="btn2" size="x-large" disabled
            style="background: #ffca2c; color: #000">
            Fechar MP
          </v-btn>
  
          <!-- Fechar -->
          <v-btn v-if="mostrarBtns == 0 && status == 1" class="btns-aponta-agrupado" id="btn3" size="x-large"
            style="background: #bb2d3b; color: #fff" @click="FecharApontamentoAgrupado()">
            Fechar
          </v-btn>
          <v-btn v-else class="btns-aponta-agrupado" id="btn3" size="x-large" disabled
            style="background: #bb2d3b; color: #fff">
            Fechar
          </v-btn>
  
          <!-- Finalizar -->
          <div v-if="supervisor == 'true'">
            <v-btn v-if="mostrarBtns == 0 && status == 1" class="btns-aponta-agrupado" id="btn4" size="x-large"
              style="background: #ffca2c; color: #000" @click="FinalizarApontamentoAgrupado()">
              Finalizar
            </v-btn>
            <v-btn v-else class="btns-aponta-agrupado" id="btn4" size="x-large" disabled
              style="background: #ffca2c; color: #000">
              Finalizar
            </v-btn>
          </div>
        </div>
        <div v-else style="display: flex; align-items: center; height: 100%">
          <ProgressCharts color="#004689" :size="100" :width="6" />
        </div>
      </v-col>
    </v-row>
    <v-row v-else cols="12" style="height: 100%; margin-top: 0">
      <div style="
          width: 100vw;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        ">
        <h1 style="
            color: #545454;
            text-align: center;
            font-size: 30px;
            font-family: 'Open Sans', sans-serif;
          ">
          {{ mensagemErro }}
        </h1>
      </div>
    </v-row>
  </div>
</template>

<script>
import {
  ResquestGETMotivoDeParada,
  RequestPostAbrirApontamentoAgrupado,
  RequestPostFecharApontamentoAgrupado,
  RequestPostFinalizarApontamentoAgrupado,
} from "../../../../../../CPSWeb/front-end/services/ApiRequest.js";
import DialogErro from "../DialogErro.vue";

import ProgressCharts from "../ProgressCharts.vue";

import PopupFinalizar from "./FInalizar/PopupFinalizar.vue";

import FinalizarParcial from "./FInalizar/FinalizarParcial.vue";

export default {
  name: "OSApontaAgrupado",

  components: {
    ProgressCharts,
    DialogErro,
    PopupFinalizar,
    FinalizarParcial
},

  props: {
    host: String,
    arrayApontaAgrupados: Array,
    arrayItensApontamentos: Array,
    MaquinaSelecionada: String,
    clickFirstApontamento: Boolean,
    mensagem: Boolean,
    versaoClient: String,
  },

  emits: [
    "OnMotivoDeParada",
    "AbrirApontamentoAgrupado",
    "FecharApontamentoAgrupado",
    "FinalizarApontamentoAgrupado",
    "AtualizaArray",
    "AtualizaStatusApontamento",
    "AvisoAbrirApontamentoAgrupado",
    "AtualizaLista"
  ],

  data() {
    return {
      infosOS: [],
      itensFinalizarApontamento: [],
      supervisor: sessionStorage.getItem("supervisor"),
      status: 0,
      cod_agrupamento: 0,
      aponta_agrupado_selecionado: "",
      cod_maquina: "",
      filter: "",
      TemApontamentoAgrupado: true,
      show_lista_aponta_agrupado: true,
      show_itens_aponta_agrupado: false,
      show_btns_aponta_agrupado: false,
      mensagemErro: "",
      mostrarBtns: 0,
      motivo_parada: 0,
      mensagemAviso: "",
      showDialog: false,
      ModalFinalizar: false,
      FinalizarApontamentoParcial: false,
      length_array: 0
    };
  },

  watch:{
    TemApontamentoAgrupado(newValue){
      if (this.mensagem) {
        this.mensagemErro = "Selecione uma máquina";
      } else {
        this.mensagemErro = "Nenhum apontamento agrupado encontrado para essa máquina";
      }
    }
  },

  computed: {
    resourceAgrupaApontamento() {
      if (this.arrayApontaAgrupados.length > 0) {
        this.TemApontamentoAgrupado = true;

        return this.arrayApontaAgrupados
          .map((e) => e)
          .sort((a, b) => a.priority - b.priority)
          .filter(({ linha_01 }) =>
            linha_01.includes(this.filter.toUpperCase())
          );
      } else {
        this.TemApontamentoAgrupado = false;

        return this.arrayApontaAgrupados;
      }
    },
  },

  mounted() {
    try {
      document.querySelector(".aponta_agrupados").children[1].click();
    } catch { }
  },

  methods: {
    // ABRIR
    GetShowDialog(pShowDialog){
      this.showDialog = pShowDialog
    },

    AbrirApontamentoAgrupado() {
      let xMP = [];
      let xEmExecucao = false;
      for (let i = 0; i < this.arrayApontaAgrupados.length; i++) {
        if (this.arrayApontaAgrupados[i].em_execucao == 1 || this.arrayApontaAgrupados[i].motivo_parada == 1) {
          xEmExecucao = true;
          xMP[i] =  this.arrayApontaAgrupados[i].codigo          
        }
      };

      if (!xEmExecucao || xMP.includes(parseInt(sessionStorage.getItem("cod_agrupamento")))) {
        this.disabledBtns(true);
        this.reloadDivs(false);

        const body = {
          cod_maquina: sessionStorage.getItem("cod_maquina"),
          cod_funcionario: sessionStorage.getItem("cod_funcionario"),
          cod_agrupamento: this.cod_agrupamento,
          versao_client: this.versaoClient,
        };

        RequestPostAbrirApontamentoAgrupado(
          this.host,
          localStorage.getItem("chave_mensageria"),
          localStorage.getItem("token"),
          body
        )
          .then(this.afterAbrirApontamentoAgrupado)
          .catch(this.OnError);
      } else {
        this.mensagemAviso = "Já existe um apontamento agrupado iniciado. Para abrir outro é necessário fechá-lo ou finalizá-lo."
        this.showDialog = true;
      }
    },

    afterAbrirApontamentoAgrupado(res) {
      this.mostrarBtns = 0;
      this.status = 1;      

      this.$emit("AtualizaStatusApontamento");

      this.reloadDivs(true);
      this.disabledBtns(false);
    },

    // MP
    MPApontamentoAgrupado() {
      let xCodsExecucao = [];
      for (let i = 0; i < this.arrayApontaAgrupados.length; i++) {
        if (this.arrayApontaAgrupados[i].em_execucao == 1) {
          xCodsExecucao[i] = this.arrayApontaAgrupados[i].codigo;
        }
      };

      if (xCodsExecucao.includes(parseInt(sessionStorage.getItem("cod_agrupamento"))) || xCodsExecucao == "") {
        this.disabledBtns(true);
        this.GETMotivosDeParada(sessionStorage.getItem("chave"));      
      } else {
        this.mensagemAviso = "Não será possível executar essa função porque já existe apontamento agrupado aberto. Para executar essa função será necessário fechá-lo ou finalizá-lo."
        this.showDialog = true;
      }
    },

    GETMotivosDeParada(chave) {
      ResquestGETMotivoDeParada(this.host, localStorage.getItem("token"), chave)
        .then(this.afterGetMotivosDeParada)
        .catch(this.OnError);
    },

    afterGetMotivosDeParada(res) {
      this.$emit("OnMotivoDeParada", res.data);
    },

    // FECHAR
    FecharApontamentoAgrupado() {
      this.disabledBtns(true);
      this.reloadDivs(false);

      const body = {
        cod_maquina: sessionStorage.getItem("cod_maquina"),
        cod_funcionario: sessionStorage.getItem("cod_funcionario"),
        cod_agrupamento: this.cod_agrupamento,
        versao_client: this.versaoClient,
      };

      RequestPostFecharApontamentoAgrupado(
        this.host,
        localStorage.getItem("chave_mensageria"),
        localStorage.getItem("token"),
        body
      )
        .then(this.afterFecharApontamentoAgrupado)
        .catch(this.OnError);
    },

    afterFecharApontamentoAgrupado(res) {
      this.status = 0;

      this.$emit("AtualizaStatusApontamento");

      this.reloadDivs(true);
      this.disabledBtns(false);
    },

    // FECHAR MP
    FecharMPApontamentoAgrupado() {
      this.disabledBtns(true);
      this.reloadDivs(false);

      const body = {
        cod_maquina: sessionStorage.getItem("cod_maquina"),
        cod_funcionario: sessionStorage.getItem("cod_funcionario"),
        cod_agrupamento: this.cod_agrupamento,
        versao_client: this.versaoClient,
      };

      RequestPostFecharApontamentoAgrupado(
        this.host,
        localStorage.getItem("chave_mensageria"),
        localStorage.getItem("token"),
        body
      )
        .then(this.afterFecharMPApontamentoAgrupado)
        .catch(this.OnError);
    },

    afterFecharMPApontamentoAgrupado(res) {
      this.status = 0;
      this.mostrarBtns = 0;

      this.$emit("AtualizaStatusApontamento");

      this.reloadDivs(true);
      this.disabledBtns(false);
    },

    // FINALIZAR
    FinalizarApontamentoAgrupado() {
      this.ModalFinalizar = true;
    },

    FinalizarApontamentoAgrupadoCompleto(){
      this.FecharModalFinalizar();
      this.disabledBtns(true);
      this.reloadDivs(false);

      let jsonSend = JSON.parse(localStorage.getItem("jsonSend"));

      const body = {
        cod_empresa: jsonSend.cod_empresa,
        cod_maquina: sessionStorage.getItem("cod_maquina"),
        cod_funcionario: sessionStorage.getItem("cod_funcionario"),
        cod_agrupamento: this.cod_agrupamento,
        versao_client: this.versaoClient,
      };

      RequestPostFinalizarApontamentoAgrupado(
        this.host,
        localStorage.getItem("chave_mensageria"),
        localStorage.getItem("token"),
        body
      )
        .then(this.afterFinalizarApontamentoAgrupadoCompleto)
        .catch(this.OnError);
    },

    afterFinalizarApontamentoAgrupadoCompleto(res) {
      this.$emit("AtualizaArray", this.cod_agrupamento);
      this.reloadDivs(true);
      this.disabledBtns(false);

      setTimeout(() => {
        try {
          document.querySelector(".aponta_agrupados").children[1].click();
        } catch {
          this.TemApontamentoAgrupado = false;
          this.mensagemErro =
            "Nenhum apontamento agrupado encontrado para essa máquina";
        }
      }, 10);
    },

    AbrirAbaFinalizarApontamentoAgrupadoParcial(){
      this.FinalizarApontamentoParcial = true;      
      this.itensFinalizarApontamento = this.infosOS;

      for (let i = 0; i < this.itensFinalizarApontamento.length; i++) {
        this.itensFinalizarApontamento[i].finalizarParcial = true;
      }

      this.length_array = this.itensFinalizarApontamento.length;

      this.FecharModalFinalizar();
    },

    RemoverItemSelecionado(resource){
      this.length_array = 0;

      for (let i = 0; i < this.itensFinalizarApontamento.length; i++) {
        if (resource.cod_pp == this.itensFinalizarApontamento[i].cod_pp) {
          this.itensFinalizarApontamento[i].finalizarParcial = false;
        }

        if (this.itensFinalizarApontamento[i].finalizarParcial == true) {
          this.length_array++;
        }
      }
    },

    AddItemRemovido(resource){
      this.length_array = 0;

      for (let i = 0; i < this.itensFinalizarApontamento.length; i++) {
        if (resource.cod_pp == this.itensFinalizarApontamento[i].cod_pp) {
          this.itensFinalizarApontamento[i].finalizarParcial = true;
        }

        if (this.itensFinalizarApontamento[i].finalizarParcial == true) {
          this.length_array++;
        }
      }
    },

    // OUTRAS FUNÇÕES
    MostrarListaOS(resource) {
      this.infosOS = [];
      this.itensFinalizarApontamento = [];
      this.show_itens_aponta_agrupado = false;
      this.show_btns_aponta_agrupado = false;

      for (let i = 0; i < this.arrayApontaAgrupados.length; i++) {
        if (this.arrayApontaAgrupados[i].codigo == resource.codigo) {
          this.status = this.arrayApontaAgrupados[i].qtde_apont;
        }
      }

      for (let i = 0; i < this.arrayItensApontamentos.length; i++) {
        if (this.arrayItensApontamentos[i].cod_tapont_grp == resource.codigo) {
          this.infosOS.push(this.arrayItensApontamentos[i]);
        }
      }
      
      for (let i = 0; i < this.resourceAgrupaApontamento.length; i++) {
        try {
          let indice = this.resourceAgrupaApontamento[i].codigo;
          document.querySelector(
            "#aponta_agrupados" + indice
          ).style.backgroundColor = "white";
        } catch { }
      }

      document.querySelector(
        "#aponta_agrupados" + resource.codigo
      ).style.backgroundColor = "#a9a9a9";

      this.show_itens_aponta_agrupado = true;
      this.show_btns_aponta_agrupado = true;

      this.cod_maquina = sessionStorage.getItem("cod_maquina");

      this.cod_agrupamento = resource.codigo;
      sessionStorage.setItem("cod_agrupamento", this.cod_agrupamento);

      this.status = resource.qtde_apont;
      this.aponta_agrupado_selecionado = resource.linha_01;
      this.motivo_parada = resource.motivo_parada;

      if (resource.motivo_parada == 0){
        this.mostrarBtns = 0; // Apontamento aberto

      }else{
        this.mostrarBtns = 1; // MP aberto
      }
    },

    OnError(erro) {
      console.error(erro);

      if (erro.response.status == 403){
          if (erro.response.data.mensagem != undefined){
            this.mensagemAviso = erro.response.data.mensagem;
            this.showDialog = true;
          }else{
            this.mensagemAviso = erro.response.data;
            this.showDialog = true;
          }
      }

      this.reloadDivs(true);
      this.disabledBtns(false);
    },

    FilterUpperCase() {
      this.filter = this.filter.toUpperCase();
    },

    disabledBtns(valor) {
      for (let i = 0; i < 5; i++) {
        if (valor) {
          try {
            document.querySelector("#btn" + i).setAttribute("disabled", true);
          } catch { }
        } else {
          try {
            document.querySelector("#btn" + i).removeAttribute("disabled");
          } catch { }
        }
      }
    },

    reloadDivs(value) {
      this.show_lista_aponta_agrupado = value;
      this.show_itens_aponta_agrupado = value;
      this.show_btns_aponta_agrupado = value;
    },

    resetaMPdoComponentePai() {
      this.mostrarBtns = 1;
      this.status = 0;

      this.reloadDivs(true);

      this.$emit("AtualizaStatusApontamento");
    },

    ErroMP(mensagem){
      this.disabledBtns(false);
      this.mensagemAviso = mensagem;
      this.showDialog = true;
    },

    FecharModalFinalizar(){
      this.ModalFinalizar = false;
    },

    async FecharFinalizarParcial(){
      this.FinalizarApontamentoParcial = false;

      await new Promise((resolve) => {
        setTimeout(() => {
          document.querySelector("#aponta_agrupados" + this.cod_agrupamento).style.backgroundColor = "#a9a9a9";
          resolve();
        }, 0);
      });
    }
  },
};
</script>

<style scoped>
h1 {
  font-size: 16px;
  margin-left: 10px;
}

h5 {
  background: #545454;
  color: #fff;
  padding: 5px;
}

.div-btns {
  margin-right: 20px;
}

.v-row {
  margin-top: -16px !important;
}

.btns-aponta-agrupado {
  width: 98%;
  height: 60px;
  margin: 5px;
  font-size: 18px;
  padding: 6px;
  color: #fff;
  border: none;
  border-radius: 5px;
}

@media (max-width: 600px) {
  h5 {
    margin-left: 10px;
  }

  .div_lista_os {
    margin: 0 10px;
  }

  .div-btns {
    margin: 0 20px 0 15px;
  }
}
</style>