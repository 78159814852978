import { RequestPostAbrirApontamento } from "../../../../CPSWeb/front-end/services/ApiRequest";
import { GenerateCrypt } from "../../../../CPSWeb/front-end/library/utils";
import { RequestGETSQL } from "../../../../CPSWeb/front-end/services/ApiRequest";

const ControllerEventsApontamento = {
    ApontamentoSimultaneo: async function (pHost, pToken, pChave, pBody) {
        const JsonSimultaneo = {
            sucess: 0,
            error: 0,
            qteApontamentos : pBody.length,
            messageErro: null
        }

        for (let i = 0; i < pBody.length; i++) {
            try {
                const Body = GenerateCrypt(
                    localStorage.getItem("token"),
                    JSON.stringify(pBody[i]));

                 await RequestPostAbrirApontamento(
                    pHost,
                    pToken,
                    pChave,
                    Body
                )

                JsonSimultaneo.sucess +=1
            }
            catch (error) {
                console.log(error)
                JsonSimultaneo.messageErro = error.response.data.mensagem
                JsonSimultaneo.error +=1
            }
        };

        return JsonSimultaneo
    },

    GetApontamentosAbertos: async function (pHost, pToken, pChave, pCodFuncionario) {
        try {
            const JsonSend = {
                SQL: `select * from taponta_aberto where cod_funcionario = :pCodFuncionario`,
                Params: `[{"name":"pCodFuncionario","type":"integer","value":"${pCodFuncionario}"}]`
            };

            const res = RequestGETSQL(pHost,
                pChave,
                pToken,
                JsonSend
            )

            return res

        } catch (error) {
            return error
        }
    },

    GetExecutarServicosSimultaneamenteStatus: async function (pHost, pToken, pChave, pCodMaquina) {
        try {
            if (localStorage.getItem("tipo_banco") == "SQLServer") {
                var JsonSend = {
                    SQL: `select top 1 executa_varios_servicos from tmaquina where codigo = :pCodMaquina`,
                    Params: `[{"name":"pCodMaquina","type":"integer","value":"${pCodMaquina}"}]`
                }
            } else {
                var JsonSend = {
                    SQL: `select executa_varios_servicos from tmaquina where codigo = :pCodMaquina limit 1`,
                    Params: `[{"name":"pCodMaquina","type":"integer","value":"${pCodMaquina}"}]`
                }
            }
            const res = RequestGETSQL(pHost,
                pChave,
                pToken,
                JsonSend
            );

            return res

        } catch (error) {
            return error
        }
    },
};

export default ControllerEventsApontamento