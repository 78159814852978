<template>
  <div class="colsServicos" style="margin-top: 10px;">
    <alertGRV ref="alert" />

    <div style="height: 100%;" :id="'Card' + numeroCard">
      <div class="conteudoCards">
        <div class="div-conteudo" @click="abrirDetalhamento">
          <h1 id="linha-1" :class="this.class_linha1">
            {{ resource.linha1 }}
          </h1>

          <h2 id="linha-2" :class="this.class_linha2">
            {{ resource.linha2 }}
          </h2>

          <h2 v-if="resource.maquina != undefined" id="linha-maquina">
            Máq.: {{ resource.maquina }}
          </h2>
          <h2 id="linha-3">Tp. Serv.: {{ resource.linha3 }}</h2>

          <div v-if="apontaPorPP" style="display: flex;">
            <h2 id="linha-4">
              Nº Proc. Prod.: {{ resource.linha4 }}
            </h2>
            <h2 id="linha-seq" style="margin-left: 30px">
              Nº da Seq.: {{ resource.seq }}
            </h2>
          </div>

          <h2 id="linha-5">Início : {{ inicioApontamento }}</h2>
        </div>

        <div class="conteudoCards-btns">
          <button v-if="!(resource.motivo_parada > 0)" type="button" :id="'btn-MP' + numeroCard" style="color: white"
            class="btn-mp btnModalCard btn btn-dark" @click="OnClickMotivosDeParada">
            MP
          </button>

          <button v-if="resource.motivo_parada > 0" type="button" :id="'btn-fecharMP' + numeroCard" style="color: #000"
            class="btnModalCard btn btn-warning" @click="OnClickFechaMotivoParada">
            Fechar MP
          </button>

          <button v-if="!ShowGridApontamentoAberto || resource.motivo_parada > 0" type="button"
            :id="'btn-AbrirMP' + numeroCard" class="btnModalCard btn btn-success" @click="OnAbrirApontamentoMotivoParada"
            style="color: #fff">
            Abrir
          </button>

          <button v-if="resource.motivo_parada == 0" type="button" @click="OnClickFechaApontamento"
            :id="'btn-Fecha' + numeroCard" class="btn-fechar btnModalCard btn btn-danger" style="color: #fff">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "../../../../../CPSWeb/front-end/library/utils";

import {
  ResquestGETMotivoDeParada,
  RequestGETTIPOServicos,
  GetChaveConexao,
  RequestListaPDF,
} from "../../../../../CPSWeb/front-end/services/ApiRequest";
import alertGRV from "../../../../../CPSWeb/front-end/Componentes/alert/alert.vue";
import {
  MensagemErroUsuarioNEncontrado,
  MensagemErroTokenNPreechido,
  MensagemTimeOut,
  JSONFechaApontamentoOS,
  JSONFechaApontamentoProcessoProdutivo,
} from "../../../../../CPSWeb/front-end/library/Publics";

import LoadingModal from "../../../../../CPSWeb/front-end/Componentes/Modal/LoadingModal.vue";

import OS from "../OS/os.vue";

import infosCardApontAbertos from "../Modal/CardDeInfos/infosCardApontAbertos.vue";

export default {
  name: "GridCardsApontamentosAbertosVue",

  emits: [
    "UpdateGrid",
    "FuncionarioSelecionado",
    "MaquinaSelecionada",
    "UpdateResourceShowModal",
    "OnHabilitaBtnsCards",
    "OnMotivoDeParada",
    "OnHabilitaDIVPrincipal",
    "OnAbrirApontamento",
    "OnAbrirMotivoDeParada",
    "OnShowDetalhesApontaOS",
    "RecebeShowModalLoadCards",
    "VimDoCardServicos",
    "OnFecharApontamentoAberto",
    "MostrarInfosCardApontAberto",
    "OnAtualizandoMotivosDeParada"
  ],

  props: {
    resource: Object,
    tipoAtualizacao: String,
    class_linha1: String,
    class_linha2: String,
    ShowGridApontamentoAberto: Boolean,
    versaoClient: String,
    numeroCard: String,
    FuncionarioSupervisor: Boolean,
    host: String
  },

  components: { alertGRV, LoadingModal, OS, infosCardApontAbertos },

  data() {
    return {
      estilo_apontamento: localStorage.getItem("estilo_apontamento"),
      DataHora: "",
      apontaPorPP: true,
    };
  },

  computed: {
    inicioApontamento() {
      this.FormatDateHours(this.resource.inicio_apontamento);
      return this.DataHora;
    },
  },

  mounted() {
    this.DicasAtalho();
  },

  methods: {
    DicasAtalho() {
      const containerPai = document.querySelector(
        "#container-grid-apontamento"
      );

      const btn1 = containerPai.children[0].children[1].children[0].children[1].children[0];
      const btn2 = containerPai.children[0].children[1].children[0].children[1].children[1];

      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) == false
      ) {
        if (btn1.innerText === "MP") {
          btn1.innerHTML += " &lt;F2&gt;";
        } else if (btn1.innerText === "Fechar MP") {
          btn1.innerHTML += "&lt;F4&gt;";
        }

        if (btn2.innerText == "Fechar") {
          btn2.innerHTML += "&lt;F4&gt;";
        } else if (btn2.innerText == "Abrir") {
          btn2.innerHTML += "&lt;F9&gt;";
        }
      }
    },

    AnimacaoCard(e) {
      let card;

      if (
        e.target.id == "linha-1" ||
        e.target.id == "linha-2" ||
        e.target.id == "linha-3" ||
        e.target.id == "linha-4" ||
        e.target.id == "linha-5"
      ) {
        card = document.getElementById(
          e.target.parentNode.parentNode.parentNode.id
        );
      } else {
        card = document.getElementById(e.target.parentNode.parentNode.id);
      }

      $(card).css("background", "rgba(0, 0, 0, 0.2)");
      $(card).css("animation", "1s");

      setTimeout(function () {
        $(card).css("animation", "1s");
        $(card).css("background", "#fff");
      }, 200);
    },

    AnimacaoBtns(btn) {
      $(btn).css("animation", "1s");
      $(btn).css("opacity", "0.5");

      setTimeout(function () {
        $(btn).css("animation", "1s");
        $(btn).css("opacity", "1");
      }, 200);
    },

    abrirDetalhamento(e) {
      this.AnimacaoCard(e);

      if (localStorage.getItem("PossuiChaoFabricaSemPapel") == "true") {
        const JsonSend = {
          cod_empresa: this.resource.cod_empresa,
          estilo_apontamento: localStorage.getItem("estilo_apontamento"),
          cod_os: this.resource.cod_os,
          cod_os_aux: this.resource.cod_os_aux,
          PP: this.resource.linha4,
        };

        RequestListaPDF(
          this.host,
          localStorage.getItem("token"),
          sessionStorage.getItem("chave"),
          JsonSend
        )
          .then(this.afterGETListaPDF)
          .catch(this.alertErro);

        this.$emit("VimDoCardServicos", false);
      } else {
        this.$emit("MostrarInfosCardApontAberto", this.resource, this.inicioApontamento);
      }
    },

    afterGETListaPDF(res) {
      this.$emit("OnShowDetalhesApontaOS", this.resource, res.data, true);
      this.$emit("RecebeShowModalLoadCards", this.ShowGridApontamentoAberto);
    },

    alertErro(error) {
      if (
        !(
          error.response.status == 401 &&
          error.response.data.mensagem ==
          "Sem permissão para o uso desse recurso"
        )
      ) {
        let mensagemErro = error.response.status + " - ";

        if (
          error.code == "ERR_BAD_REQUEST" &&
          typeof error.response.data.mensagem != "undefined"
        ) {
          mensagemErro = mensagemErro + error.response.data.mensagem;
        }

        if (error.code == "ERR_BAD_RESPONSE") {
          mensagemErro = mensagemErro + error.response.data;
        }

        if (
          mensagemErro == MensagemErroUsuarioNEncontrado ||
          mensagemErro == MensagemErroTokenNPreechido
        ) {
          mensagemErro = mensagemErro + `\n` + "Atualizando token...";
          this.$refs.alert.alertGRV("warning", mensagemErro, this.Modulo);

          GetChaveConexao(this.host, localStorage.getItem("cnpj"))
            .then(this.Login)
            .catch(this.alertErro);
        } else if (mensagemErro == MensagemTimeOut) {
          GetChaveConexao(this.host, localStorage.getItem("cnpj"))
            .then(this.Login)
            .catch(this.alertErro);
        } else {
          if (typeof error.response.data.mensagem === "undefined") {
            mensagemErro =
              mensagemErro +
              "Erro ao atualizar, verifique os '''dados da configuração.";
            this.$refs.alert.alertGRV("warning", mensagemErro, this.Modulo);

            setTimeout(() => {
              this.$router.push("/config");
            }, "3000");
          }
        }
      }
    },

    FormatDateHours(dateHours) {
      const data = dateFormat(dateHours, "dd/MM/yyyy");
      const hours = dateHours.toLocaleString().substring(11, 19);
      this.DataHora = data + " " + hours;
    },

    OnApontamentoPadrao(pChamada) {
      if (localStorage.getItem("estilo_apontamento") == "OS") {
        if (pChamada == "fechar_apontamento" || pChamada == "fechar_mp") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);

          if(localStorage.getItem("versaoServer") > "3.0.1.13"){
            sessionStorage.setItem("OS", this.resource.cod_os);
          }else{
            sessionStorage.setItem("OS", this.resource.cod_os_completo);
          }

          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }

        if (pChamada == "motivo_parada") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          
          if(localStorage.getItem("versaoServer") > "3.0.1.13"){
            sessionStorage.setItem("OS", this.resource.cod_os);
          }else{
            sessionStorage.setItem("OS", this.resource.cod_os_completo);
          }

          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("acao", 100);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }
      } else {
        if (pChamada == "motivo_parada") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          
          if(localStorage.getItem("versaoServer") > "3.0.1.13"){
            sessionStorage.setItem("OS", this.resource.cod_os);
          }else{
            sessionStorage.setItem("OS", this.resource.cod_os_completo);
          }

          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("acao", 100);
        }

        if (pChamada == "abrir_apontamento") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          
          if(localStorage.getItem("versaoServer") > "3.0.1.13"){
            sessionStorage.setItem("OS", this.resource.cod_os);
          }else{
            sessionStorage.setItem("OS", this.resource.cod_os_completo);
          }

          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("acao", 100);
        }

        if (pChamada == "fechar_apontamento" || pChamada == "fechar_mp") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          
          if(localStorage.getItem("versaoServer") > "3.0.1.13"){
            sessionStorage.setItem("OS", this.resource.cod_os);
          }else{
            sessionStorage.setItem("OS", this.resource.cod_os_completo);
          }
          
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }
      }

      localStorage.setItem("qtdPecas", 0);
    },

    alertErro(error) {

      console.log(error)
      let mensagemErro = error.response.status + " - ";

      if (
        error.code == "ERR_BAD_REQUEST" &&
        typeof error.response.data.mensagem != "undefined"
      ) {
        mensagemErro = mensagemErro + error.response.data.mensagem;
      }

      if (error.code == "ERR_BAD_RESPONSE") {
        mensagemErro = mensagemErro + error.response.data;
      }

      if (
        mensagemErro == MensagemErroUsuarioNEncontrado ||
        mensagemErro == MensagemErroTokenNPreechido
      ) {
        mensagemErro = mensagemErro + `\n` + "Atualizando token...";
        this.$refs.alert.alertGRV("warning", mensagemErro, this.Modulo);

        GetChaveConexao(this.host, localStorage.getItem("cnpj"))
          .then(this.Login)
          .catch(this.alertErro);
      } else if (mensagemErro == MensagemTimeOut) {
        GetChaveConexao(this.host, localStorage.getItem("cnpj"))
          .then(this.Login)
          .catch(this.alertErro);
      } else {
        if (typeof error.response.data.mensagem === "undefined") {
          mensagemErro =
            mensagemErro +
            "Erro ao atualizar, verifique os '''dados da configuração.";
          this.$refs.alert.alertGRV("warning", mensagemErro, this.Modulo);

          setTimeout(() => {
            this.$router.push("/config");
          }, "3000");
        }
      }
    },

    OnAbrirApontamentoMotivoParada(e) {
      this.AnimacaoBtns("#" + e.target.id);

      sessionStorage.setItem(
        "tp_servico_maquina",
        this.resource.cod_tp_servico
      );
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );

      this.OnApontamentoPadrao("fechar_mp");
      this.$emit("OnAbrirMotivoDeParada", this.resource);
    },

    OnClickMotivosDeParada(e) {      
      this.AnimacaoBtns("#" + e.target.id);

      this.$emit("OnHabilitaBtnsCards", true);
      this.$emit("OnHabilitaDIVPrincipal", true);

      sessionStorage.setItem(
        "tp_servico_maquina",
        this.resource.cod_tp_servico
      );
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );

      this.OnApontamentoPadrao("motivo_parada");

      this.$emit("OnAtualizandoMotivosDeParada",true);

      try {
        if (localStorage.getItem("tipoconexao") == "CNPJ") {
          GetChaveConexao(this.host, localStorage.getItem("cnpj"))
            .then(this.GETMotivosDeParada)
            .catch(this.alertErro);
        } else {
          this.GETMotivosDeParadaChave(sessionStorage.getItem("chave"));
        }

      } catch (error) {
        alert(error)
      }
    },

    async OnClickFechaMotivoParada(e) {
      this.AnimacaoBtns("#" + e.target.id);

      sessionStorage.setItem(
        "tp_servico_maquina",
        this.resource.cod_tp_servico
      );

      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );

      sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);

      this.OnApontamentoPadrao("fechar_mp");
      this.$emit("UpdateResourceShowModal", [], "fechar_mp");
    },

    OnClickFechaApontamento(e) {
      this.AnimacaoBtns("#" + e.target.id);

      let JSONFechamento = [];

      if (localStorage.getItem("estilo_apontamento") == "OS") {
        JSONFechamento = JSONFechaApontamentoOS;
      } else {
        JSONFechamento = JSONFechaApontamentoProcessoProdutivo;
      }

      sessionStorage.setItem(
        "tp_servico_maquina",
        this.resource.cod_tp_servico
      );
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );

      document.querySelector(".btn-mp").disabled = true;
      this.OnApontamentoPadrao("fechar_apontamento");
      if (this.FuncionarioSupervisor) {
        this.$emit(
          "UpdateResourceShowModal",
          JSONFechamento,
          "modalfechamento"
        );
      } else {
        this.$emit("OnFecharApontamentoAberto");
      }
    },

    GETTiposDeServico() {
      RequestGETTIPOServicos(this.host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave")
      )
        .then(this.afterGetAbrirApontamento)
        .catch(this.alertErro);
    },

    GETMotivosDeParada(res) {
      ResquestGETMotivoDeParada(this.host, localStorage.getItem("token"), res.data)
        .catch(this.alertErro)
        .then(this.afterGetMotivosDeParada);
    },

    GETMotivosDeParadaChave(chave) {
      ResquestGETMotivoDeParada(this.host, localStorage.getItem("token"), chave)
        .catch(this.alertErro)
        .then(this.afterGetMotivosDeParada);
    },

    afterGetMotivosDeParada(res) {
      this.$emit("OnAtualizandoMotivosDeParada",false);
      this.$emit("OnMotivoDeParada", res.data, "apontamento_normal");
    },

    afterGetAbrirApontamento(res) {
      this.$emit("UpdateResourceShowModal", res.data, "tp_servicos");
    },

    verificaEstiloApontamento() {
      if (localStorage.getItem("estilo_apontamento_client") == "OS") {
        this.apontaPorPP = false;
      } else if (
        localStorage.getItem("estilo_apontamento_client") ==
        "Processo Produtivo"
      ) {
        this.apontaPorPP = true;
      } else {
        if (localStorage.getItem("estilo_apontamento") == "OS") {
          this.apontaPorPP = false;
        } else {
          this.apontaPorPP = true;
        }
      }
    },
  },

  created() {
    this.verificaEstiloApontamento();
  },
};
</script>

<style scoped>
.conteudoCards {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 2px 5px 12px #a0a0a0;
  border: 1px solid #808080;
  transition: 0.5s;
  margin: 2%;
}

.div-conteudo {
  cursor: pointer;
  width: 100%;
  height: auto;
}

#linha-1,
.linha-1 {
  font-size: clamp(0.7rem, 0.3rem + 1vw, 1vw);
  margin: 5px 0 5px 9px;
  overflow: hidden;
  word-wrap: break-word;
  font-weight: 900;
  font-family: "Poppins";
  color: blue;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.div-linha i {
  margin-right: 5px !important;
}

.div-linha .linha-1,
.linha-2 {
  display: -webkit-box;
  overflow: hidden;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

#linha-2,
.linha-2,
#linha-3,
#linha-maquina,
#linha-4,
#linha-seq,
#linha-5 {
  font-size: clamp(0.7rem, 0.3rem + 1vw, 1vw);
  margin: 0 0 5px 9px;
  word-wrap: break-word;
  overflow: hidden;
  font-weight: 900;
  font-family: "Poppins";

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

#linha-5 {
  margin: 0 0 0 9px;
}

.btnModalCard {
  width: 38%;
  margin: 0 5px;
  height: 100%;
  font-size: 12px;
}

.conteudoCards-btns {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-MP,
.btn-abrir {
  width: 38%;
  margin: 0 5px;
  height: 100%;
  font-size: 12px;
  padding: 6px 12px;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.btn-MP {
  background: black;
}

.btn-abrir {
  background: #157347;
}

@media handheld and (orientation: portrait) {

  #linha-1,
  #linha-2,
  #linha-maquina,
  #linha-3,
  #linha-4,
  #linha-seq,
  #linha-5 {
    font-size: clamp(0.6rem, 0.2rem + 1vw, 3vw);
  }
}

@media handheld and (max-width: 480px),
(max-height: 480px) {
  .conteudoCards-btns {
    margin-bottom: 10px;
  }

  #linha-1 {
    margin: 5px 0 0.3rem 9px;
  }

  #linha-2,
  #linha-maquina,
  #linha-3,
  #linha-4,
  #linha-seq {
    margin: 0 0 0.3rem 9px;
  }

  #linha-5 {
    margin: 0 0 0 9px;
  }
}
</style>