// Import que o vue faz a configuração quando inciamos o projeto.
import { createRouter, createWebHistory } from 'vue-router'
// Importando as views que iremos usar nesse projeto 
import Home from '../views/Home.vue'
import configView from '@/views/configView.vue'


/* nesse objeto nós passamos as configurações da rota. 
no primeiro parametro passamos o caminho da nova rota, no segundo o nome 
e no ultimo o componente vue que sera chamado nessa nova rota */

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/config',
    name: 'configView',
    component: configView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
