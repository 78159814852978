<template>
    <v-row justify="space-around" style="width: 100%">
        <v-col cols="auto">
            <v-dialog transition="dialog-bottom-transition" width="100%" persistent v-model="showData">
                <v-card>
                    <v-card-text>
                        <div style="font-family: 'Poppins'; font-size: 22px;">{{ message }}</div>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn class="btnModalOK" style="background-color: #004c89; color: white; width: 20%; font-weight: bold;" variant="text"
                            @click="BotaoOK">ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            showData : false,
        }
    },

    props: {
        show: Boolean,
        message: String,
        acao: String,
    },

    emits: ["showDialog", "acaoModal", "OnEvent", "OnEventOS", "OnHabilitaBtnsCards", "OnHabilitaDIVPrincipal"],

    watch: {
        show (newValue) {
            this.showData = newValue;
        }
    },

    methods: {
        BotaoOK(){
            this.$emit("showDialog", false);
            this.$emit("OnHabilitaBtnsCards", "disabled", false);
            this.$emit("OnHabilitaDIVPrincipal", false);
            this.$emit("OnEvent", "CancelModal");
            this.$emit("OnEventOS", "CancelModal");

            if (this.acao == 'voltar_maquinas'){
                this.$emit("acaoModal", 'voltar_maquinas');
            }
        }
    }
}
</script>

<style>

</style>