<template>
  <div
    class="modal fade"
    id="popupPDF"
    tabindex="-1"
    aria-labelledby="modal-title"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
     
        <div ref="pdfContainerPopup" id="viewerPopup"></div>

        <div class="buttons">
          <button
            type="button"
            class="btns"
            id="rotate-left"
            @click="RotateLeft(this.UltPosicaoRotate - 90)"
          >
            <i class="bi bi-arrow-counterclockwise"></i>
          </button>

          <button
            type="button"
            class="btns"
            id="rotate-right"
            @click="RotateRight(this.UltPosicaoRotate + 90)"
          >
            <i class="bi bi-arrow-clockwise"></i>
          </button>

          <button type="button" class="btns" id="ZoomIn" @click="zoomIn">
            <i class="bi bi-zoom-in"></i>
          </button>

          <button type="button" class="btns" id="ZoomOut" @click="zoomOut">
            <i class="bi bi-zoom-out"></i>
          </button>

          <button
            type="button"
            class="btns"
            id="btnCloseModal"
            @click="OnVoltar()"
          >
            <i class="bi bi-fullscreen-exit" id="closefullscreen1"></i>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { Log } from "../../../../../CPSWeb/front-end/library/utils";

import { createObjectURL } from "blob-util";

function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export default {
  name: "PopupPDF",
  components: {},

  props: {
    url: String,
    nomeArquivo: String,
    previewFilePromisePDF: Object,
    resource: Object,
  },

  data() {
    return {
      adobeApiReady: false,
      previewFilePromise: null,
      UltPosicaoRotate: 0,
    };
  },

  created() {},

  mounted() {},

  updated() {},

  methods: {
    OnVoltar() {
      $("#popupPDF").modal("hide");
    },
  
    RotateRight(pPos) {
      if (pPos == 90) {
        
        this.base64 = this.resource.fileRaCW90;
        this.UltPosicaoRotate = pPos;
      }

      if (pPos == 180) {
        
        this.base64 = this.resource.fileRaCW180;
        this.UltPosicaoRotate = pPos;
      }

      if (pPos == 270) {
        
        this.base64 = this.resource.fileRaCW270;
        this.UltPosicaoRotate = pPos;
      }

      if (pPos == 0) {
        
        this.base64 = this.resource.file0;
        this.UltPosicaoRotate = 0;
      }

      if (pPos == 360) {
        
        this.base64 = this.resource.file0;
        this.UltPosicaoRotate = 0;
      }

      try {
        this.blob = b64toBlob(this.base64);
        let urlShowRotate = createObjectURL(this.blob);
        this.adobeApiReady = true;
        this.renderPdf(urlShowRotate, this.resource.nome);
      } catch (error) {
        alert(error);
      }
    },

    RotateLeft(pPos) {
      if (pPos == 90) {
        
        this.base64 = this.resource.fileRaCW90;
        this.UltPosicaoRotate = pPos;
      }

      if (pPos == 180) {
        
        this.base64 = this.resource.fileRaCW180;
        this.UltPosicaoRotate = pPos;
      }

      if (pPos == -90) {
        
        this.base64 = this.resource.fileRaACW90;
        this.UltPosicaoRotate = pPos;
      }

      if (pPos == -180) {
        
        this.base64 = this.resource.fileRaACW180;
        this.UltPosicaoRotate = pPos;
      }

      if (pPos == -270) {
        
        this.base64 = this.resource.fileRaACW270;
        this.UltPosicaoRotate = pPos;
      }

      if (pPos == 0) {
        
        this.base64 = this.resource.file0;
        this.UltPosicaoRotate = 0;
      }

      if (pPos == -360) {
        
        this.base64 = this.resource.file0;
        this.UltPosicaoRotate = 0;
      }

      try {
        this.blob = b64toBlob(this.base64);
        let urlShowRotate = createObjectURL(this.blob);
        this.adobeApiReady = true;
        this.renderPdf(urlShowRotate, this.resource.nome);
      } catch (error) {
        alert(error);
      }
    },

    currentPage(res) {
      console.log(res);
    },

    OnShowPDF(pURL,pNomeArq) {
      this.adobeApiReady = true;
      this.renderPdf(pURL, pNomeArq);
    },

    renderPdfLocal() {
      if (!this.adobeApiReady) {
        return;
      }
      const previewConfig = {
        defaultViewMode: "FIT_WIDTH",
        showAnnotationTools: false,
      };
      this.$refs.pdfContainerPopup.innerHTML = "";
      let viewerPopup = document.createElement("div");
      viewerPopup.id = "viewerPopup";
      this.$refs.pdfContainerPopup.appendChild(viewerPopup);

      if (process.env.NODE_ENV === "development") {
        Log("Running in development mode");
        var ChaveAcesso = "a2cda9737c464b90a0d06b13060ca0c3"; // Aqui minha chave
      } else {
        Log("Running in production mode");

        if (window.location.href == "https://v3.grv.net.br/"){
          var ChaveAcesso = "9f8c2518acca4690897918dce4621053";
        }else{
          var ChaveAcesso = "84ad86d8ef254c0481a1cb46fa27e527";
        }
      }

      let adobeDCView = new AdobeDC.View({
        clientId: ChaveAcesso,
        divId: "viewerPopup",
      });

      this.previewFilePromise = adobeDCView.previewFile(
        {
          content: {
            location: {
              url: "output.pdf",
            },
          },
          metaData: {
            fileName: "TESTE LOCAL",
            id: "TESTE LOCAL",
          },
        },
        {
          // embedMode: "SIZED_CONTAINER",
          showDownloadPDF: false,
          showPrintPDF: false,
          showAnnotationTools: false,
        },
        previewConfig
      );

      Log("renderPDF PopupPDF");
      Log(this.previewFilePromise);
    },

    renderPdfLink() {
      if (!this.adobeApiReady) {
        return;
      }
      const previewConfig = {
        defaultViewMode: "FIT_WIDTH",
        showAnnotationTools: false,
      };
      this.$refs.pdfContainerPopup.innerHTML = "";
      let viewerPopup = document.createElement("div");
      viewerPopup.id = "viewerPopup";
      this.$refs.pdfContainerPopup.appendChild(viewerPopup);

      if (process.env.NODE_ENV === "development") {
        Log("Running in development mode");
        var ChaveAcesso = "a2cda9737c464b90a0d06b13060ca0c3"; // Aqui minha chave
      } else {
        Log("Running in production mode");
        
        if (window.location.href == "https://v3.grv.net.br/"){
          var ChaveAcesso = "9f8c2518acca4690897918dce4621053";
        }else{
          var ChaveAcesso = "84ad86d8ef254c0481a1cb46fa27e527";
        }
      }

      let adobeDCView = new AdobeDC.View({
        clientId: ChaveAcesso,
        divId: "viewerPopup",
      });

      this.previewFilePromise = adobeDCView.previewFile(
        {
          content: {
            location: {
              url: "https://documentservices.adobe.com/view-sdk-demo/PDFs/Bodea%20Brochure.pdf",
            },
          },
          metaData: {
            fileName: "TESTE LINK",
            id: "TESTE LINK",
          },
        },
        {
          // embedMode: "SIZED_CONTAINER",
          showDownloadPDF: false,
          showPrintPDF: false,
          showAnnotationTools: false,
        },
        previewConfig
      );

      Log("renderPDF PopupPDF");
      Log(this.previewFilePromise);
    },

    renderPdf(url, fileName) {
      Log("Caiu aqui no renderPdf");

      if (!this.adobeApiReady) {
        return;
      }
      const previewConfig = {
        defaultViewMode: "FIT_WIDTH",
        showAnnotationTools: false,
      };
      this.$refs.pdfContainerPopup.innerHTML = "";
      let viewerPopup = document.createElement("div");
      viewerPopup.id = "viewerPopup";
      this.$refs.pdfContainerPopup.appendChild(viewerPopup);

      if (process.env.NODE_ENV === "development") {
        Log("Running in development mode");
        var ChaveAcesso = "a2cda9737c464b90a0d06b13060ca0c3"; // Aqui minha chave
      } else {
        Log("Running in production mode");
        
        if (window.location.href == "https://v3.grv.net.br/"){
          var ChaveAcesso = "9f8c2518acca4690897918dce4621053";
        }else{
          var ChaveAcesso = "84ad86d8ef254c0481a1cb46fa27e527";
        }
      }

      let adobeDCView = new AdobeDC.View({
        clientId: ChaveAcesso,
        divId: "viewerPopup",
      });

      this.previewFilePromise = adobeDCView.previewFile(
        {
          content: {
            location: {
              url: url,
            },
          },
          metaData: {
            fileName: fileName,
            id: fileName,
          },
        },
        {
          // embedMode: "SIZED_CONTAINER",
          showDownloadPDF: false,
          showPrintPDF: false,
          showAnnotationTools: false,
        },
        previewConfig
      );

      Log("renderPDF PopupPDF");
      Log(this.previewFilePromise);
    },

    alertErro(ERRO) {
      alert(ERRO);
    },

    nextPage() {
      this.previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAPIs().then((apis) => {
          apis
            .getCurrentPage()
            .then((currentPage) => apis.gotoLocation(currentPage + 1))
            .catch((error) => console.error(error));
        });
      });
    },

    previousPage() {
      this.previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAPIs().then((apis) => {
          apis
            .getCurrentPage()
            .then((currentPage) => {
              if (currentPage > 1) {
                return apis.gotoLocation(currentPage - 1);
              }
            })
            .catch((error) => console.error(error));
        });
      });
    },

    zoomIn() {
      // this.$emit("MaisZoom");

      Log(this.previewFilePromise);

      this.previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAPIs().then((apis) => {
          apis
            .getZoomAPIs()
            .zoomIn()
            .catch((error) => console.error(error));
        });
      });

      Log(this.previewFilePromise);
    },

    zoomOut() {
      // this.$emit("MenosZoom");

      Log(this.previewFilePromise);

      this.previewFilePromise.then((adobeViewer) => {
        adobeViewer.getAPIs().then((apis) => {
          apis
            .getZoomAPIs()
            .zoomOut()
            .catch((error) => console.error(error));
        });
      });

      Log(this.previewFilePromise);
    },
  },
};
</script>

<style scoped>
#viewerPopup {
  width: 100%;
  height: 100%;
}

.btns {
  width: 50px;
  height: 50px;
  background: #0d6efd;
  color: #fff;
  border-radius: 50%;
  border: none;
  margin: 0 0 20px 20px;
}

.buttons{
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (max-width: 480px), (max-height: 480px) {
  .btns {
    width: 40px;
    height: 40px;
  }
}
</style>