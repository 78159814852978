import {Hosts} from "../library/hosts.js"

export const BASE_URL = Hosts.host;

export function FormatDateOC(date) {
   
    return date.toLocaleDateString('pt-br')
}

export function FormatDate(dateHours) {
    const [year, month, day] = dateHours.split("-");

    const result = [day,month,year].join("/");

    return result; 
}

export function DecodeCrypt(pPass) { 
    
    return Decode_base64(pPass.substr(32))
}

export function GenerateCrypt(pToken,PMensagem){
    return Md5(pToken) + Encode_base64(PMensagem)
}

export function Md5(value){
    let md5 = require('md5');
 
    return md5(value);  
}

export function Encode_base64(value){
    const base64 = require('base-64');
    const utf8 = require('utf8');
    let text = value;
    let bytes = utf8.encode(text);
    return base64.encode(bytes);
}


export function Decode_base64(value){
    const base64 = require('base-64');
    const utf8 = require('utf8');
    let bytes = base64.decode(value);
    return utf8.decode(bytes);
}

export function dateFormat(inputDate, format) {
    //parse the input date
    const date = new Date(inputDate);

    //extract the parts of the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();    

    //replace the month
    format = format.replace("MM", month.toString().padStart(2,"0"));        

    //replace the year
    if (format.indexOf("yyyy") > -1) {
        format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
        format = format.replace("yy", year.toString().substr(2,2));
    }

    //replace the day
    format = format.replace("dd", day.toString().padStart(2,"0"));

    return format;
}

export function getConfigServidorRest() {
    let endereco = Hosts.host

    if (endereco.indexOf(":9") >= 0) {
        endereco = endereco.substr(0, endereco.indexOf(":9"))
    }

    endereco = endereco.replace('/config', '');

    endereco = endereco.replace('/teste', '');
    
    endereco = endereco.replace('/os','');

    endereco = endereco.replace('br/', 'br');

    endereco = endereco.replace('1/', '1');

    endereco = endereco.replace('t/', 't');

    console.log(endereco)

    if ((localStorage.getItem('caminhoRest') != null) && (localStorage.getItem('caminhoRest') != '')) {
        endereco = window.localStorage.getItem('caminhoRest')
    }

    let porta = '9080';

    if (window.localStorage.getItem('portaRest') != '') {
        porta = window.localStorage.getItem('portaRest')
    }

    return endereco + ':' + porta;
}

export function getServidor() {
    let endereco = Hosts.host

    if (endereco.indexOf(":9") >= 0) {
        endereco = endereco.substr(0, endereco.indexOf(":9"))
    }

    endereco = endereco.replace('/config', '')

    if (window.localStorage.getItem('caminhoRest') !== null) {
        if (window.localStorage.getItem('caminhoRest') !== '') {
            endereco = window.localStorage.getItem('caminhoRest')

        }
    }

    return endereco
}

export function getSetores() {
    if (!window.localStorage.getItem('setores')) {
        return null
    }
    else {
        return window.localStorage.getItem('setores')
    }
}

/*
Função que valida se o usuário já preencheu ou não o Tipo de conexao nas configurações, 
caso a condição for false ela retorna a condição de null e sendo true o Tipo de conexao preenchida.
*/
export function getTipoConexaoServidorRest() {
    if (!window.localStorage.getItem('tipoconexao')) {
        return null
    }
    else {
        return window.localStorage.getItem('tipoconexao')
    }
}

/*
Função que valida se o usuário já preencheu ou não o Usuario nas configurações, 
caso a condição for false ela retorna a condição de null e sendo true o Usuario preenchida.
*/
export function getSenhaServidorRest() {
    if (!window.localStorage.getItem('5f4dcc3b5aa765d61d8327deb882cf99')) {
        return null
    }
    else {
        return window.localStorage.getItem('5f4dcc3b5aa765d61d8327deb882cf99')
    }
}

/*
Função que valida se o usuário já preencheu ou não o Usuario nas configurações, 
caso a condição for false ela retorna a condição de null e sendo true o Usuario preenchida.
*/
export function getUsuarioServidorRest() {
    if (!window.localStorage.getItem('usuario')) {
        return null
    }
    else {
        return window.localStorage.getItem('usuario')
    }
}

/*
Função que valida se o usuário já preencheu ou não a POrta nas configurações, 
caso a condição for false ela retorna a condição de null e sendo true a POrta preenchida.
*/
export function getPortaServidorRest() {
    if (!window.localStorage.getItem('portaRest')) {
        return null
    }
    else {
        return window.localStorage.getItem('portaRest')
    }
}

export function Abort() {
    const controller = new AbortController();
    return controller.abort()
}


/*
Função que valida se o usuário já preencheu ou não a Chave nas configurações, 
caso a condição for false ela retorna a condição de null e sendo true a Chave preenchida.
*/
export function getChaveServidorRest() {
    if (!window.localStorage.getItem('chave_mensageria')) {
        return null
    }
    else {
        return window.localStorage.getItem('chave_mensageria')
    }
}

/*
Função que valida se o usuário já preencheu ou não o CNPJ nas configurações, 
caso a condição for false ela retorna a condição de null e sendo true o CNPJ preenchido.
*/
export function getCNPJServidorRest() {
    if (!window.localStorage.getItem('cnpj')) {
        return null
    }
    else {
        return window.localStorage.getItem('cnpj')
    }
}

export function getTempoSemExecucao() {
    if (!window.localStorage.getItem('tempoSemExecucao')) {
        return 3
    }
    else {
        return (window.localStorage.getItem('tempoSemExecucao') / 60000)
    }
}


export function limpaDocumentos(pDocumento) {
    pDocumento = pDocumento.replace('.', '');
    pDocumento = pDocumento.replace(',', '');
    pDocumento = pDocumento.replace('-', '');
    pDocumento = pDocumento.replace('/', '');
    return pDocumento;
}

export function keyPressed(evt) {
    var key = evt.key;
    return key;
}

export function Log( PMensagem) {
    if (false) {
        console.log(PMensagem)
    }
}

