<template>
    <vue-basic-alert class='vue-alert' :duration="1000" :closeIn="4000" ref="alert" />
</template>

<script>
import VueBasicAlert from "vue-basic-alert";


export default {
  name: "alertGRV",
  components: { VueBasicAlert },
  props: {
    mensagemAlert: String,
    tituloAlert: String,
    tipoAlert: String,
  },
  methods: {
    alertGRV(pTipoErro,pMensagem,pTitulo) {
      this.$refs.alert.showAlert(
        "success", 
        pMensagem,
        'Sucesso', 
        pTitulo
      );
    },
  },
}
</script>

<style>

</style>

