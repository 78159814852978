module.exports = {
    HABILITALOGS: false,
    AMBIENTETESTE: false,
    MensagemErroUsuarioNEncontrado: '400 - Usuário não encontrado',
    MensagemErroTokenNPreechido: '400 - Erro: TOKEN nao preenchido.',
    MensagemTimeOut: '504 - timeout',
    JSONFechaApontamentoOS: [{
        "codigo": 1,
        "linha1": "1- Finalizar o serviço."
    },
    {
        "codigo": 2,
        "linha1": "2- Fechar o apontamento sem finalizar o serviço."
    }
    ],
    JSONFechaApontamentoProcessoProdutivo: [{
        "codigo": 1,
        "linha1": "1 - Finalizar o processo."
    },
    {
        "codigo": 2,
        "linha1": "2- Fechar o apontamento sem finalizar o processo."
    }]
}
