<template>
    <div>
        <div class="container-fluid fixed-top"></div>
        <!-- Usando a classe de container do bootstrap fixado a top simulando a ideia do header -->

        <config-vue class="config"/> <!-- Usando o componente de configVue, nesse componente encontra os campos do formulario do config-->
        <div class="container-fluid fixed-bottom"></div>
        <!-- Usando a classe de container do bootstrap fixado a top simulando a ideia do footer -->
    </div>
</template>

<script>

// Importando o componente de configVue que vamos usar na nossa pagina.
import configVue from '@/components/Config/config.vue';

export default {
    name: "ConfigView",
    // Declaração dos componentes que serão usados, se eles não forem declarados não conseguimos usar somente importando.
    components: {
        configVue
    }
}
</script>

<style scoped>

.container-fluid {
    height: 10px;
    background-color: #004c89;
}

</style>