import { createApp } from 'vue'
// Importando o componente App 
import App from './App.vue'
// Importa a configuração de rotas 
import router from './router'

// Importando o bootstrap para trabalharmos com componentes(web) no nosso html 
import './registerServiceWorker'

import { createPinia } from 'pinia';

const pinia = createPinia()

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
// you have to import bootstrap css.
import "bootstrap/dist/css/bootstrap.min.css";

// import bootstrap icons (this is not required, you can use your own icon class)
import "bootstrap-icons/font/bootstrap-icons.css";


// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import store from './store'

const vuetify = createVuetify({
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
          mdi,
        },
      },
  components,
  directives,
})


// Nos parametros de uses estamos passando as rot.as para o vue gerencias para nós a paginação, e o VueMask para podermos usar no projeto.
const app = createApp(App).use(store).use(pinia).provide().use(vuetify).use(router).mount('#app')