<template>
  <div id="app">
    <div
      v-if="div_btn_agrupados && estilo_apontamento == 'PROCESSO_PRODUTIVO'"
      style="
        border: 1px solid #a9a9a9;
        height: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      "
    >
      <button
        type="button"
        @click="this.$emit('AbriModalApontamentoAgrupado')"
        class="btn-agrupamento"
        style="background: #4a148c"
      >
        Apontamento Agrupado
      </button>
    </div>
    <div
      v-if="div_btn_simultaneo && !aptSimultaneoPronto"
      style="
        border: 1px solid #a9a9a9;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <v-btn
        @click="HabilitarAptSimultaneo"
        style="
          width: 98%;
          height: 90%;
          background: #4a148c;
          color: white;
          font-weight: bold;
          font-size: 12px;
        "
      >
        Apontamento Simultâneo
      </v-btn>
    </div>
    <div
      v-if="aptSimultaneoPronto"
      style="
        border: 1px solid #a9a9a9;
        height: 20%;
        display: flex;
        justify-content: space-around;
        align-items: center;
      "
    >
      <v-btn
        @click="AbrirAptSimultaneo"
        style="
          width: 46%;
          height: 90%;
          background: green;
          color: white;
          font-weight: bold;
          font-size: 12px;
        "
      >
        <div>
          <p id="btn-text-style">
            Abrir<br />
            Apontamentos<br />
            Selecionados<br />
          </p>
        </div>
      </v-btn>
      <v-btn
        @click="CancelarAptSimultaneo"
        style="
          width: 46%;
          height: 90%;
          background: red;
          color: white;
          font-weight: bold;
        "
      >
        Cancelar
      </v-btn>
    </div>
    <div class="calculator">
      <div class="rows">
        <div @click="append('1')" id="n1" class="btns">1</div>
        <div @click="append('2')" id="n2" class="btns">2</div>
        <div @click="append('3')" id="n3" class="btns">3</div>
      </div>
      <div class="rows">
        <div @click="append('4')" id="n4" class="btns">4</div>
        <div @click="append('5')" id="n5" class="btns">5</div>
        <div @click="append('6')" id="n6" class="btns">6</div>
      </div>
      <div class="rows">
        <div @click="append('7')" id="n7" class="btns">7</div>
        <div @click="append('8')" id="n8" class="btns">8</div>
        <div @click="append('9')" id="n9" class="btns">9</div>
      </div>
      <div class="rows">
        <div @click="append('/')" id="plus" class="btn-operator">/</div>
        <div @click="append('0')" id="n0" class="btns">0</div>
        <div @click="append('C')" id="equal" class="btn-operator">
          <i class="bi bi-backspace"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "calculatorVue",
  data() {
    return {
      logList: "",
      current: "",
      answer: "",
      operatorClicked: true,
      estilo_apontamento: localStorage.getItem("estilo_apontamento"),
      aptSimultaneoPronto: false,
    };
  },

  methods: {
    AbrirAptSimultaneo() {
      this.$emit("AbrirApontSimultaneo", true);
    },

    CancelarAptSimultaneo() {
      this.aptSimultaneoPronto = false;
      this.$emit("HabilitarAptSimultaneo", false);
    },

    HabilitarAptSimultaneo() {
      this.aptSimultaneoPronto = true;
      this.$emit("HabilitarAptSimultaneo", true);
    },

    append(value) {
      this.$emit("SendValueCalculator", value);
      this.$emit("VerificaCaixaFiltro", false);
    },
  },

  emits: [
    "AbriModalApontamentoAgrupado",
    "HabilitarAptSimultaneo",
    "AbrirApontSimultaneo",
  ],

  props: {
    div_btn_agrupados: Boolean,
    div_btn_simultaneo: Boolean,
  },

  watch: {
    div_btn_agrupados(newvalue) {
      if (newvalue && this.estilo_apontamento == "PROCESSO_PRODUTIVO") {
        document.querySelector(".calculator").style.height = "80%";
      } else {
        document.querySelector(".calculator").style.height = "100%";
      }
    },

    div_btn_simultaneo() {
      if (this.div_btn_simultaneo == true) {
        document.querySelector(".calculator").style.height = "80%";
      } else {
        document.querySelector(".calculator").style.height = "100%";
      }
    },
  },

  mounted() {
    if (
      (this.div_btn_agrupados &&
        this.estilo_apontamento == "PROCESSO_PRODUTIVO") ||
      this.div_btn_simultaneo == true
    ) {
      document.querySelector(".calculator").style.height = "80%";
    } else {
      document.querySelector(".calculator").style.height = "100%";
    }
  },
};
</script>

<style scoped>
#btn-text-style {
  font-size: 10px;
  margin: 0;
}

.btn-agrupamento {
  width: 98%;
  margin: 0 5px;
  height: 90%;
  font-size: 20px;
  padding: 6px 12px;
  color: #fff;
  border: none;
  border-radius: 5px;
}

#app {
  position: fixed;
  top: 8%;
  right: 0;
  width: 30%;
  height: 84%;
}

.calculator {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-shadow: -5px 0px 40px -13px rgba(13, 81, 134, 1);
}

.rows {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btns {
  background-color: #004c89;
  color: #fff;
  font-size: 4vw;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  height: 70%;
  width: 25%;
  margin: 2%;
  transition: 0.5s;
}

.btn-operator {
  background-color: #666;
  color: #fff;
  font-size: 4vw;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  height: 70%;
  width: 25%;
  margin: 2%;
  transition: 0.5s;
}

.btns:hover {
  background: #0d6efd;
}

.btn-operator:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (orientation: portrait) {
  #app {
    top: 64%;
    width: 100%;
    height: 30%;
  }
}

@media (orientation: portrait) and (max-width: 480px) {
  #app {
    height: 32%;
    top: 58%;
  }
}
</style>