<template>
    <div class="loading-modal" id="loading-modal">
        <div class="loading-modal-content">
            <h1 :style="sizeTitle">
                <template v-if="title">{{ title }}</template>
            </h1>
            <p v-if="message">{{ message }} {{ tempoDecorrido }}</p>
        </div>
    </div>
</template>

<script>
export default {
    created() {

    },
    mounted() {
        // Atualiza os segundos a cada segundo
        setInterval(this.incrementarSegundos, 1000);
    },
    name: 'LoadingModal',
    props: {
        title: String,
        message: String,
        sizeTitle: Object
    },
    computed: {
    tempoDecorrido() {
      return ( 20 - this.SegLoads) ;
    },
  },
    methods: {
        incrementarSegundos() {
            this.SegLoads++;
        }
    },
    data() {
        return {
            SegLoads: 0
        }
    }
}
</script>

<style scoped>
.loading-modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(161, 161, 161, 0.7);
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
}

.loading-modal-content {
    background-color: white;
    padding: 1vw 3vw;
    border-radius: 1vw;
}
</style>