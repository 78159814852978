<template>
    <div
        class="modal fade"
        id="load-modal"
        tabindex="-1"
        aria-labelledby="modal-title"
        aria-hidden="true"
      >
       
      <div class="modal-dialog">
          <div class="modal-content">
            <div class="div-mensagem">
              <h1>
                {{Mensagem}}
              </h1>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: "ModalLoadVue",
  props: {Mensagem : String,acao:String},
  emits: ["OnEvent",
          "OnHabilitaBtnsCards",
          "OnHabilitaDIVPrincipal",
          "acaoModal"],

  data(){
    return{
        
    }
  },
}
</script>

<style scoped>
.modal-dialog {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modal-content {
  height: 40%;
}

.div-mensagem{
  height: 75%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

h1{
  font-size: 18px;
  font-family: "Poppins";
  text-align: center;
}

.div-button{
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ccc;
}

.btnModalOK {
  width: 20%;
  background: #004c89;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 2.5% 0;
  transition: 0.5s;
}

.btnModalOK:hover {
  background: #0d6efd;
}

@media (max-width: 480px) {
  .btns {
    font-size: 3vw;
  }
}

@media (orientation: landscape) and (max-height: 480px){
  .modal-content {
    height: 100%;
  }

  .modal-dialog {
    height: 65vh;
  }
}
</style>