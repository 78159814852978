import { createStore } from 'vuex'

export default createStore({
  state: {
    funcionario_apont_grupo: 0,
  },
  mutations: {
    AlteraFuncionarioApontGrupo(state, data){
      state.funcionario_apont_grupo = data;
    }
  },
  getters: {
  },
  actions: {
  },
})
