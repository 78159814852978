<template>
  <div class="cols">
    <alertGRV ref="alert" />
    <div class="cards" @click="clickCards" :id="'Card' + numeroCard">
      <div class="status">
        <div class="af-card p-2" id="af-card">
          <div class="div-status" v-bind:style="{
      backgroundColor: resource.colorHeaderCard,
      height: 90 + '%',
      marginBottom: -1 + 'px',
      borderRadius: 5 + 'px',
    }">
            <span class="div-status-span" v-bind:style="{
      color: 'white',
      fontSize: 18 + 'px',
      borderRadius: 5 + 'px',
    }">
              <h1 class="div-status-h1" id="linha-1">{{ resource.linha1 }}</h1>
            </span>
          </div>
        </div>
      </div>
      <div class="conteudoCards">
        <div class="div-conteudo" style="height: 100%;">
          <!-- Foto do recurso -->
          <div v-if="checkimage" class="img flex-shrink-0" id="img"
            :style="{ backgroundImage: 'url(' + adjustedimg + ')' }"></div>
        </div>

        <h2 id="linha-3">{{ resource.linha3 }}</h2>
        <p id="linha-2">{{ resource.linha2 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Log } from "../../../../../CPSWeb/front-end/library/utils";
import alertGRV from "../../../../../CPSWeb/front-end/Componentes/alert/alert.vue";
import LoadingModal from "../../../../../CPSWeb/front-end/Componentes/Modal/LoadingModal.vue";
import { useAppConnectionWs } from "@/store/appConnectionWs";

export default {
  name: "GridCardsFuncionarioVue",
  emits: [
    "FuncionarioSelecionado"
  ],
  props: {
    resource: Object,
    numeroCard: String,
    tipoAtualizacao: String,
    ShowGridApontamentoAberto: Boolean,
  },
  components: { alertGRV, LoadingModal },

  computed: {
    adjustedimg() {
      if (this.resource.url_img != undefined) {
        return this.resource.url_img;
      }
      else {
        return `data:image;base64,${this.resource.img.replace(/\n/g, "")}`;
      }
    },

    checkimage() {
      if (this.resource.url_img == "null") {
        return 0;
      } else {
        return 1;
      }
    },
  },

  methods: {
    AnimacaoCard(e) {
      var card;

      if (e.target.className == "status" || e.target.className == "conteudoCards") {
        card = document.getElementById(e.target.parentNode.id);
      }
      else if (e.target.id == "af-card" || e.target.className == "div-conteudo" ||
        e.target.id == "linha-2" || e.target.id == "linha-3") {
        card = document.getElementById(e.target.parentNode.parentNode.id);
      }
      else if (e.target.className == "div-status" || e.target.id == "img") {
        card = document.getElementById(e.target.parentNode.parentNode.parentNode.id);
      } else if (e.target.className == "div-status-span") {
        card = document.getElementById(e.target.parentNode.parentNode.parentNode.parentNode.id);
      } else if (e.target.className == "div-status-h1") {
        card = document.getElementById(e.target.parentNode.parentNode.parentNode.parentNode.parentNode.id);
      }

      $(card).css("background", "rgba(0, 0, 0, 0.2)");
      $(card).css("animation", "1s");

      setTimeout(function () {
        $(card).css("animation", "1s");
        $(card).css("background", "#fff");
      }, 400);

    },

    clickCards(e) {
      if (useAppConnectionWs().existe_trava) {
        useAppConnectionWs().msgWs.showModalMsgWs = true;
      }
      else {
        this.AnimacaoCard(e);

        this.$store.commit("AlteraFuncionarioApontGrupo", this.resource.funcionario_apont_grupo);

        sessionStorage.setItem("cod_funcionario", this.resource.codigo);
        sessionStorage.setItem("supervisor", this.resource.supervisor);

        if (this.resource.url_img != undefined) {
          this.$emit("FuncionarioSelecionado", this.resource.linha1, this.resource.url_img, this.resource.supervisor);
        }
        else {
          this.$emit("FuncionarioSelecionado", this.resource.linha1, this.resource.img, this.resource.supervisor);
        }
      }
    },
  },
};
</script>

<style scoped>
.cards {
  height: 90%;
  color: black;
  background: white;
  margin: 2.5% 3%;
  border-radius: 5px;
  box-shadow: 2px 5px 12px #a0a0a0;
  border: 1px solid #808080;
  transition: 0.5s;
  cursor: pointer;
}

.cards:hover {
  box-shadow: 4px 7px 10px #808080, -1px -1px 5px #808080;
  border: 1.75px solid #808080;
}

.img {
  margin-left: 9px;
  border-radius: 15%;
  height: 40px;
  width: 40px;
  border: 1px solid #ccc;
  padding: 5px 0;
  background-size: cover;
  background-position: center center;
}

#linha-1 {
  font-size: clamp(0.4rem, 0.8rem, 1rem);
  padding: 5px 0 5px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#linha-1:hover {
  text-overflow: clip;
  white-space: normal;
}

.conteudoCards {
  width: 100%;
  height: 100%;
}
</style>