import axios from 'axios'

// http method
const METHOD = {
  GET: 'get',
  POST: 'post',
  PATCH:'patch',
  PUT:'put',
  DELETE:'delete'
}

/**
  * pedido de axios
  * @param endereço de solicitação de url
  * @param método {METHOD} método http
  * @param params parâmetros de solicitação
  * @returns {Promise<AxiosResponse<T>>}
  */
 
async function request(url, method, params,headers,config) {
  let promise =null;
  switch (method) {
    case METHOD.GET:
      promise = axios.get(url, {params,headers,...config});
      break;
    case METHOD.POST:
      promise = axios.post(url, params, config);
      break;
    case METHOD.PUT:
      promise = axios.put(url, params, headers,config);
      break;
    case METHOD.PATCH:
      promise = axios.patch(url,params,config);
      break;
    case METHOD.DELETE:
      promise = axios.delete(url,config);
      break;
    default:
      promise = axios.get(url, {params, ...config});
      break;
  }

  return promise;
}

/**
 * Analisar parâmetros no URL
 * @param url
 * @returns {Object}
 */
function parseUrlParams(url) {
  const params = {}
  if (!url || url === '' || typeof url !== 'string') {
    return params
  }
  const paramsStr = url.split('?')[1]
  if (!paramsStr) {
    return params
  }
  const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
  for (let i = 0; i < paramsArr.length / 2; i++) {
    const value = paramsArr[i * 2 + 1]
    params[paramsArr[i * 2]] = value === 'true' ? true : (value === 'false' ? false : value)
  }
  return params
}

export {
  METHOD,
  request,
  parseUrlParams
}
