<template>
  <div class="div-principal" :style="styleLiberaFuncionamento">
    <div class="modals">
      <DialogErro :show="ShowDialogErro" :message="MensagemErroModal" :acao="acaoModal"
      @OnHabilitaBtnsCards="OnHabilitaBtnsCards" @OnHabilitaDIVPrincipal="OnHabilitaDIVPrincipal"  
      @OnEventOS="OnEventModal" @showDialog="ShowDialog"/>

      <ModalLoadVue :Mensagem="MensagemLoadModal" />

      <PopupPDF ref="PopUPPDF" :url="url" :nomeArquivo="nomeArquivo" :resource="resourcePDF" />

      <div class="alertGRV"><alertGRV ref="alert" /></div>
    </div>

    <div v-if="!ShowModalLoadCards" class="ajusta-conteudo">
      <div class="infos">
        <div class="texto-infos">
          <p v-if="resource.linha1 != null" id="linha-1" class="outras_linhas">
            {{ resource.linha1 }}
          </p>

          <p v-if="resource.linha2 != null" id="linha-2" class="outras_linhas">
            {{ resource.linha2 }}
          </p>

          <p v-if="resource.maquina != undefined && !vimDo_cardServico" id="linha-maquina" class="outras_linhas">
            Máq.: {{ resource.maquina }}
          </p>

          <p v-if="vimDo_cardServico && apontaPorPP" id="linha-3" class="outras_linhas">
            Tp. Serv.: {{ resource.tiposervico }}
          </p>
          <p v-if="!vimDo_cardServico && apontaPorPP" id="linha-3" class="outras_linhas">Tp. Serv.: {{ resource.linha3 }}</p>

          <p v-if="vimDo_cardServico" class="outras_linhas">{{ dataEntrega }}</p>

          <div v-if="apontaPorPP" style="display: flex;">
            <p id="linha-4" class="outras_linhas" style="margin-bottom: 0;">
              Nº Proc. Prod.: {{ resource.linha4 }}
            </p>
            <p id="linha-seq" class="outras_linhas" style="margin: 0 0 0 30px;">
              Nº da Seq.: {{ resource.seq }}
            </p>
          </div>

          <p
            v-if="resource.cliente != null || resource.cliente != ''"
            id="linha-5" class="outras_linhas"
          >
            {{ resource.cliente }}
          </p>

          <p v-if="!vimDo_cardServico" class="outras_linhas">Início : {{ inicioApontamento }}</p>
        </div>
        <div class="btns-apontamento">
          <button
            v-if="!(resource.motivo_parada > 0)"
            @click="OnMetodoMP"
            id="btn-MP"
            class="btn-mp"
          >
            MP &lt;F2&gt;
          </button>

          <button
            v-if="resource.motivo_parada > 0"
            type="button"
            id="btn-fecharMP"
            class="btnModalCard btn btn-warning"
            @click="OnClickFechaMotivoParada"
            style="font-size: 16px;"
          >
            Fechar MP &lt;F4&gt;
          </button>

          <button
            v-if="!ShowGridApontamentoAberto || resource.motivo_parada > 0"
            @click="OnClickAbrirApontamento"
            id="btn-abrir"
            class="btn-abrir"
          >
            Abrir &lt;F9&gt;
          </button>

          <button
            v-if="resource.motivo_parada == 0"
            type="button"
            @click="OnClickFechaApontamento"
            id="btn-fechar"
            class="btnModalCard btn btn-danger"
            style="color: #fff; font-size: 16px;"
          >
            Fechar &lt;F4&gt;
          </button>
        </div>
      </div>

      <ul
        v-if="!ShowModalLoadCards"
        class="nav nav-tabs"
        id="myTab"
        role="tablist"
      >
        <li class="nav-item" role="presentation" style="padding: 0">
          <button
            class="nav-link active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home-tab-pane"
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected="true"
          >
            PDF
          </button>
        </li>
      </ul>

      <div v-if="!ShowModalLoadCards" class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="home-tab-pane"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabindex="0"
        >
          <div id="conteudo">
            <div v-if="semPDF" class="pdfs">
              <div
                v-if="!ShowModalLoadCards"
                id="myTab"
                class="row row-cols-1 row-cols-sm-1 row-cols-lg-1"
              >
                <div id="files" class="cols">
                  <div
                    @click="OnClickPDF(file.id)"
                    class="nome-pdf"
                    v-for="file in resourceFiles"
                    :key="file.id"
                    :id="file.id"
                  >
                    <p>
                      <b>Nome: </b> {{ file.nome_arquivo }}
                      <br />
                      <b v-if="file.descricao != null">Descrição: </b
                      >{{ file.descricao }}
                      <br v-if="file.descricao != null" />
                      <b>Tipo: </b>{{ file.descricao_tabela }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <h1 v-if="semPDF == false">Nenhum PDF encontrado</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertGRV from "../../../../../CPSWeb/front-end/Componentes/alert/alert.vue";

import DialogErro from "../Modal/DialogErro.vue";

import ModalLoadVue from "../Modal/ModalLoad.vue";

import PopupPDF from "../Modal/PopupPDF.vue";

import {
  RequestGETPDF,
  ResquestGETMotivoDeParada,
  RequestPostAbrirApontamento,
  RequestGETTIPOServicos,
} from "../../../../../CPSWeb/front-end/services/ApiRequest";

import {
  getCNPJServidorRest,
  Log,
  BASE_URL,
  dateFormat,
  FormatDate
} from "../../../../../CPSWeb/front-end/library/utils";

import {
  HABILITALOGS,
  JSONFechaApontamentoOS,
  JSONFechaApontamentoProcessoProdutivo,
} from "../../../../../CPSWeb/front-end/library/Publics";

import { GenerateCrypt } from "../../../../../CPSWeb/front-end/library/utils";

import pdf from "../pdf/pdf.vue";

import LoadingModal from "../../../../../CPSWeb/front-end/Componentes/Modal/LoadingModal.vue";

import { createObjectURL } from "blob-util";

function b64toBlob(b64Data, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export default {
  name: "OS",

  components: {
    ModalLoadVue,
    DialogErro,
    alertGRV,
    PopupPDF,
    pdf,
    LoadingModal,

  },

  props: {
    resource: Object,
    resourceFiles: Object,
    ShowGridApontamentoAberto: Boolean,
    vimDo_cardServico: Boolean,
    host : String
  },

  emits: [
    "OnAbrirApontamento",
    "OnHabilitaBtnsCards",
    "OnHabilitaDIVPrincipal",
    "OnMotivoDeParada",
    "OnReset",
    "desabilitaHeaderFooter",
    "UpdateResourceShowModal",
    "OnAbrirMotivoDeParada",
  ],

  data() {
    return {
      MensagemErroModal: "",
      MensagemLoadModal: "",
      ShowDialogErro: false,
      ShowModal: false,
      ShowModal: false,
      blob: "",
      base64: "",
      nomeArquivo: "",
      resourcePDFSelecionado: [],
      resourcePDF : [],
      styleLiberaFuncionamento: {
        pointerEvents: "visible",
      },
      semPDF: true,
      apontaPorPP: true,
      DataHora: "",

      // Modal
      acaoModal: "",
      dataModal: "",
      titleModal: "",
      resourceModal: [],
      IniOS: true,
      ShowModalLoadCards: false,
      ShowFooter: false,
    };
  },

  computed: {
    versaoClient() {
      return process.env.VUE_APP_VERSION;
    },

    dataEntrega() {
      let dataEntrega = new Date(this.resource.dt_prevista_os);

      if (
        localStorage.getItem("ordenacao_apontamento") == "DTENTREGAPRIORIDADE"
      ) {
        dataEntrega = new Date(this.resource.dt_prevista_os);
        dataEntrega.setHours(dataEntrega.getHours() + 3);

        if (dataEntrega > new Date(1989, 12, 29)) {
          return `Previsão de entrega: ${FormatDate(
            this.resource.dt_prevista_os.toLocaleString().substring(0, 10)
          )}`;
        } else {
          return "Sem data prevista para entrega.";
        }
      } else {
        dataEntrega = new Date(this.resource.dt_incio_previsto);
        dataEntrega.setHours(dataEntrega.getHours() + 3);

        if (dataEntrega > new Date(1989, 12, 29)) {
          const dataHora = new Date(dataEntrega);
          const dia = dataHora.getDate().toString().padStart(2, "0");
          const mes = (dataHora.getMonth() + 1).toString().padStart(2, "0");
          const ano = dataHora.getFullYear();
          const hora = dataHora.getHours().toString().padStart(2, "0");
          const minuto = dataHora.getMinutes().toString().padStart(2, "0");
          const segundo = dataHora.getSeconds().toString().padStart(2, "0");

          const dataHoraFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;

          return `Previsão de início: ` + dataHoraFormatada;
        } else {
          return "Sem data prevista de início.";
        }
      }
    },

    inicioApontamento() {
      this.FormatDateHours(this.resource.inicio_apontamento);
      return this.DataHora;
    },
  },

  updated() {
    // this.AlturaTabContent();
  },

  mounted() {
    this.AlturaTabContent();
  },

  created() {
    $("#grv-modal").modal("hide");
    $("#popupPDF").modal("hide");
    this.ShowFooter = true;
    this.IniOS = true;
    this.verificaEstiloApontamento();
    this.VerificaModoTela();

    try {
      const JsonDadosOS = this.resourceFiles[0];

      const IndicesPrimario = JsonDadosOS.IndicePrimario.split(";");

      const JsonParams = {
        tabela: JsonDadosOS.tabela,
        indice_primario: JsonDadosOS.IndicePrimario,
        nome_arquivo: JsonDadosOS.nome_arquivo,
        cod_empresa: JsonDadosOS.cod_empresa,
        cod_os: JsonDadosOS.cod_os,
        cod_os_aux: JsonDadosOS.cod_os_aux,
        pp: JsonDadosOS.cod_processo,
        codigo: parseInt(IndicesPrimario[IndicesPrimario.length - 1]),
      };

      this.resourcePDFSelecionado = JsonParams;

      RequestGETPDF(
        this.host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave"),
        JsonParams
      ).then(this.afterGETPDF);
    } catch {
      this.semPDF = false;
      this.OnHabilitaDIVPrincipal(false);
    }
  },

  methods: {
    ShowDialog(value){
      this.ShowDialogErro = value;
    },

    OnHabilitaBtnsCards(disabled, value) {
      $(".btnModalCard").attr(disabled, value);
      $(".btn-inicio").attr(disabled, value);
      $(".btn-voltar").attr(disabled, value);
    },

    OnClickPDF(id) {
      const JsonDadosOS = this.resourceFiles[id - 1];

      if (JsonDadosOS != undefined) {
        this.OnHabilitaDIVPrincipal(true);
        this.MensagemLoadModal = `CARREGANDO ${JsonDadosOS.nome_arquivo}`;
        this.acaoModal = "load";
        $("#load-modal").modal("show");

        const IndicesPrimario = JsonDadosOS.IndicePrimario.split(";");

        const JsonParams = {
          tabela: JsonDadosOS.tabela,
          indice_primario: JsonDadosOS.IndicePrimario,
          nome_arquivo: JsonDadosOS.nome_arquivo,
          cod_empresa: JsonDadosOS.cod_empresa,
          cod_os: JsonDadosOS.cod_os,
          cod_os_aux: JsonDadosOS.cod_os_aux,
          pp: JsonDadosOS.cod_processo,
          codigo: parseInt(IndicesPrimario[IndicesPrimario.length - 1]),
        };

        this.resourcePDFSelecionado = JsonParams;

        RequestGETPDF(
          this.host,
          localStorage.getItem("token"),
          sessionStorage.getItem("chave"),
          JsonParams
        )
          .then(this.afterGETPDFClick)
          .catch(this.alertErro);
      }
    },

    afterGETListaPDF(res) {
      this.ShowModalLoadCards = false;
      this.resourceFiles = res.data;
      const JsonDadosOS = this.resourceFiles[0];

      const IndicesPrimario = JsonDadosOS.IndicePrimario.split(";");

      const JsonParams = {
        tabela: JsonDadosOS.tabela,
        indice_primario: JsonDadosOS.IndicePrimario,
        nome_arquivo: JsonDadosOS.nome_arquivo,
        cod_empresa: JsonDadosOS.cod_empresa,
        cod_os: JsonDadosOS.cod_os,
        cod_os_aux: JsonDadosOS.cod_os_aux,
        pp: JsonDadosOS.cod_processo,
        codigo: parseInt(IndicesPrimario[IndicesPrimario.length - 1]),
      };

      this.resourcePDFSelecionado = JsonParams;

      RequestGETPDF(
        this.host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave"),
        JsonParams
      )
        .then(this.afterGETPDF)
        .catch(this.alertErro);
    },

    // Requisição referente a base64 do PDF selecionado
    RequestPDF(posicaoLinha) {
      const JsonDadosOS = this.resourceFiles[posicaoLinha - 1];

      if (JsonDadosOS != undefined) {
        this.OnHabilitaDIVPrincipal(true);
        this.MensagemLoadModal = `CARREGANDO ${JsonDadosOS.nome_arquivo}`;
        this.acaoModal = "load";
        $("#load-modal").modal("show");

        const IndicesPrimario = JsonDadosOS.IndicePrimario.split(";");

        const JsonParams = {
          tabela: JsonDadosOS.tabela,
          indice_primario: JsonDadosOS.IndicePrimario,
          nome_arquivo: JsonDadosOS.nome_arquivo,
          cod_empresa: JsonDadosOS.cod_empresa,
          cod_os: JsonDadosOS.cod_os,
          cod_os_aux: JsonDadosOS.cod_os_aux,
          pp: JsonDadosOS.cod_processo,
          codigo: parseInt(IndicesPrimario[IndicesPrimario.length - 1]),
        };

        this.resourcePDFSelecionado = JsonParams;
        
        RequestGETPDF(
          this.host,
          localStorage.getItem("token"),
          sessionStorage.getItem("chave"),
          JsonParams
        )
          .then(this.afterGETPDF)
          .catch(this.alertErro);
      }
    },

    async afterGETPDFClick(res) {
      if (res.data.length == 0) {
        this.semPDF = false;
      } else {
        $("#load-modal").modal("hide");
        this.OnHabilitaBtnsCards("disabled", false);
        this.OnHabilitaDIVPrincipal(false);

        try {
          this.base64 = res.data[0].file0;
          this.resourcePDF = res.data[0];
          this.blob = b64toBlob(this.base64);
          this.nomeArquivo = res.data[0].nome;
          this.url = createObjectURL(this.blob);
          this.semPDF = true;
          this.IniOS = false; 
        } catch (error) {
          alert(error)
        }

        $("#popupPDF").modal("show");

        this.$refs.PopUPPDF.OnShowPDF(this.url,this.nomeArquivo);
        this.semPDF = true;
        this.IniOS = false;
        
      }
    },

    async afterGETPDF(res) {
      if (res.data.length == 0) {
        this.semPDF = false;
      } else {
        $("#load-modal").modal("hide");
        this.OnHabilitaBtnsCards("disabled", false);
        this.OnHabilitaDIVPrincipal(false);

        try {
          this.base64 = res.data[0].file0;
          this.resourcePDF = res.data[0];
          this.blob = b64toBlob(this.base64);
          this.nomeArquivo = res.data[0].nome;
          this.url = createObjectURL(this.blob);
          this.semPDF = true;
          this.IniOS = false;
        } catch (error) {
          alert(error)
        }
      }
    },

    OnEventModal(event) {
      if (event == "CancelModal") {
        this.OnHabilitaBtnsCards("disabled", false);
        this.OnHabilitaDIVPrincipal(false);

        this.$emit("OnEvent", "CancelModal");
      }
    },

    OnHabilitaDIVPrincipal(value) {
      if (value) {
        this.styleLiberaFuncionamento.pointerEvents = "none";
      } else {
        this.styleLiberaFuncionamento.pointerEvents = "visible";
      }
    },

    OnInicio() {
      sessionStorage.setItem("OnServicos", false);
      this.ShowFooter = false;
      this.$router.push("/");
      this.ResetaSessions();
    },

    AnimacaoBtns(btn) {
      $(btn).css("animation", "1s");
      $(btn).css("opacity", "0.5");

      setTimeout(function () {
        $(btn).css("animation", "1s");
        $(btn).css("opacity", "1");
      }, 200);
    },

    OnMetodoMP(e) {
      this.AnimacaoBtns("#" + e.target.id);

      sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
      localStorage.setItem("cod_os", this.resource.cod_os);
      sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);

      if (this.resource.cod_tp_servico != undefined) {
        sessionStorage.setItem(
          "tp_servico_maquina",
          this.resource.cod_tp_servico
        );
      }

      if (this.resource.cod_tp_servico_apontamento != undefined) {
        sessionStorage.setItem(
          "tp_servicos_selecionado",
          this.resource.cod_tp_servico_apontamento
        );
      }

      if (
        this.resource.cod_maquina != undefined &&
        this.resource.cod_maquina > 0
      ) {
        sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
      }

      this.OnApontamentoPadrao("motivo_parada");
      this.GETMotivosDeParada();
    },

    OnClickAbrirApontamento(e) {
      this.AnimacaoBtns("#" + e.target.id);

      this.ShowModal = true;

      localStorage.setItem("cod_os", this.resource.cod_os);
      sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
      sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);

      if (this.resource.cod_tp_servico != undefined) {
        sessionStorage.setItem(
          "tp_servico_maquina",
          this.resource.cod_tp_servico
        );
      }

      if (this.resource.cod_tp_servico_apontamento != undefined) {
        sessionStorage.setItem(
          "tp_servicos_selecionado",
          this.resource.cod_tp_servico_apontamento
        );
      }

      if (
        this.resource.cod_maquina != undefined &&
        this.resource.cod_maquina > 0
      ) {
        sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
      }

      if (this.vimDo_cardServico == true) {
        this.OnApontamentoPadrao("abrir_apontamento");

        if (localStorage.getItem("informar_tipo_servico") == "true") {
          this.GETTiposDeServico();
        } else {
          this.dataModal = "tp_servicos";
          this.acaoModal = "";
          this.OnIniciarApontamento();
        }
      } else if (this.vimDo_cardServico == false) {
        if (this.resource.cod_tp_servico_apontamento != undefined) {
          sessionStorage.setItem(
            "tp_servicos_selecionado",
            this.resource.cod_tp_servico
          );
        }

        this.OnApontamentoPadrao("fechar_mp");
        this.$emit("OnAbrirMotivoDeParada", this.resource);
      }
    },

    OnApontamentoPadrao(pChamada) {
      if (localStorage.getItem("estilo_apontamento") == "OS") {
        localStorage.setItem("qtdPecas", 0);

        if (pChamada == "fechar_apontamento" || pChamada == "fechar_mp") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("OS", this.resource.linha3);

          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);

          if (
            this.resource.cod_maquina != undefined &&
            this.resource.cod_maquina > 0
          ) {
            sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          }

          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }

        if (pChamada == "motivo_parada") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          if (
            this.resource.cod_maquina != undefined &&
            this.resource.cod_maquina > 0
          ) {
            sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          }
          localStorage.setItem("acao", 100);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }
      } else {
        if (pChamada == "motivo_parada") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          localStorage.setItem("acao", 100);
        }

        if (pChamada == "abrir_apontamento") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          localStorage.setItem("acao", 101);
        }

        if (pChamada == "fechar_apontamento" || pChamada == "fechar_mp") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }
      }

      localStorage.setItem("qtdPecas", 0);
    },

    // Referente ao botão de abrir apontamento
    GETTiposDeServico() {
      RequestGETTIPOServicos(
        this.host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave")
      )
        .then(this.afterGetTpServicos)
        .catch(this.alertErro);
    },

    afterGetTpServicos(res) {
      this.OnShowModalTPServicos(res.data);
    },

    OnShowModalTPServicos(res) {
      this.dataModal = "tp_servicos";
      this.titleModal = "Tipo de Serviço";
      this.typeModal = "input-list";
      this.resourceModal = res;

      $("#grv-modal").modal("show");
    },

    afterAbrirApontamento() {
      this.$refs.alert.alertGRV(
        "info",
        "Apontamento aberto com sucesso!!",
        this.Modulo
      );
      this.OnHabilitaBtnsCards("disabled", false);
      this.OnHabilitaDIVPrincipal(false);
      this.$emit("OnReset", "Abrir_Apontamento");
    },

    alertErro(error) {
      console.log(error)

      this.OnHabilitaBtnsCards("disabled", false);
      this.OnHabilitaDIVPrincipal(false);

      let mensagemErro = error.response.status + " - ";

      if (error.response.status == 403) {
        localStorage.setItem("MensagemErro", error.response.data.mensagem);
        this.MensagemErroModal = error.response.data.mensagem;
        this.ShowDialogErro = true;
      }

      if (error.response.status == 400) {
        localStorage.setItem("MensagemErro", error.response.data.mensagem);
        
        if (error.response.data.mensagem == undefined) {
          this.MensagemErroModal = error.response.data;
        }
        else {
          this.MensagemErroModal = error.response.data.mensagem;
        }

        this.ShowDialogErro = true;
      }

      if (
        error.code == "ERR_BAD_REQUEST" &&
        typeof error.response.data.mensagem != "undefined"
      ) {
        mensagemErro = mensagemErro + error.response.data.mensagem;
      }

      if (error.code == "ERR_BAD_RESPONSE") {
        mensagemErro = mensagemErro + error.response.data;
      }
    },

    // Referente ao botão de motivo de parada
    GETMotivosDeParada() {
      ResquestGETMotivoDeParada(
        this.host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave")
      )
      .then(this.afterGetMotivosDeParada)
      .catch(this.alertErro)
    },

    afterGetMotivosDeParada(res) {
      this.$emit("UpdateResourceShowModal", res.data, "MP_da_os");
    },

    OnShowModal() {
      this.ShowModal = true;
      $("#grv-modal").modal("show");
    },

    async OnMotivoDeParada(res) {
      this.dataModal = "motivo_parada";
      this.titleModal = "Motivo de Parada";
      this.typeModal = "input-list";
      this.resourceModal = res.data;
      this.ShowModal = true;
    },

    OnSelectModal(pDataModal) {
      const click_event = new CustomEvent("click");
      const btn_element = document.querySelector("#btnCloseModal");
      btn_element.dispatchEvent(click_event);

      if (pDataModal == "tp_servicos") {
        this.OnIniciarApontamento();
      }

      if (pDataModal == "motivo_parada") {
        this.OnIniciarApontamento();
      }

      if (pDataModal == "fechamento_apontamento") {
        this.OnFecharApontamento();
      }
    },

    OnIniciarApontamento() {
      this.ShowFooter = false;
      this.OnHabilitaDIVPrincipal(true);

      if (localStorage.getItem("estilo_apontamento") == "PROCESSO_PRODUTIVO") {
        var JSONApontamento = {
          versao: this.versaoClient,
          acao: parseInt(localStorage.getItem("acao")),
          cod_empresa: this.resource.cod_empresa,
          OS: sessionStorage.getItem("OS"),
          COD_OS_COMPLETO: sessionStorage.getItem("COD_OS_COMPLETO"),
          apontamento_normal: true,
          PP: localStorage.getItem("PROCESSO_PRODUTIVO"),
          CodFuncionario: parseInt(sessionStorage.getItem("cod_funcionario")),
          CodMaquina: parseInt(sessionStorage.getItem("cod_maquina")),
          CodTpServico: parseInt(
            sessionStorage.getItem("tp_servicos_selecionado")
          ),
          CodTpServicoMaquina: sessionStorage.getItem("tp_servico_maquina"),
          QtdePecas: parseFloat(localStorage.getItem("qtdPecas")),
        };
      } else {
        var JSONApontamento = {
          versao: this.versaoClient,
          acao: 100,
          cod_empresa: this.resource.cod_empresa,
          OS: parseInt(localStorage.getItem("cod_os")),
          COD_OS_COMPLETO: sessionStorage.getItem("COD_OS_COMPLETO"),
          apontamento_normal: true,
          OS_AUX: parseInt(sessionStorage.getItem("cod_os_aux")),
          CodFuncionario: parseInt(sessionStorage.getItem("cod_funcionario")),
          CodMaquina: parseInt(sessionStorage.getItem("cod_maquina")),
          CodTpServico: parseInt(
            sessionStorage.getItem("tp_servicos_selecionado")
          ),
          CodTpServicoMaquina: sessionStorage.getItem("tp_servico_maquina"),
          QtdePecas: parseInt(localStorage.getItem("qtdPecas")),
        };
      }

      const body = GenerateCrypt(
        localStorage.getItem("token"),
        JSON.stringify(JSONApontamento)
      );

      if (this.dataModal == "tp_servicos") {
        RequestPostAbrirApontamento(
          this.host,
          localStorage.getItem("token"),
          sessionStorage.getItem("chave"),
          body
        )
          .then(this.afterAbrirApontamento)
          .catch(this.alertErro);
      }

      if (this.dataModal == "motivo_parada") {
        RequestPostAbrirApontamento(
          this.host,
          localStorage.getItem("token"),
          sessionStorage.getItem("chave"),
          body
        )
          .then(this.afterAbrirMotivoParada)
          .catch(this.alertErro);
      }
    },

    afterAbrirMotivoParada() {
      this.$refs.alert.alertGRV(
        "info",
        "Motivo de parada aberto com sucesso!!",
        this.Modulo
      );
      this.OnHabilitaBtnsCards("disabled", false);
      this.OnHabilitaDIVPrincipal(false);
      this.$emit("OnReset", "Motivo_Parada");
    },

    // Referente ao fechar apontamento
    OnClickFechaApontamento(e) {
      this.AnimacaoBtns("#" + e.target.id);

      let JSONFechamento = [];

      if (localStorage.getItem("estilo_apontamento") == "OS") {
        JSONFechamento = JSONFechaApontamentoOS;
      } else {
        JSONFechamento = JSONFechaApontamentoProcessoProdutivo;
      }

      sessionStorage.setItem(
        "tp_servico_maquina",
        this.resource.cod_tp_servico
      );
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );
      sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
      localStorage.setItem("cod_os", this.resource.cod_os);
      sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);

      this.OnApontamentoPadrao("fechar_apontamento");
      this.$emit("UpdateResourceShowModal", JSONFechamento, "modalfechamento");
    },

    // Referente ao fechar MP
    async OnClickFechaMotivoParada(e) {
      this.AnimacaoBtns("#" + e.target.id);

      sessionStorage.setItem(
        "tp_servico_maquina",
        this.resource.cod_tp_servico
      );
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );
      sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
      localStorage.setItem("cod_os", this.resource.cod_os);
      sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
      this.OnApontamentoPadrao("fechar_mp");
      this.$emit("UpdateResourceShowModal", [], "fechar_mp");
    },

    Closefullscreen(){
      $(".header").css("display", "flex");
      $(".ajusta-conteudo").css("display", "block");
      $(".footer").css("display", "flex");
      this.AlturaTabContent();
    },

    VerificaModoTela() {
      let portrait = window.matchMedia("(orientation: portrait)");
      portrait.addEventListener("change", this.OnListenEventPortrait);
    },

    OnListenEventPortrait(event) {
      // Portrait
      if (event.matches) {
        this.AlturaTabContent();
      }
      // Landscape
      else {
        this.AlturaTabContent();
      }
    },

    AlturaTabContent() {
      let ajusta_conteudo =
        document.querySelector(".ajusta-conteudo").offsetHeight;
      let infos = document.querySelector(".infos").offsetHeight;
      let nav = document.querySelector(".nav").offsetHeight;
      
      if (window.screen.height > 480) {
        let result = ajusta_conteudo - (infos + nav + 30);
        let porcentagem = (result / ajusta_conteudo) * 100;

        document.querySelector(".tab-content").style.height = porcentagem + "%";
      } else {
        document.querySelector(".tab-content").style.height = "300px";
      }
    },

    verificaEstiloApontamento() {
      if (localStorage.getItem("estilo_apontamento_client") == "OS") {
        this.apontaPorPP = false;
      } else if (
        localStorage.getItem("estilo_apontamento_client") ==
        "Processo Produtivo"
      ) {
        this.apontaPorPP = true;
      } else {
        if (localStorage.getItem("estilo_apontamento") == "OS") {
          this.apontaPorPP = false;
        } else {
          this.apontaPorPP = true;
        }
      }
    },

    FormatDateHours(dateHours) {
      const data = dateFormat(dateHours, "dd/MM/yyyy");
      const hours = dateHours.toLocaleString().substring(11, 19);
      this.DataHora = data + " " + hours;
    },
  },
};
</script>

<style scoped>
.tab-content {
  height: 300px;
  display: flex;
}
.ajusta-conteudo {
  position: absolute;
  top: 8vh;
  width: 100%;
  height: 84%;
  overflow: hidden;
}

.infos {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 98%;
  height: auto;
  margin: 1%;
}

.texto-infos {
  width: 70%;
}

#linha-1 {
  font-size: clamp(0.8rem, 0.4rem + 1vw, 1.1vw);
  margin: 5px 9px 0 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 900;
  font-family: "Poppins";
  color: blue;
}

.outras_linhas {
  font-size: clamp(0.7rem, 0.3rem + 1vw, 1vw);
  margin: 0 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 900;
  font-family: "Poppins";
}

.btnModalCard {
  width: 38%;
  margin: 0 2%;
  height: 100%;
  font-size: 12px;
}

#conteudo {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
}

#conteudo h1 {
  margin: 0 auto;
  height: 100%;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* REFERENTE A LISTA DE PDF's */
.pdfs {
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #fff;
  width: 98%;
  height: 95%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1%;
}

.leitor-pdf {
  height: 100vh;
  width: 100vw;
  background: grey;
  position: absolute;
}

#files {
  width: 100%;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

#files p {
  margin-left: 10px;
}

.nome-pdf {
  width: 100vw;
  font-family: "Poppins";
  padding: 0 3%;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.nome-pdf p {
  margin-bottom: 1%;
}

.nome-pdf:hover {
  background: lightgray;
}

.btns-apontamento {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30%;
}

.btns-apontamento button {
  width: 40%;
  height: 50%;
  border: none;
  margin-right: 2%;
  border-radius: 5px;
}

.btn-mp {
  color: #fff;
  background-color: #000;
}

.btn-abrir {
  color: #fff;
  background-color: #198754;
}

/* RESPONSIVIDADE */
@media (orientation: portrait) {
  .ajusta-conteudo {
    top: 6vh;
    height: 88%;
  }
}

@media (orientation: portrait) and (max-width: 768px) and (min-width: 481px) {
  .ajusta-conteudo {
    overflow-y: hidden;
  }
}

@media (orientation: portrait) and (max-width: 480px) {
  .ajusta-conteudo {
    top: 10vh;
    height: 80vh;
    overflow-y: scroll;
  }
}

@media (orientation: landscape) and (max-height: 480px) {
  .ajusta-conteudo {
    top: 8vh;
    overflow-y: scroll;
  }
}
</style>