<template>
  <router-view />
</template>

<script>

export default {
  provide: {
    APP_NAME: 'APONTAMENTOWEB'
  }
}
</script>

<style>
.v-overlay__content {
  background: #363636 !important;
}

.v-dialog--fullscreen > .v-overlay__content {
  background: #fff !important;
}

.v-dialog--fullscreen > .v-overlay__content > .v-card {
  overflow: hidden !important;
}

.vue-alert * {
    font-family: Arial;
    height: 150px;
    color: white;
}


.alert-message {
  color : black;
  font-size : 18px
}

.vue-alert > .alert-container .alert-content > p.alert-message {
    font-size: 25px !important;
    min-width: fit-content !important;
    margin-bottom: 50px !important;
    font-weight: bold !important;
    line-height: 1.3 !important;
    color: black !important;
}


</style>