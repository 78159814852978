module.exports = {
  
    LOGIN: `/login`,
    GETSQL: `/getsql`,
    CHAVE:`/chave`,
    FUNCIONARIOPRODUCAO : `/funcionarioproducao`,
    FUNCIONARIOPRODUCAOSETOR : `/funcionarioproducaosetor`,
    MAQUINAS : `/maquina`,
    MOTIVOPARADA : `/motivoparada`,
    PROCESSOPRODUTIVO : `/processoprodutivo`,
    OSPRODUCAO : `/osproducao`,
    TIPODESERVICO : `/tipodeservico`,
    APONTAMENTOPP : `/apontamento/pp`,
    APONTAMENTOOS: `/apontamento/os`,
    
    APONTAMENTOAGRUPADOABRIRPP : `/apontamento/agrupado/abrirpp`,
    APONTAMENTOAGRUPADOMP: `/apontamento/agrupado/mp`,
    APONTAMENTOAGRUPADOFECHAR : `/apontamento/agrupado/fechar`,
    APONTAMENTOAGRUPADOFINALIZAR : `/apontamento/agrupado/finalizar`,
    APONTAMENTOAGRUPADOFINALIZARPARCIAL : `/apontamento/agrupado/finalizarParcial`,

    APONTAMENTOFUNC : `/apontamento/funcionario`,
    APONTAMENTOFECHAR : `/apontamento/fechar`,
    LISTAPDF : `/file`,
    GETPDF : `/pdf`,
    APROVAROC : `/oc/assinar`,
    GETPDFFAKE :  `/pdfrotate`,
    TEMPERMISSAO :  `/temPermissao` 
  }
  