<template>
  <v-app>
    <alertGRV ref="alert" />

    <v-main>
      <v-dialog v-model="dialogData" persistent class="v-dialog">

        <v-toolbar color="#004c89" style="color: white" title="Detalhes"></v-toolbar>
        <v-card class="div-infos">
          <h1 id="linha-1">
            {{ resource.linha1 }}
          </h1>

          <h2 class="outras-linhas">
            {{ resource.linha2 }}
          </h2>

          <h2 v-if="resource.maquina != undefined" id="linha-maquina" class="outras-linhas">
            Máq.: {{ resource.maquina }}
          </h2>

          <h2 id="linha-3" class="outras-linhas">Tp. Serv.: {{ resource.linha3 }}</h2>

          <div v-if="apontaPorPP" style="display: flex">
            <h2 class="outras-linhas">
              Nº Proc. Prod.: {{ resource.linha4 }}
            </h2>
  
            <h2 id="linha-seq" class="outras-linhas" style="margin-left: 30px">
              Nº da Seq.: {{ resource.seq}}
            </h2>
          </div>          

          <h2 id="linha-5" class="outras-linhas">Início : {{ inicioApontamento }}</h2>
        </v-card>
        <div class="modal-footer" style="background-color: #004c89">
          
          <div style="width: 100%; display: flex; justify-content: space-between;">
            <button class="btns-modal" id="btn-cancelar" @click="$emit('FecharModal')" style="margin-left: 5px; background: #0B5ED7; color: #fff; width: 30% !important;">Cancelar</button>
            <div style="width: 60%; display: flex; justify-content: space-between; margin-right: 5px">
              <button v-if="!(resource.motivo_parada > 0)" @click="OnClickMotivosDeParada" class="btns-modal" id="btn-mp-infosCard"
                style="color: #fff; background: black">MP</button>
              <button v-if="resource.motivo_parada > 0" @click="OnClickFechaMotivoParada" class="btns-modal" id="btn-fecharmp-infosCard"
                style="color: #000; background: #FFCA2C; margin-right: 5px; width: 130px">Fechar MP</button>
              <button v-if="!ShowGridApontamentoAberto || resource.motivo_parada > 0" @click="OnAbrirApontamentoMotivoParada"
                class="btns-modal" id="btn-abrir-infosCard" style="color: #fff; background: #157347; width: 130px">Abrir</button>
              <button v-if="resource.motivo_parada == 0" @click="OnClickFechaApontamento" class="btns-modal" id="btn-fechar-infosCard"
                style="color: #FFF; background: #BB2D3B">Fechar</button>
            </div>
          </div>
        </div>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { GetChaveConexao, ResquestGETMotivoDeParada } from "../../../../../../CPSWeb/front-end/services/ApiRequest";

import alertGRV from "../../../../../../CPSWeb/front-end/Componentes/alert/alert.vue";

import {
  MensagemErroUsuarioNEncontrado,
  MensagemErroTokenNPreechido,
  MensagemTimeOut,
  JSONFechaApontamentoOS,
  JSONFechaApontamentoProcessoProdutivo,
} from "../../../../../../CPSWeb/front-end/library/Publics";

export default {
  name: "infosCard",

  components: {
    alertGRV
  },

  props: {
    dialog: Boolean,
    resource: Object,
    inicioApontamento: String,
    ShowGridApontamentoAberto: Boolean,
    host: String,
    FuncionarioSupervisor: Boolean,
  },

  emits: ["FecharModal",
    "UpdateGrid",
    "FuncionarioSelecionado",
    "MaquinaSelecionada",
    "UpdateResourceShowModal",
    "OnHabilitaBtnsCards",
    "OnMotivoDeParada",
    "OnHabilitaDIVPrincipal",
    "OnAbrirApontamento",
    "OnAbrirMotivoDeParada",
    "OnShowDetalhesApontaOS",
    "RecebeShowModalLoadCards",
    "VimDoCardServicos",
    "OnFecharApontamentoAberto",
    "MostrarInfosCardApontAberto"],

  data() {
    return {
      apontaPorPP: localStorage.getItem("estilo_apontamento_client"),
      estilo_apontamento: localStorage.getItem("estilo_apontamento"),
      dialogData : false
    }
  },

  created() {
    this.verificaEstiloApontamento();
  },

  mounted(){
    this.DicasAtalho();
  },

  methods: {
    // MP
    OnClickMotivosDeParada(e) {
      this.disabledBtn(true);
      this.AnimacaoBtns("#" + e.target.id);

      this.$emit("OnHabilitaBtnsCards", true);
      this.$emit("OnHabilitaDIVPrincipal", true);

      sessionStorage.setItem(
        "tp_servico_maquina",
        this.resource.cod_tp_servico
      );
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );

      this.OnApontamentoPadrao("motivo_parada");

      if (localStorage.getItem("tipoconexao") == "CNPJ") {
        GetChaveConexao(this.host, localStorage.getItem("cnpj"))
          .then(this.GETMotivosDeParada)
          .catch(this.alertErro);
      } else {
        this.GETMotivosDeParadaChave(sessionStorage.getItem("chave"));
      }
    },

    GETMotivosDeParada(res) {
      ResquestGETMotivoDeParada(this.host, localStorage.getItem("token"), res.data)
        .catch(this.alertErro)
        .then(this.afterGetMotivosDeParada);
    },

    afterGetMotivosDeParada(res) {
      this.$emit("OnMotivoDeParada", res.data, "apontamento_normal");
    },

    GETMotivosDeParadaChave(chave) {
      ResquestGETMotivoDeParada(this.host, localStorage.getItem("token"), chave)
        .catch(this.alertErro)
        .then(this.afterGetMotivosDeParada);
    },

    // Fechar MP
    async OnClickFechaMotivoParada(e) {
      this.disabledBtn(true);
      this.AnimacaoBtns("#" + e.target.id);

      sessionStorage.setItem(
        "tp_servico_maquina",
        this.resource.cod_tp_servico
      );

      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );

      sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);

      this.OnApontamentoPadrao("fechar_mp");
      this.$emit("UpdateResourceShowModal", [], "fechar_mp");
    },

    // Abrir MP
    OnAbrirApontamentoMotivoParada(e) {
      this.disabledBtn(true);
      this.AnimacaoBtns("#" + e.target.id);

      sessionStorage.setItem(
        "tp_servico_maquina",
        this.resource.cod_tp_servico
      );
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );

      this.OnApontamentoPadrao("fechar_mp");
      this.$emit("OnAbrirMotivoDeParada", this.resource);

    },

    // Fechar
    OnClickFechaApontamento(e) {
      this.disabledBtn(true);
      this.AnimacaoBtns("#" + e.target.id);

      let JSONFechamento = [];

      if (localStorage.getItem("estilo_apontamento") == "OS") {
        JSONFechamento = JSONFechaApontamentoOS;
      } else {
        JSONFechamento = JSONFechaApontamentoProcessoProdutivo;
      }

      sessionStorage.setItem(
        "tp_servico_maquina",
        this.resource.cod_tp_servico
      );
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );

      this.OnApontamentoPadrao("fechar_apontamento");
      if (this.FuncionarioSupervisor) {
        this.$emit(
          "UpdateResourceShowModal",
          JSONFechamento,
          "modalfechamento"
        );
      } else {
        this.$emit("OnFecharApontamentoAberto");
      }
    },

    // Outros métodos
    OnApontamentoPadrao(pChamada) {
      if (localStorage.getItem("estilo_apontamento") == "OS") {
        if (pChamada == "fechar_apontamento" || pChamada == "fechar_mp") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }

        if (pChamada == "motivo_parada") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("acao", 100);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }
      } else {
        if (pChamada == "motivo_parada") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("acao", 100);
        }

        if (pChamada == "abrir_apontamento") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("acao", 100);
        }

        if (pChamada == "fechar_apontamento" || pChamada == "fechar_mp") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }
      }

      localStorage.setItem("qtdPecas", 0);
    },

    alertErro(error) {
      let mensagemErro = error.response.status + " - ";

      if (
        error.code == "ERR_BAD_REQUEST" &&
        typeof error.response.data.mensagem != "undefined"
      ) {
        mensagemErro = mensagemErro + error.response.data.mensagem;
      }

      if (error.code == "ERR_BAD_RESPONSE") {
        mensagemErro = mensagemErro + error.response.data;
      }

      if (
        mensagemErro == MensagemErroUsuarioNEncontrado ||
        mensagemErro == MensagemErroTokenNPreechido
      ) {
        mensagemErro = mensagemErro + `\n` + "Atualizando token...";
        this.$refs.alert.alertGRV("warning", mensagemErro, this.Modulo);

        GetChaveConexao(this.host, localStorage.getItem("cnpj"))
          .then(this.Login)
          .catch(this.alertErro);
      } else if (mensagemErro == MensagemTimeOut) {
        GetChaveConexao(this.host, localStorage.getItem("cnpj"))
          .then(this.Login)
          .catch(this.alertErro);
      } else {
        if (typeof error.response.data.mensagem === "undefined") {
          mensagemErro =
            mensagemErro +
            "Erro ao atualizar, verifique os '''dados da configuração.";
          this.$refs.alert.alertGRV("warning", mensagemErro, this.Modulo);

          setTimeout(() => {
            this.$router.push("/config");
          }, "3000");
        }
      }
    },

    disabledBtn(value){
      if (value){
        document.querySelector("#btn-cancelar").setAttribute("disabled", true);

        if (!(this.resource.motivo_parada > 0)){
          document.querySelector("#btn-mp-infosCard").setAttribute("disabled", true);
          document.querySelector("#btn-fechar-infosCard").setAttribute("disabled", true);
        }
        else{
          document.querySelector("#btn-abrir-infosCard").setAttribute("disabled", true);
          document.querySelector("#btn-fecharmp-infosCard").setAttribute("disabled", true);
        }
      }
      else{
        document.querySelector("#btn-cancelar").removeAttribute("disabled");

        if (!(this.resource.motivo_parada > 0)){
          document.querySelector("#btn-mp-infosCard").removeAttribute("disabled");
          document.querySelector("#btn-fechar-infosCard").removeAttribute("disabled");
        }
        else{
          document.querySelector("#btn-abrir-infosCard").removeAttribute("disabled");
          document.querySelector("#btn-fecharmp-infosCard").removeAttribute("disabled");
        }
      }
    },

    AnimacaoBtns(btn) {
      $(btn).css("animation", "1s");
      $(btn).css("opacity", "0.5");

      setTimeout(function () {
        $(btn).css("animation", "1s");
        $(btn).css("opacity", "1");
      }, 200);
    },

    verificaEstiloApontamento() {
      if (localStorage.getItem("estilo_apontamento_client") == "OS") {
        this.apontaPorPP = false;
      } else if (
        localStorage.getItem("estilo_apontamento_client") ==
        "Processo Produtivo"
      ) {
        this.apontaPorPP = true;
      } else {
        if (localStorage.getItem("estilo_apontamento") == "OS") {
          this.apontaPorPP = false;
        } else {
          this.apontaPorPP = true;
        }
      }
    },

    DicasAtalho(){
      const btnAbrir = document.querySelector("#btn-abrir-infosCard");
      const btnMP = document.querySelector("#btn-mp-infosCard");
      const btnFechar = document.querySelector("#btn-fechar-infosCard");
      const btnFecharMP = document.querySelector("#btn-fecharmp-infosCard");

      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) == false
      ) {
        try{
          btnMP.innerHTML += " &lt;F2&gt;";
          btnFechar.innerHTML += " &lt;F4&gt;";
        }catch{
          btnAbrir.innerHTML += " &lt;F9&gt;";
          btnFecharMP.innerHTML += " &lt;F4&gt;";
        }
      }
    }
  }
}
</script>

<style scoped>
.div-infos{
  height: 575px; 
  overflow-y: auto; 
  margin-bottom: 46px;
}

#linha-1 {
  font-size: clamp(1.2rem, 0.3rem + 1vw, 1vw);
  margin: 5px 0 5px 9px;
  word-wrap: break-word;
  font-weight: 900;
  font-family: "Poppins";
  color: blue;
}

.outras-linhas {
  font-size: clamp(1.2rem, 0.3rem + 1vw, 1vw);
  margin: 0 0 5px 9px;
  word-wrap: break-word;
  font-weight: 900;
  font-family: "Poppins";
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.btns-modal {
  margin: 5px 0;
  width: 100px;
  font-size: 16px;
  padding: 6px 0;
  color: #fff;
  border: none;
  font-weight: 600;
  border-radius: 5px;
}

@media (width < 480px){
  .btns-modal {
    width: 80px;
    font-size: 13px;
  }

  .div-infos{
    margin-bottom: 40px;
  }
}
</style>