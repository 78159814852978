const ControllerEvents = {
    F2MotivoParadaMaquinas: function () {
        try {
            // Clica no 1º botão do 1º card - ApontamentosAbertos
            let divApontamentoAberto = document.querySelector(
                "#container-grid-apontamento"
            );
            divApontamentoAberto.children[0].children[1].children[0].children[1].children[0].click();
        } catch {
            // Clica no 1º botão do 1º card - Serviços
            let divServicos = document.querySelector("#container-gridcards3");
            divServicos.children[0].children[1].children[0].children[1].children[0].click();
        }
    },

    F4FecharApontamentoMotivoParadaMaquinas: function () {
        const divApontamentoAberto = document.querySelector(
            "#container-grid-apontamento"
        );
        const btn1 =
            divApontamentoAberto.children[0].children[1].children[0].children[1]
                .children[0];
        const btn2 =
            divApontamentoAberto.children[0].children[1].children[0].children[1]
                .children[1];

        if (
            btn1.innerText == "Fechar MP <F4>" ||
            btn1.innerText == "Fechar MP"
        ) {
            // Fechar MP
            try {
                btn1.click();
            } catch { }
        } else if (
            btn2.innerText == "Fechar <F4>" ||
            btn2.innerText == "Fechar"
        ) {
            // Fechar apontamento
            try {
                btn2.click();
            } catch { }
        }
    },

    F9AbrirMotivodeParadaMaquinas: function () {
        const divApontamentoAberto = document.querySelector(
            "#container-grid-apontamento"
        );
        const divpaiServicos = document.querySelector(
            "#container-gridcards3"
        );

        if (divApontamentoAberto) {
            const btnApontaAberto =
                divApontamentoAberto.children[0].children[1].children[0]
                    .children[1].children[1];

            if (
                btnApontaAberto.innerText == "Abrir <F9>" ||
                btnApontaAberto.innerText == "Abrir"
            ) {
                // Abrir dos apontamentos abertos
                try {
                    btnApontaAberto.click();
                } catch { }
            }
        } else if (divpaiServicos) {
            const btnServicos =
                divpaiServicos.children[0].children[1].children[0].children[1]
                    .children[1];

            if (
                btnServicos.innerText == "Abrir <E>" ||
                btnServicos.innerText == "Abrir"
            ) {
                // Abrir dos apontamentos abertos
                try {
                    btnServicos.click();
                } catch { }
            }
        }
    },
    F2AbrirMotivoDeParadaApontamentoAberto: function () {
        document.querySelector("#btn-mp-infosCard").click();
    },
    F4FecharMPApontentoAberto: function () {
        try {
            document.querySelector("#btn-fecharmp-infosCard").click();
        } catch {
            document.querySelector("#btn-fechar-infosCard").click();
        }
    },
    F9AbrirMPApontamentoAberto: function () {
        document.querySelector("#btn-abrir-infosCard").click();
    },
    F9AbrirApontamentoDetalheOS: function () {
        document.querySelector("#btn-abrir").click();
    },
    F4FecharApontamentoMPDetalheOS: function () {
        try {
            document.querySelector("#btn-fecharMP").click();
        } catch {
            document.querySelector("#btn-fechar").click();
        }
    },
    F2AbrirApontamentoMPDetalhesOS: function () {
        document.querySelector("#btn-MP").click();
    },
    F2AbrirMpInfoOS: function () {
        document.querySelector("#btn-mp-infosCard").click();
    },
    F9AbrirApontamentoInfoOs: function () {
        document.querySelector("#btn-abrir-infosCard").click();
    },
    EnterModals: function (pModalAberto, pShowMP, pShowTPServicos) {
        if (pModalAberto) {
            if (pShowMP) {
                document.querySelector(".div-mp").children[0].click();
            }

            if (pShowTPServicos) {
                document.querySelector(".div-tp").children[0].click();
            }
        } else {
            document.querySelector(".btnModalOK").click();
        }
    },
    FinalizarFecharApontamento: function (event) {
        if (event == "1") {
            document.querySelector("#o1").click();
        }

        if (event == "2") {
            document.querySelector("#o2").click();
        }
    },
    EnterOnApp: function (pShowModal) {
        if (pShowModal) {
            try {
                let divpaiFuncionario = document.querySelector(
                    "#container-gridcards1"
                );
                divpaiFuncionario.children[0].children[1].click();
            } catch {
                try {
                    let divpaiMaquina = document.querySelector(
                        "#container-gridcards2"
                    );
                    divpaiMaquina.children[0].children[1].click();
                } catch {
                    try {
                        let divpaiServicos = document.querySelector(
                            "#container-gridcards3"
                        );
                        divpaiServicos.children[0].children[1].children[0].children[1].children[1].click();
                    } catch { }
                }
            }
        }
        else {
            document.querySelector(".btnModalOK").click();
        }
    },
    Voltar : function(){
        document.querySelector(".btn-voltar").click();
    },
};

export default ControllerEvents;