<template>
  <v-app>
    <v-main>
      <v-dialog v-model="dialog" persistent class="v-dialog">
        <v-toolbar color="#004c89" style="color: white" title="Motivos de parada"></v-toolbar>
        <v-card style="height: 965px; margin-bottom: 30px;">
          <v-text-field :autofocus="autofocus" v-model="filter" id="pesquisamp" style="margin: 10px; font-weight: bold"
            @keyup="UpperCase" :label="'Pesquisa'"></v-text-field>
          <v-card-text style="padding: 0 !important;height: 100%;">
            <div class="main-container">
              <div class="container-list">
                <ul id="lista">
                  <div class="div-mp">
                    <li :id="'o' + resource.codigo" v-for="(resource, index) in ascSortedResources" :key="resource.codigo"
                      @click="OnClickModal">
                      <span :id="'o' + resource.codigo" v-if="index == 0">
                        &lt;E&gt; {{ resource.linha1 }}</span>

                      <span :id="'o' + resource.codigo" v-else> {{ resource.linha1 }}</span>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <div class="modal-footer" style="background-color: #004c89">
          <button id="btnCloseModalMP" class="btn btn-primary" @click="OnCancel" style="margin: 10px">
            Cancelar
          </button>
        </div>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { RequestPostMPApontamentoAgrupado } from "../../../../../CPSWeb/front-end/services/ApiRequest.js";

export default {
  emits: ["OnCloseModalMP", "OnSelectMP", "ResetaBtnsMPApontaAgrupado", "CancelMPApontaAgrupado", "resetaShowMP","OnVoltar", "ErroMPApontaAgrupado"],
  props: {
    showMP: Boolean,
    resource: Object,
    dataModal: String,
    origemMP: String,
    versaoClient: String,
    host : String
  },
  data() {
    return {
      dialog: false,
      filter: "",
      autofocus: true,
    };
  },
  computed: {
    ascSortedResources() {
      if (this.resource.length > 0) {
        if (this.pesquisaLeitor()) {
          if (this.dataModal == "motivo_parada") {
            return this.resource
              .map((e) => e)
              .sort((a, b) => a.priority - b.priority)
              .filter(({ linha1 }) => linha1.includes(this.filter.toUpperCase()));
          } else {
            return this.resource;
          }
        }else {
          return this.resource;
        }
      } else {
        return this.resource;
      }
    },
  },
  methods: {
    pesquisaLeitor() {
      if (this.filter.indexOf('<') == -1) {
        return true
      }
      else {
        return false
      }
    },
    OnCancel() {
      this.$emit("OnCloseModalMP");
      
      if (this.origemMP != "apontamento_normal"){
        this.$emit("CancelMPApontaAgrupado");
      }
    },

    OnClickModal(e) {
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        e.target.id.replace("o", "")
      );

      if (this.origemMP == "apontamento_normal") {
        this.$emit("OnSelectMP");
      } else {
        const body = {
          cod_maquina: sessionStorage.getItem("cod_maquina"),
          cod_funcionario: sessionStorage.getItem("cod_funcionario"),
          cod_agrupamento: sessionStorage.getItem("cod_agrupamento"),
          cod_tp_servico: e.target.id.replace("o", ""),
          versao_client: this.versaoClient
        }

        RequestPostMPApontamentoAgrupado(
          this.host,
          localStorage.getItem("chave_mensageria"),
          localStorage.getItem("token"),
          body
        )
          .then(this.afterMPApontamentoAgrupado)
          .catch(this.OnError);
      }
    },

    afterMPApontamentoAgrupado(res) {
      this.dialog = false;
      this.$emit("resetaShowMP");

      this.$emit('ResetaBtnsMPApontaAgrupado');
    },

    UpperCase() {
      this.filter = this.filter.toUpperCase();
    },

    OnError(erro){
      console.log(erro);

      this.$emit("resetaShowMP");
      this.$emit("ErroMPApontaAgrupado", erro.response.data.mensagem);
    }
  },

  created() {
    this.dialog = this.showMP;

    if (localStorage.getItem("teclado_virtual") == "true"){
      this.autofocus = true;
    }else{
      this.autofocus = false;
    }
  },

  watch: {
    showMP(newValue) {
      this.dialog = newValue;
    },
    
    filter(newValue){
      if (this.filter == '<ESC>' || newValue.indexOf('<ESC>') > 0) {
        this.$emit('OnVoltar','<ESC>');
      }
      if (this.filter == '<F5>' || newValue.indexOf('<F5>') > 0) {
        this.$emit('OnVoltar','<F5>');
      }
     }
  },
};
</script>

<style scoped>
.v-dialog {
  width: 40%;
}

.container-list {
  width: 100%;
  height: 100%;
  margin-bottom: 5%;
}

.container-list ul {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.div-mp:last-child {
  margin-bottom: 35px;
}

li {
  font-family: "Poppins";
  font-weight: bold;
  padding: 3%;
  cursor: pointer;
}

li:hover {
  opacity: 0.6;
}

/* Alterna as cores das linhas (cinza e branco) */
li:nth-child(even) {
  background: lightgray;
  padding: 3%;
}

@media (max-width: 600px) {
  .v-dialog {
    width: 100%;
  }
}

@media (min-width: 601px) and (max-width: 1000px) {
  .v-dialog {
    width: 70%;
  }
}
</style>