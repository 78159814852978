<template>
  <div>
    <div class="modals">
      <DialogErro :show="ShowDialogErro" :message="MensagemErroModal" @showDialog="ShowDialog"/>

      <LoadingModal v-if="ShowModalLoadCards" :sizeTitle="sizeTitle" :title="MensagemModalLoad" />
    </div>

    <form class="form">
      <div class="mb-3">
        <label style="font-weight: bold" for="exampleFormControlInput1" class="form-label">Chave</label>
        <input type="text" class="form-control" v-model="form.chave" placeholder="Chave de conexão" />
      </div>
      <div class="mb-3" v-if="APP_NAME != 'APONTAMENTOWEB'">
        <label style="font-weight: bold" class="form-label">Usuario CPS</label>
        <input type="text" class="form-control" v-model="form.usercps" />
      </div>
      <div class="mb-3">
        <label style="font-weight: bold" class="form-label">{{
          edtSenha
        }}</label>
        <input type="password" class="form-control" v-model="form.password" />
      </div>
      <div class="mb-3">
        <p style="font-weight: bold" class="form-label">
          Visualizar somente os funcionários do setor. Informe os códigos dos
          setores por "," (virgula) <br />
          Exemplo: 1,2,5. Para visualizar todos os setores deixe o campo vazio.
        </p>
        <input type="text" class="form-control" v-model="form.setores" />
      </div>
      <div class="mb-3">
        <label style="font-weight: bold" class="form-label">Caminho do servidor REST</label>
        <input type="text" class="form-control" v-model="form.servidorRest" />
      </div>
      <div class="mb-3">
        <label style="font-weight: bold" class="form-label">Porta do Servidor Rest</label>
        <input type="number" class="form-control" v-model="form.portaRest" placeholder="9080" />
      </div>
      <div class="mb-3">
        <label style="font-weight: bold" class="form-label">Número de Itens a Serem Exibidos</label>
        <input type="number" class="form-control" v-model="form.qtd_cards" placeholder="" />
      </div>
      <div class="mb-3">
        <v-select label="Estilo de apontamento" density="compact" color="#0d6efd" v-model="form.estilo_apontamento"
          style="font-weight: bold" :items="['Padrão', 'OS', 'Processo Produtivo']"></v-select>
      </div>      

      <v-switch label="Habilitar Teclado Virtual (Foco automático na pesquisa para uso do teclado)" inset v-model="teclado_virtual"></v-switch>

      <button type="button" :onclick="salvarConfiguracoes" class="btn btn-primary btn-lg"
        style="margin: 20px 0; color: white">
        Salvar configurações
      </button>

      <router-link to="/">
        <button type="button" class="btn btn-primary btn-lg" style="margin: 20px 15px; color: white">
          Voltar
        </button>
      </router-link>
    </form>
  </div>
</template>

<script>
import {
  getChaveServidorRest,
  getPortaServidorRest,
  getUsuarioServidorRest,
  getSenhaServidorRest,
  getTempoSemExecucao,
  GenerateCrypt,
  DecodeCrypt,
  getSetores,
  getConfigServidorRest,
  getServidor,
} from "../../../../../CPSWeb/front-end/library/utils.js";
import { login } from "../../../../../CPSWeb/front-end/services/ApiRequest.js";
import DialogErro from "../Modal/DialogErro.vue";
import LoadingModal from "../../../../../CPSWeb/front-end/Componentes/Modal/LoadingModal.vue";

import { HostsV3Up } from "../../../../../CPSWeb/front-end/library/hostsv3.js";

import {useAppConnectionWs} from "../../store/appConnectionWs"

export default {
  name: "configVue",
  data() {
    return {
      teclado_virtual: false,
      date: null,
      sizeTitle: {
        fontSize: 25 + "px",
      },
      ShowDialogErro: false,
      MensagemModalLoad:
        "Salvando configurações, isso pode levar alguns minutos...",
      ShowModalLoadCards: false,
      MensagemErroModal: "",
      controller: new AbortController(),
      listTipoDeConexões: ["CNPJ", "Chave"],
      mensagemAlert: "null",
      tituloAlert: "Configuração",
      tipoAlert: "",
      edtSenha: "",
      Modulo: "Configurações",
      form: {
        CNPJ: "",
        servidorRest: "",
        portaRest: "",
        password: "",
        chave: "",
        usuario: "CPSAPONTAWEB",
        tempoSemInteracao: null,
        usercps: "",
        setores: "",
        estilo_apontamento: "Padrão",
        mesageria: "V3 / Superior",
        qtd_cards: 15,
      },
      options: {
        CNPJ: {
          placeholder: "00.000.000/0000-00",
        },
      },
      mensagemSalvar: "Configuração salva com sucesso!",
      tela: "Configuração do servidor",
      HostsMensageria: null,
      Host: "https://apontamento.grv.net.br:9081",
      HostOn: {
        host: 0,
        online: true,
        local: false,
      },
    };
  },

  watch: {
    teclado_virtual(newValue) {
      localStorage.setItem("teclado_virtual", newValue);
    },
    "form.mesageria"(){
      this.OnCheckVersionMensageria();

      if (localStorage.getItem("HostOn") != null) {
        this.HostOn = JSON.parse(localStorage.getItem("HostOn"));
        this.OnCheckUltHost().then(this.OnCheckHost);
      } else {
        this.OnCheckUltHost();
      }
    }
  },

  inject: ["APP_NAME"],
  async created() {
    this.ConfirmarTecladoVirtual();

    if (localStorage.getItem("qtd_cards") != undefined){
      this.form.qtd_cards = parseInt(localStorage.getItem("qtd_cards"));
    }

    // this.form.qtd_cards = parseInt(localStorage.getItem("qtd_cards"));

    if (this.APP_NAME == "APONTAMENTOWEB") {
      this.edtSenha = "Chave de autenticação";
    } else {
      this.edtSenha = "Senha";
    }

    this.atualizaCampos();

  },
  methods: {
    ConfirmarTecladoVirtual() {
      if (localStorage.getItem("teclado_virtual") != null) {
        if (localStorage.getItem("teclado_virtual") == "true") {
          this.teclado_virtual = true;
        } else if (localStorage.getItem("teclado_virtual") == "false") {
          this.teclado_virtual = false;
        }
      }
      else {
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ) == false
        ) {
          this.teclado_virtual = true;
        }
      }
    },
    async OnCheckVersionMensageria() {      
      this.HostsMensageria = HostsV3Up;      
    },
    async OnCheckUltHost() {
      if (this.HostOn.host == 0 && this.HostOn.online == true) {
        this.Host = this.HostsMensageria.host;
      }

      if (this.HostOn.host == 1 && this.HostOn.online == true) {
        this.Host = this.HostsMensageria.host_alt1;
      }

      if (this.HostOn.host == 2 && this.HostOn.online == true) {
        this.Host = this.HostsMensageria.host_alt2;
      }

      if (this.HostOn.host == 3 && this.HostOn.online == true) {
        this.Host = this.HostsMensageria.host_alt3;
      }
      localStorage.setItem("HostOn", JSON.stringify(this.HostOn));
    },
    async SetHostOn(pURL, pOnline) {
      if (pURL == this.HostsMensageria.host) {
        this.HostOn.host = 0;
        this.HostOn.online = pOnline;
      }

      if (pURL == this.HostsMensageria.host_alt1) {
        this.HostOn.host = 1;
        this.HostOn.online = pOnline;
      }

      if (pURL == this.HostsMensageria.host_alt2) {
        this.HostOn.host = 2;
        this.HostOn.online = pOnline;
      }

      if (pURL == this.HostsMensageria.host_alt3) {
        this.HostOn.host = 3;
        this.HostOn.online = pOnline;
      }
    },
    OnCheckHost() {
      if (this.HostOn.host == 0 && this.HostOn.online == false) {
        this.Host = this.HostsMensageria.host_alt1;
        this.HostOn.host = 1;
        this.HostOn.online = true;
      }

      if (this.HostOn.host == 1 && this.HostOn.online == false) {
        this.Host = this.HostsMensageria.host_alt2;
        this.HostOn.host = 2;
        this.HostOn.online = true;
      }

      if (this.HostOn.host == 2 && this.HostOn.online == false) {
        this.Host = this.HostsMensageria.host_alt3;
        this.HostOn.host = 3;
        this.HostOn.online = true;
      }

      if (this.HostOn.host == 3 && this.HostOn.online == false) {
        this.Host = this.HostsMensageria.host;
        this.HostOn.host = 0;
        this.HostOn.online = true;
      }

      localStorage.setItem("HostOn", JSON.stringify(this.HostOn));
    },
    alertErro(error) {
      console.log(error);
      this.ShowModalLoadCards = false;
      this.MensagemErroModal = "";

      if (error.code == "ERR_NETWORK") {
        this.SetHostOn(this.Host, false).then(this.OnCheckHost);
        this.OnCheckUltHost().then(this.OnCheckHost);
        this.MensagemErroModal = "Erro ao conectar com o servidor.";
        this.ShowDialogErro = true;
        localStorage.setItem("MensagemErro", this.MensagemErroModal);
      }

      if (error.code == "ERR_BAD_REQUEST") {
        if (error.response.data == "Servidor para CNPJ not found") {
          this.MensagemErroModal = "CNPJ não encontrado no servidor.";
          this.ShowDialogErro = true;
          localStorage.setItem("MensagemErro", this.MensagemErroModal);
        }

        if (error.response.data.codigo == 2) {
          this.MensagemErroModal = error.response.data.mensagem;
          this.ShowDialogErro = true;
          localStorage.setItem("MensagemErro", this.MensagemErroModal);
        }
      }

      if (error.response.status == 500) {
        this.SetHostOn(this.Host, false).then(this.OnCheckHost);
        this.MensagemErroModal = "Erro interno no servidor.";
        this.ShowDialogErro = true;
        localStorage.setItem("MensagemErro", this.MensagemErroModal);
      }

      if (error.code == "ERR_BAD_RESPONSE") {
        if (error.response.status == 504) {
          this.MensagemErroModal =
            "Erro ao salvar as configurações, verifique os dados de conexão.";
          this.ShowDialogErro = true;
          localStorage.setItem("MensagemErro", this.MensagemErroModal);
        }
      }
    },
    async salvarConfiguracoes() {
      localStorage.setItem("setores", this.form.setores);
      localStorage.setItem("caminhoRest", this.form.servidorRest);
      localStorage.setItem("portaRest", this.form.portaRest);
      localStorage.setItem('mensageria', this.form.mesageria);

      if (this.form.qtd_cards == null || this.form.qtd_cards == ""){
        localStorage.setItem("qtd_cards", 15);
      }else{
        localStorage.setItem("qtd_cards", this.form.qtd_cards);
      }

      if ((this.form.servidorRest != null) && (this.form.servidorRest != '')) {
        this.HostOn.local = true;
        this.Host = `${this.form.servidorRest}:${this.form.portaRest}`
      }
      else {
        this.HostOn.local = false;
      }

      localStorage.setItem("HostOn", JSON.stringify(this.HostOn));

      localStorage.setItem("usuario", this.form.usuario);
      localStorage.setItem("usuariocps", this.form.usercps);

      if (this.form.password != "") {
        localStorage.setItem(
          "5f4dcc3b5aa765d61d8327deb882cf99",
          GenerateCrypt(this.form.password, this.form.password)
        );
      }


      localStorage.setItem("chave_mensageria", this.form.chave);
      localStorage.setItem(
        "tempoSemExecucao",
        this.form.tempoSemInteracao * 60000
      );

      if (this.form.password == "") {
        this.MensagemErroModal =
          "Chave de autenticação Não preenchida. Por favor verificar o cadastro antes de salvar as configurações";
        this.ShowDialogErro = true;
        throw "";
      }

      if (this.form.chave == null || this.form.chave == "") {
        this.MensagemErroModal =
          "Chave Não preenchida. Por favor verificar o cadastro antes de salvar as configurações";
        this.ShowDialogErro = true;
        throw "";
      }
      this.ShowModalLoadCards = true;

      localStorage.setItem('teclado_virtual',this.teclado_virtual);

      await this.OnCheckVersionMensageria()

      login(
        this.Host,
        this.form.chave,
        1,
        this.form.usuario,
        this.form.usercps,
        GenerateCrypt(this.form.password, this.form.password)
      )
        .then(this.afterLoginChave)
        .catch(this.alertErro);
    },

    afterLoginChave(res) {
      if (res.status === 200) {
        this.ShowModalLoadCards = false;

        localStorage.setItem("tipo_banco", res.data.tipo_banco);
        localStorage.setItem("chave_mensageria", this.form.chave);
        sessionStorage.setItem(
          "chave",
          localStorage.getItem("chave_mensageria")
        );

        // localStorage.setItem("cnpj", res.data.cnpj);
        localStorage.setItem("local", res.data.ip);

        if (res.data.email != undefined) {
          localStorage.setItem("emailUser", res.data.email);
        }

        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem("versaoServer", res.data.versao);

        localStorage.setItem("estilo_apontamento", res.data.estilo_apontamento);

        localStorage.setItem(
          "estilo_apontamento_client",
          this.form.estilo_apontamento
        );

        localStorage.setItem(
          "ordenacao_apontamento",
          res.data.ordenacao_apontamento
        );
        localStorage.setItem(
          "visualizacao_apontamento",
          res.data.visualizacao_apontamento
        );
        localStorage.setItem("exibe_cliente", res.data.exibe_cliente);
        localStorage.setItem(
          "PossuiChaoFabricaSemPapel",
          res.data.PossuiChaoFabricaSemPapel
        );


        useAppConnectionWs().connectar()
                
        this.$router.push("/");
      }
    },

    async atualizaCampos() {
      if (localStorage.getItem('mensageria') != undefined) {
        this.form.mesageria = localStorage.getItem('mensageria')
      }

      await this.OnCheckVersionMensageria()

      if (localStorage.getItem("HostOn") != null) {
        this.HostOn = JSON.parse(localStorage.getItem("HostOn"));
        this.OnCheckUltHost().then(this.OnCheckHost);
      } else {
        this.OnCheckUltHost();
      }


      this.form.chave = getChaveServidorRest();

      if (getUsuarioServidorRest() != null) {
        this.form.usuario = getUsuarioServidorRest();
      }

      if (getSenhaServidorRest() != null) {
        this.form.password = DecodeCrypt(getSenhaServidorRest());
      }

      if (getSetores() != null) {
        this.form.setores = getSetores();
      }

      if (localStorage.getItem("estilo_apontamento") == null) {
        this.form.estilo_apontamento = "Padrão";
      } else {
        this.form.estilo_apontamento = localStorage.getItem(
          "estilo_apontamento_client"
        );
      }

      if (this.HostOn.local) {
        this.form.servidorRest = localStorage.getItem('caminhoRest');
        this.form.portaRest = parseInt(localStorage.getItem('portaRest'))
      }

      this.form.tempoSemInteracao = getTempoSemExecucao();
    },

    ShowDialog(value){
      this.ShowDialogErro = value;
    } 
  },
  components: { DialogErro, LoadingModal },
};
</script>

<style  scoped>
.form {
  margin-top: 7em;
  margin-left: 1em;
  margin-right: 1em;
}
</style>