import { request, METHOD } from '../library/request'
import {
  LOGIN, GETSQL,
  CHAVE,
  FUNCIONARIOPRODUCAO,
  MAQUINAS,
  MOTIVOPARADA, PROCESSOPRODUTIVO,
  OSPRODUCAO,
  TIPODESERVICO,
  APONTAMENTOPP,
  APONTAMENTOAGRUPADOABRIRPP,
  APONTAMENTOAGRUPADOMP,
  APONTAMENTOAGRUPADOFECHAR,
  APONTAMENTOAGRUPADOFINALIZAR,
  APONTAMENTOAGRUPADOFINALIZARPARCIAL,
  APONTAMENTOFUNC,
  APONTAMENTOFECHAR,
  APONTAMENTOOS,
  LISTAPDF,
  GETPDF,
  APROVAROC,
  GETPDFFAKE,
  TEMPERMISSAO,
  FUNCIONARIOPRODUCAOSETOR
} from './api'
import { getConfigServidorRest, Log, GenerateCrypt } from '../library/utils'

const versionPrefix = '/v1';

const URLLogGRV = 'https://logs.grv.net.br:1880/log';

export function getConfigHeadersOC(options, chave, sendInfo) {

  options.onBeforeSend = function (method, ajaxOptions) {
    //  let user = JSON.parse(localStorage.getItem('user'));

    ajaxOptions.headers = {
      "chave": chave,
      "token": "teste",
      "SQL": sendInfo.SQL,
      "Params": sendInfo.Params
    }
  }
  return options;
}


export async function login(host,chave, cod_Empresa, user, usercps, pass) {
  return request(`${host}${versionPrefix}${LOGIN}`, METHOD.POST, {
    chave: chave,
    cod_Empresa: cod_Empresa,
    user: user,
    usercps: usercps,
    f4dcc3b5aa765d61d8327deb882cf99: pass
  }
  )
}

export async function RequestPDFFAKE(chave, pos) {
  return request(getConfigServidorRest() + versionPrefix + GETPDFFAKE, METHOD.POST,
    {
      pos: parseInt(pos)
    }, {},
    {
      headers: {
        'chave': chave
      }
    })
}


export async function RequestGETPDF(pHost,pToken, pChave, pParams) {
  return request(`${pHost}${versionPrefix}${GETPDF}`, METHOD.GET, {}, {}, {
    headers: {
      'token': pToken,
      'chave': pChave,
      'params': JSON.stringify(pParams),
      'text-plain': true
    }
  })
}

export async function RequestListaPDF(phost,pToken, pChave, pParams) {
  
  return request(`${phost}${versionPrefix}${LISTAPDF}`, METHOD.GET, {}, {}, {
    headers: {
      'token': pToken,
      'chave': pChave,
      'params': JSON.stringify(pParams),
      'text-plain': true
    }
  })
}

export async function RequestPostAprovarOC(pToken, pChave, body) {
  return request(getConfigServidorRest() + versionPrefix + APROVAROC, METHOD.POST, body, {}, {
    headers: {
      'chave': pChave,
      'token': pToken

    }
  })
}

export async function PostErroQuedaAppLogGRV(pVersaoClient) {
  const DataHoraAtual = new Date();
  const JsonSend = {
    streams: [
      {
        stream: {
          Sistema: "APONTAMENTOWEB",
          Versao: pVersaoClient,
          versao_server: localStorage.getItem('versaoServer'),
          chave_servidor: localStorage.getItem('chave_mensageria'),
          Tipo_log: "INFO",
          Criticidade: "MEDIO",
          CNPJ: localStorage.getItem('cnpj'),
          TipoApp: "WEB",
          LocalIP: localStorage.getItem('local'),
          acao: "QUEDA APP"
        },
        values: [
          [
            JSON.stringify(Math.round(DataHoraAtual.getTime() * 1000000)),
            ' '
          ]
        ]
      }
    ]
  }


  return request(URLLogGRV, METHOD.POST, JsonSend, {}, {
    auth: {
      username: 'grv',
      password: 'Log@Grv*H9@Loki'
    }
  })

}


export async function PostLogGRV(pVersaoClient) {
  const DataHoraAtual = new Date();
  const JsonSend = {
    streams: [
      {
        stream: {
          Sistema: "APONTAMENTOWEB",
          Versao: pVersaoClient,
          versao_server: localStorage.getItem('versaoServer'),
          chave_servidor: localStorage.getItem('chave_mensageria'),
          Tipo_log: "INFO",
          Criticidade: "BAIXO",
          CNPJ: localStorage.getItem('cnpj'),
          TipoApp: "WEB",
          LocalIP: localStorage.getItem('local'),
          acao: "ABRIR_APONTAMENTO"
        },
        values: [
          [
            JSON.stringify(Math.round(DataHoraAtual.getTime() * 1000000)),
            ' '
          ]
        ]
      }
    ]
  }


  return request(URLLogGRV, METHOD.POST, JsonSend, {}, {
    auth: {
      username: 'grv',
      password: 'Log@Grv*H9@Loki'
    }
  })

}

export async function RequestPostAbrirApontamentoAgrupado(pHost,pChave, pToken, body) {
  return request(pHost+ versionPrefix + APONTAMENTOAGRUPADOABRIRPP, METHOD.POST, body, {}, {
    headers: {
      'chave': pChave,
      'token': pToken

    }
  })
}

export async function RequestPostMPApontamentoAgrupado(pHost,pChave, pToken, body) {
  return request(pHost + versionPrefix + APONTAMENTOAGRUPADOMP, METHOD.POST, body, {}, {
    headers: {
      'chave': pChave,
      'token': pToken

    }
  })
}

export async function RequestPostFecharApontamentoAgrupado(pHost,pChave, pToken, body) {
  return request(pHost + versionPrefix + APONTAMENTOAGRUPADOFECHAR, METHOD.POST, body, {}, {
    headers: {
      'chave': pChave,
      'token': pToken

    }
  })
}

export async function RequestPostFinalizarApontamentoAgrupado(pHost,pChave, pToken, body) {
  return request(pHost + versionPrefix + APONTAMENTOAGRUPADOFINALIZAR, METHOD.POST, body, {}, {
    headers: {
      'chave': pChave,
      'token': pToken

    }
  })
}

export async function RequestPostFinalizarApontamentoAgrupadoParcial(pHost,pChave, pToken, body) {
  return request(pHost + versionPrefix + APONTAMENTOAGRUPADOFINALIZARPARCIAL, METHOD.POST, body, {}, {
    headers: {
      'chave': pChave,
      'token': pToken

    }
  })
}

export async function RequestPostAbrirApontamento(pHost,pToken, pChave, body) {
  if (localStorage.getItem('estilo_apontamento_client') == 'Processo Produtivo') {
    return request(`${pHost}${versionPrefix}${APONTAMENTOPP}`  , METHOD.POST, body, {}, {
      headers: {
        'chave': pChave,
        'token': pToken

      }
    })
  }
  else if (localStorage.getItem('estilo_apontamento_client') == 'OS') {
    return request(`${pHost}${versionPrefix}${APONTAMENTOOS}` , METHOD.POST, body, {}, {  
      headers: {
        'chave': pChave,
        'token': pToken

      }
    })
  }
  else {
    if (localStorage.getItem('estilo_apontamento') == 'PROCESSO_PRODUTIVO') {
      return request(`${pHost}${versionPrefix}${APONTAMENTOPP}`, METHOD.POST, body, {}, {
        headers: {
          'chave': pChave,
          'token': pToken

        }
      })
    }
    else if (localStorage.getItem('estilo_apontamento') == 'OS') {
      return request(`${pHost}${versionPrefix}${APONTAMENTOOS}`, METHOD.POST, body, {}, {
        headers: {
          'chave': pChave,
          'token': pToken

        }
      })
    }
  }

}


export async function RequestPostDecodeGRV(pToken, body) {
  return request('http://localhost:9002/decodePost', METHOD.POST, body, {}, {
    headers: {
      'token': pToken
    }
  })
}


export async function RequestPostFecharApontamento(pHost,pToken, pChave, body) {
  return request(`${pHost}${versionPrefix}${APONTAMENTOFECHAR}`, METHOD.POST, body, {}, {
    headers: {
      'chave': pChave,
      'token': pToken

    }
  })
}

export async function RequestGETMaquinas(pHost,pToken, pChave, pSelectTodasMaquinas) {
  return request(`${pHost}${versionPrefix}${MAQUINAS}`, METHOD.GET, {}, {}, {
    headers: {
      'chave': pChave,
      'token': pToken,
      'SelectTodasMaquinas': pSelectTodasMaquinas,
      'codfuncionario': sessionStorage.getItem('cod_funcionario'),
      'filter': 'and ta.cod_funcionario =  ' + sessionStorage.getItem('cod_funcionario'),
      'text-plain': true
    }
  })
}

export async function requestGETTemPermissao(pChave, pToken, pCodEmpresa, pModulo, pNivelPermissao) {
  const Chave = `/${pChave}`
  const Token = `/${pToken}`
  const Empresa = `/${pCodEmpresa}`
  const Modulo = `/${pModulo}`
  const NivelPermissao = `/${pNivelPermissao}`
  return request(getConfigServidorRest() + versionPrefix + TEMPERMISSAO + Chave + Token + Empresa + Modulo + NivelPermissao, METHOD.GET, {}, {}, {})
}

export async function RequestGETServicos(pHost,pToken, pChave, pEstiloApontamento) {
  const dataAtual = new Date();
  localStorage.setItem('HoraRequisicao', dataAtual.getHours())
  localStorage.setItem('MinutoRequicao', dataAtual.getMinutes())
  localStorage.setItem('SegundosRequsicao', dataAtual.getSeconds())

  if (pEstiloApontamento == 'PROCESSO_PRODUTIVO') {
    return request(`${pHost}${versionPrefix}${PROCESSOPRODUTIVO}`, METHOD.GET, {}, {}, {
      headers: {
        'chave': pChave,
        'token': pToken,
        'filter': 'TPRO_PRO.COD_TP_SERVICO in (' + sessionStorage.getItem('tipos_de_servicos') + ')',
        'estilo-apontamento-client': localStorage.getItem('estilo_apontamento_client').replace('ã', 'a'),
        'text-plain': true
      }
    })
  }

  if (pEstiloApontamento == 'OS') {
    return request(`${pHost}${versionPrefix}${OSPRODUCAO}`, METHOD.GET, {}, {}, {
      headers: {
        'chave': pChave,
        'token': pToken,
        'estilo-apontamento-client': localStorage.getItem('estilo_apontamento_client').replace('ã', 'a'),
      }
    })
  }
}

export async function RequestGETTIPOServicos(pHost,pToken, pChave) {
  return request(`${pHost}${versionPrefix}${TIPODESERVICO}`, METHOD.GET, {}, {}, {
    headers: {
      'chave': pChave,
      'token': pToken
    }
  })
}

export async function TesteApontamento(pUrl, pToken, pChave) {
  const dataAtual = new Date();
  localStorage.setItem('HoraRequisicao', dataAtual.getHours())
  localStorage.setItem('MinutoRequicao', dataAtual.getMinutes())
  localStorage.setItem('SegundosRequsicao', dataAtual.getSeconds())

  return request(pUrl, METHOD.GET, {}, {}, {
    headers: {
      'chave': pChave,
      'token': pToken
    }
  })
}

export async function ResquestGETFuncionariosProducao(phost,pToken, pchave, pSetorSelecionado) {
  return request(`${phost}${versionPrefix}${FUNCIONARIOPRODUCAO}`, METHOD.GET, {}, {}, {
    headers: {
      'chave': pchave,
      'token': pToken,
      'setores': pSetorSelecionado,
      'text-plain': true
    }
  })
}

export async function ResquestGETFuncionariosProducaoSetor(phost,pToken, pchave, pSetorSelecionado) {
  return request(`${phost}${versionPrefix}${FUNCIONARIOPRODUCAOSETOR}`, METHOD.GET, {}, {}, {
    headers: {
      'chave': pchave,
      'token': pToken,
      'setores': pSetorSelecionado,
      'text-plain': true
    }
  })
}

export async function ResquestGETMotivoDeParada(pHost,pToken, pchave) {
  return request(`${pHost}${versionPrefix}${MOTIVOPARADA}`, METHOD.GET, {}, {}, {
    headers: {
      'chave': pchave,
      'token': pToken
    }
  })
}

export async function RequestGETSQL(pHost,pchave, pToken, sendInfo) {
  console.log(`${pHost}${versionPrefix}${GETSQL}`)
  return request(`${pHost}${versionPrefix}${GETSQL}`, METHOD.GET, {}, {}, {
    headers: {
      'chave': pchave,
      'token': pToken,
      'timeout': 50000,
      "SQL": GenerateCrypt(pToken, sendInfo.SQL),
      "Params": GenerateCrypt(pToken, sendInfo.Params),
      'text-plain': 'false'
    }
  })
}


export async function GetChaveConexao(pHost,pCNPJ) {
  return request(`${pHost}${versionPrefix}/${pCNPJ.replace('.', '')}`, METHOD.GET, {}, {}, {})
}


