<template>
  <div class="cols">
    <alertGRV ref="alert" />
    <div class="cards" @click="clickCards" :id="'Card' + numeroCard">
      <div class="status">
        <div class="af-card p-2" id="af-card">
          <div
            class="div-status"
            v-bind:style="{
              backgroundColor: resource.colorHeaderCard,
              height: 90 + '%',
              marginBottom: -1 + 'px',
              borderRadius: 5 + 'px',
            }"
          >
            <span
              class="div-status-span"
              v-bind:style="{
                color: 'white',
                fontSize: 18 + 'px',
                borderRadius: 5 + 'px',
              }"
            >
              <h1 class="div-status-h1" id="linha-1">{{ resource.linha1 }}</h1>
            </span>
          </div>
        </div>
      </div>
      <div class="conteudoCards">
        <div class="div-conteudo">
          <!-- Foto do recurso -->
          <div
            v-if="checkimage"
            class="img flex-shrink-0"
            id="img"
            :style="{ backgroundImage: 'url(' + adjustedimg + ')' }"
          ></div>
          <div
            v-if="
              resource.quant_ult_apontamentos >= 1 &&
              resource.quant_ult_apontamentos != undefined
            "
            class="maquina_ults_apont"
          >
            <i
              class="bi bi-star"
              style="color: darkblue; font-weight: bold"
              title="Máquina com apontamento nos últimos 40 dias."
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HABILITALOGS } from "../../../../../CPSWeb/front-end/library/Publics";
import alertGRV from "../../../../../CPSWeb/front-end/Componentes/alert/alert.vue";
import LoadingModal from "../../../../../CPSWeb/front-end/Componentes/Modal/LoadingModal.vue";
import { Log } from "../../../../../CPSWeb/front-end/library/utils";
import ControllerEventsApontamento from "../../controllers/controllerApontamento.js";

export default {
  name: "GridCardsMaquinaVue",
  emits: [
    "MaquinaSelecionada",
    "UpdateResourceShowModal",
    "OnHabilitaBtnsCards",
    "OnHabilitaDIVPrincipal",
  ],
  props: {
    resource: Object,
    numeroCard: String,
    tipoAtualizacao: String,
    host: String,
  },
  components: { alertGRV, LoadingModal },

  data() {
    return {};
  },

  created() {},

  mounted() {
    this.$store.state.funcionario_apont_grupo;
  },  

  computed: {
    adjustedimg() {
      if (this.resource.url_img != undefined) {
        return this.resource.url_img;
      } else {
        return `data:image;base64,${this.resource.img.replace(/\n/g, "")}`;
      }
    },

    checkimage() {
      if (this.resource.url_img == "null") {
        return 0;
      } else {
        return 1;
      }
    },
  },

  methods: {
    AnimacaoCard(e) {
      var card;

      if (
        e.target.className == "status" ||
        e.target.className == "conteudoCards"
      ) {
        card = document.getElementById(e.target.parentNode.id);
      } else if (
        e.target.id == "af-card" ||
        e.target.className == "div-conteudo" ||
        e.target.id == "linha-2" ||
        e.target.id == "linha-3"
      ) {
        card = document.getElementById(e.target.parentNode.parentNode.id);
      } else if (e.target.className == "div-status" || e.target.id == "img") {
        card = document.getElementById(
          e.target.parentNode.parentNode.parentNode.id
        );
      } else if (e.target.className == "div-status-span") {
        card = document.getElementById(
          e.target.parentNode.parentNode.parentNode.parentNode.id
        );
      } else if (e.target.className == "div-status-h1") {
        card = document.getElementById(
          e.target.parentNode.parentNode.parentNode.parentNode.parentNode.id
        );
      }

      $(card).css("background", "rgba(0, 0, 0, 0.2)");
      $(card).css("animation", "1s");

      setTimeout(function () {
        $(card).css("animation", "1s");
        $(card).css("background", "#fff");
      }, 200);
    },

    async clickCards(e) {
      this.AnimacaoCard(e);

      try {
        await this.VerificaStatus();

        sessionStorage.setItem("cod_maquina", this.resource.codigo);

        let tiposDeServico = "";
        tiposDeServico = this.resource.cod_tp_servico;

        localStorage.setItem(
          "informar_tipo_servico",
          this.resource.informar_tipo_servico
        );

        localStorage.setItem(
          "apontamento_por_peca",
          this.resource.apontamento_por_peca
        );

        sessionStorage.setItem(
          "tp_servico_maquina",
          this.resource.cod_tp_servico
        );

        sessionStorage.setItem(
          "tp_servicos_selecionado",
          this.resource.cod_tp_servico
        );

        if (this.resource.tipos_de_servico != null) {
          for (
            let index = 0;
            index < this.resource.tipos_de_servico.length;
            index++
          ) {
            tiposDeServico =
              tiposDeServico +
              "," +
              this.resource.tipos_de_servico[index].codigo;
          }
        }

        sessionStorage.setItem("tipos_de_servicos", tiposDeServico);

        if (this.resource.url_img != undefined) {
          this.$emit(
            "MaquinaSelecionada",
            this.resource.linha1,
            this.resource.url_img
          );
        } else {
          this.$emit(
            "MaquinaSelecionada",
            this.resource.linha1,
            this.resource.img
          );
        }
      } catch (error) {}
    },

    async VerificaStatus() {
      const res =
        await ControllerEventsApontamento.GetExecutarServicosSimultaneamenteStatus(
          this.host,
          localStorage.getItem("token"),
          localStorage.getItem("chave_mensageria"),
          this.resource.codigo
        );

      sessionStorage.setItem("ExecutaServicosSimultaneamente", res.data[0]);
    },
  },
};
</script>

<style scoped>
.cards {
  height: 90%;
  /* 26vh 240px */
  color: black;
  background: white;
  margin: 2.5% 3%;
  border-radius: 5px;
  box-shadow: 2px 5px 12px #a0a0a0;
  border: 1px solid #808080;
  transition: 0.5s;
  cursor: pointer;
}

.cards:hover {
  box-shadow: 4px 7px 10px #808080, -1px -1px 5px #808080;
  border: 1.75px solid #808080;
}

.div-conteudo {
  height: 100%;
  display: flex;
}

.img {
  margin: 0 0 0.5rem 9px;
  border-radius: 15%;
  height: 40px;
  width: 40px;
  border: 1px solid #ccc;
  background-size: cover;
  background-position: center center;
}

.maquina_ults_apont {
  margin-left: 5px;
  height: 40px;
  display: flex;
  align-items: center;
}

#linha-1 {
  font-size: clamp(0.4rem, 0.8rem, 1rem);
  padding: 5px 0 5px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#linha-1:hover {
  text-overflow: clip;
  white-space: normal;
}

.conteudoCards {
  width: 100%;
  height: 100%;
}
</style>