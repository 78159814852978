<template>
  <div class="modal fade" id="modal-popup" tabindex="-1" aria-labelledby="modal-title" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="div-mensagem">
          <h1>{{ descricaoModal }}</h1>
        </div>

        <div class="div">
          <button v-if="acaoModal == 'btnsLayoutMobile'" class="btns" @click="CloseModal()">
            Cancelar
          </button>
          <button v-if="acaoModal == 'config'" class="btns" @click="CloseModalSemConexaoServidor()">
            Não
          </button>
          <button v-if="acaoModal == 'config'" class="btns" @click="OnConfig">
            Sim
          </button>

          <div v-if="acaoModal == 'btnsLayoutMobile'" class="conteudoCards-btns">
            <button v-if="!(resource.motivo_parada > 0)" type="button" class="btnModalCard btn btn-dark"
              @click="OnClickMotivosDeParada" style="color: white;">
              MP
            </button>

            <button v-if="resource.motivo_parada > 0" type="button" id="btn-fecharMP" class="btnModalCard btn btn-warning"
              @click="OnClickFechaMotivoParada">
              Fechar MP
            </button>

            <button v-if="!ShowGridApontamentoAberto || resource.motivo_parada > 0" type="button"
              class="btnModalCard btn btn-success" @click="OnClickAbrirApontamento">
              Abrir
            </button>
            <button v-if="resource.motivo_parada == 0" type="button" @click="OnClickFechaApontamento"
              class="btnModalCard btn btn-danger">
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  ResquestGETMotivoDeParada,
  RequestGETTIPOServicos,
  GetChaveConexao,
} from "../../../../../CPSWeb/front-end/services/ApiRequest";
import alertGRV from "../../../../../CPSWeb/front-end/Componentes/alert/alert.vue";
import {
  MensagemErroUsuarioNEncontrado,
  MensagemErroTokenNPreechido,
  MensagemTimeOut,
  JSONFechaApontamentoOS,
  JSONFechaApontamentoProcessoProdutivo
} from "../../../../../CPSWeb/front-end/library/Publics";

import LoadingModal from "../../../../../CPSWeb/front-end/Componentes/Modal/LoadingModal.vue";

export default {
  name: "Popup",
  emits: [
    "UpdateGrid",
    "FuncionarioSelecionado",
    "MaquinaSelecionada",
    "UpdateResourceShowModal",
    "OnHabilitaBtnsCards",
    "OnMotivoDeParada",
    "OnHabilitaDIVPrincipal",
    "OnEvent",
    "OnAbrirMotivoDeParada",
    "OnFecharApontamentoAberto",
  ],
  props: {
    resource: Object,
    tipoAtualizacao: String,
    ShowGridApontamentoAberto: Boolean,
    descricaoModal: String,
    acaoModal: String,
    FuncionarioSupervisor: Boolean,
    host : String
  },

  components: { alertGRV, LoadingModal },

  data() {
    return {
      titleModal: "Motivo de Parada",
      BtnsHabilitados: false,
      DataHora: "",
    };
  },

  mounted() {
  },

  created() {
    
  },

  computed: {
    inicioApontamento() {
      this.FormatDateHours(this.resource.inicio_apontamento);
      return this.DataHora;
    },
  },

  methods: {
    OnConfig() {
      this.CloseModal();
      this.$router.push("/config");
    },
    FormatDateHours(dateHours) {
      const data = dateHours.toLocaleString().substring(0, 5);
      const hours = dateHours.toLocaleString().substring(11, 19);
      this.DataHora = data + " " + hours;
    },

    OnApontamentoPadrao(pChamada) {
      if (localStorage.getItem("estilo_apontamento") == "OS") {
        localStorage.setItem("qtdPecas", 0);

        if (pChamada == "fechar_apontamento" || pChamada == "fechar_mp") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("OS", this.resource.linha3);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }

        if (pChamada == "motivo_parada") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("OS", this.resource.linha3);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("acao", 100);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }
      } else {
        if (pChamada == "motivo_parada") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("acao", 100);
        }

        if (pChamada == "abrir_apontamento") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("acao", 100);
        }

        if (pChamada == "fechar_apontamento" || pChamada == "fechar_mp") {
          localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);
          sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
          sessionStorage.setItem("OS", this.resource.cod_os_completo);
          sessionStorage.setItem("cod_maquina", this.resource.cod_maquina);
          localStorage.setItem("cod_os", this.resource.cod_os);
          sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
        }
      }

      localStorage.setItem("qtdPecas", 0);


    },

    alertErro(error) {
      let mensagemErro = error.response.status + " - ";

      if (
        error.code == "ERR_BAD_REQUEST" &&
        typeof error.response.data.mensagem != "undefined"
      ) {
        mensagemErro = mensagemErro + error.response.data.mensagem;
      }

      if (error.code == "ERR_BAD_RESPONSE") {
        mensagemErro = mensagemErro + error.response.data;
      }

      if (
        mensagemErro == MensagemErroUsuarioNEncontrado ||
        mensagemErro == MensagemErroTokenNPreechido
      ) {
        mensagemErro = mensagemErro + `\n` + "Atualizando token...";
        this.$refs.alert.alertGRV("warning", mensagemErro, this.Modulo);

        GetChaveConexao(this.host,localStorage.getItem("cnpj"))
          .then(this.Login).catch(this.alertErro);
      } else if (mensagemErro == MensagemTimeOut) {
        GetChaveConexao(this.host,localStorage.getItem("cnpj"))
          .then(this.Login).catch(this.alertErro);
      } else {
        if (typeof error.response.data.mensagem === "undefined") {
          mensagemErro =
            mensagemErro +
            "Erro ao atualizar, verifique os '''dados da configuração.";
          this.$refs.alert.alertGRV("warning", mensagemErro, this.Modulo);

          setTimeout(() => {
            this.$router.push("/config");
          }, "3000");
        }
      }
    },

    OnClickAbrirApontamento() {
      sessionStorage.setItem("tp_servico_maquina", this.resource.cod_tp_servico);
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );
      this.OnApontamentoPadrao("fechar_mp");
      this.$emit("OnAbrirMotivoDeParada", this.resource);
      this.CloseModal();
    },

    OnClickMotivosDeParada() {
      sessionStorage.setItem("tp_servico_maquina", this.resource.cod_tp_servico);
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );
      this.$emit("OnHabilitaBtnsCards", true);
      this.$emit("OnHabilitaDIVPrincipal", true);
      this.OnApontamentoPadrao("motivo_parada");


      if (localStorage.getItem("tipoconexao") == "CNPJ") {
        GetChaveConexao(this.host,localStorage.getItem("cnpj"))
          .then(this.GETMotivosDeParada).catch(this.alertErro);
      } else {
        this.GETMotivosDeParadaChave(sessionStorage.getItem("chave"));
      }

      this.CloseModal();
    },

    OnClickFechaMotivoParada() {
      this.OnApontamentoPadrao("fechar_mp");
      sessionStorage.setItem("tp_servico_maquina", this.resource.cod_tp_servico);
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );
      this.$emit("UpdateResourceShowModal", [], "fechar_mp");
      this.CloseModal();
    },

    OnClickFechaApontamento() {
      sessionStorage.setItem("tp_servico_maquina", this.resource.cod_tp_servico);
      sessionStorage.setItem(
        "tp_servicos_selecionado",
        this.resource.cod_tp_servico_apontamento
      );
      let JSONFechamento = []

      if (localStorage.getItem('estilo_apontamento') == "OS") {
        JSONFechamento = JSONFechaApontamentoOS;
      }
      else {
        JSONFechamento = JSONFechaApontamentoProcessoProdutivo;
      }

      this.OnApontamentoPadrao("fechar_apontamento");
      if (this.FuncionarioSupervisor) {
        this.$emit(
          "UpdateResourceShowModal",
          JSONFechamento,
          "modalfechamento"
        );
      } else {
        this.$emit("OnFecharApontamentoAberto");
      }

      this.CloseModal();
    },

    GETTiposDeServico() {
      RequestGETTIPOServicos(
        this.host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave")
      )
        .then(this.afterGetAbrirApontamento)
        .catch(this.alertErro);
    },

    GETMotivosDeParada(res) {
      ResquestGETMotivoDeParada(this.host,localStorage.getItem("token"), res.data)
        .then(this.afterGetMotivosDeParada).catch(this.alertErro);
    },

    GETMotivosDeParadaChave(chave) {
      ResquestGETMotivoDeParada(this.host,localStorage.getItem("token"), chave)
        .then(this.afterGetMotivosDeParada).catch(this.alertErro);
    },

    afterGetMotivosDeParada(res) {
      this.$emit("OnMotivoDeParada", res.data);
    },

    afterGetAbrirApontamento(res) {
      this.$emit("UpdateResourceShowModal", res.data, "tp_servicos");
    },

    CloseModal() {
      let btnCancelar = document.querySelector(".btns");
      this.$emit("OnEvent", "CancelModal");
      btnCancelar.setAttribute("data-bs-dismiss", "modal");
      btnCancelar.click();
    },
    CloseModalSemConexaoServidor() {
      let btnCancelar = document.querySelector(".btns");
      this.$emit("OnEvent", "CancelModalSemConexaoServidor");
      btnCancelar.setAttribute("data-bs-dismiss", "modal");
      btnCancelar.click();
    },
  },

  mounted() { },
  created() { },
};
</script>

<style scoped>
.modal-dialog {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.modal-content {
  height: 40%;
}

.div-mensagem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

h1 {
  font-size: 25px;
  font-family: "Poppins";
  text-align: center;
}

.div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.div-btns {
  width: 50%;
}

.btnModalCard {
  width: 38%;
  margin: 0 0 5px 5px;
  padding: 4% 0;
  font-size: 12px;
}

.conteudoCards-btns {
  width: 50%;
  height: 100%;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns {
  width: 20%;
  background: #004c89;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 1.5% 0;
  margin: 0 0 5px 5px;
  transition: 0.5s;
}

.btns:hover {
  background: #0d6efd;
}

@media (max-width: 480px) {
  .btns {
    font-size: 3vw;
  }
}

@media (orientation: landscape) and (max-height: 480px) {
  .modal-content {
    height: 100%;
  }

  .modal-dialog {
    height: 65vh;
  }
}
</style>