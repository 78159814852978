import { defineStore } from 'pinia'

import { WsClient } from "../../../../../../CommonGRV/Framework/js/WsClient.js"

const grupoWs = 'CPS'

const subgrupoWS = 'APONTAMENTOWEB'

const TipoMensagemUsuarioExpiradoWs = 'msg_tipo_usuario_expirado'

const AcaoMensagemUsuarioExpiradoWs = 'acao_usuario_expirado'

const TipoMensagemGenerica = 'msg_tipo_generica'

const AcaoMensagemGenerica = 'acao_msg_generica'

const AcaoMensagemHome = 'home'

export const useAppConnectionWs = defineStore('appConnectionWs', {
  state: () => ({
    WSClient: null,
    id_client_ws: null,
    msgWs: {
      mensagem: null,
      showModalMsgWs: false
    },
    existe_trava: false
  }),

  getters: {

  },
  actions: {

     onislivemessage() {
      let localStorageObject = {};

      for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);
        let value = localStorage.getItem(key);
        localStorageObject[key] = value;
      }

      return localStorageObject;
    },


    async connectar() {
      if (!this.WSClient) {
        this.WSClient = new WsClient()

        if (localStorage.getItem('id_sessao') != undefined) {
          this.WSClient.IDName = `CLI_APONTAWEB_${localStorage.getItem('chave_mensageria')}_ID-${localStorage.getItem('id_sessao')}`
        } else {
          const guid = this.GUID();
          localStorage.setItem('id_sessao', guid);

          this.WSClient.IDName = `CLI_APONTAWEB_${localStorage.getItem('chave_mensageria')}_ID-${guid}`
        }

        this.WSClient.Grupo = grupoWs;
        this.WSClient.SubGrupo = subgrupoWS;
        
        this.WSClient.connect(0)
        this.WSClient.aorecebermensagem = this.onreceivemessage;
        this.WSClient.onislivemessage =  this.onislivemessage;

        const delay = (ms) =>
          new Promise(resolve => setTimeout(resolve, ms));

        await delay(500);
      }

      if (this.WSClient._Sockets.readyState == 1) {
        this.id_client_ws = this.WSClient.IDName

        this.getcnpj();

        console.log(this.id_client_ws)
      }
    },

    getcnpj(){
      const JsonMessage = {
          NomeOrigem: this.WSClient.IDName,
          NomeDestino: localStorage.getItem("chave_mensageria"),
          Tipo: "CPSWEB.SQL",
          Acao: "SQL.GET",
          Parametro1: "GET_CNPJ",
          Parametro2: "select cgc as cnpj from tempresa where codigo = 1",
          Parametro4: "GET_CNPJ"
      };           

      this.WSClient.SendMessage(JsonMessage)
    },

    onreceivemessage(message) {
      if ((message.Tipo == TipoMensagemUsuarioExpiradoWs) && (message.Acao == AcaoMensagemUsuarioExpiradoWs)) {
        this.msgWs.showModalMsgWs = true
        this.msgWs.mensagem = message.Mensagem
        this.existe_trava = true
      }

      if ((message.Tipo == TipoMensagemGenerica) && (message.Acao == AcaoMensagemGenerica)) {
        this.msgWs.showModalMsgWs = true
        this.msgWs.mensagem = message.Mensagem
      }

      if ((message.Tipo == "CPSWEB.SQL") && (message.Acao == "SQL.RESULT")) {
        const array_cnpj = JSON.parse(message.Mensagem)
        localStorage.setItem("cnpj", array_cnpj[0].replace(/\D/g, ''));

        const json = {
          cnpj: array_cnpj[0].replace(/\D/g, '')
        }

        this.WSClient.InfoAdicional = json;
      }
    },
    GUID() {
      var S4 = function () {
        return Math.floor(
          Math.random() * 0x10000
        ).toString(16);
      };

      return (
        S4() + S4() + S4() + S4()
      );
    }
  }
})