<template>
  <div v-if="!this.resource.aptSimultaneo" class="cols">
    <alertGRV ref="alert" />

    <div style="height: 100%" :id="'Card' + numeroCard">
      <div class="conteudoCards">
        <div class="div-conteudo" @click="abrirDetalhamento">
          <h1 id="linha-1">
            {{ resource.linha1 }}
          </h1>
          <h2 id="linha-2" :class="this.class_linha2">
            {{ resource.linha2 }}
          </h2>

          <h2
            v-if="
              estilo_apontamento == 'PROCESSO_PRODUTIVO' ||
              estilo_apontamento == 'Processo Produtivo'
            "
            id="linha_tipo_servico"
          >
            Tp. Serv.: {{ resource.tiposervico }}
          </h2>

          <h2 id="linha-3">{{ dataEntrega }}</h2>

          <div
            v-if="
              estilo_apontamento == 'PROCESSO_PRODUTIVO' ||
              estilo_apontamento == 'Processo Produtivo'
            "
            style="display: flex"
          >
            <h2 id="linha-4">Nº Proc. Prod.: {{ resource.linha4 }}</h2>

            <h2 id="linha-4" style="margin-left: 30px">
              Nº da Seq.: {{ resource.seq }}
            </h2>
          </div>

          <h2 id="linha-5" :class="this.class_linha5">
            {{ resource.cliente }}
          </h2>
        </div>

        <div v-if="!habilitaAptSimultaneo" class="conteudoCards-btns">
          <button
            type="button"
            class="btn-MP"
            :id="'btn-MP' + resource.codigo"
            @click="OnClickMotivosDeParada"
          >
            MP
          </button>

          <button
            type="button"
            class="btn-abrir"
            :id="'btn-abrir' + resource.codigo"
            @click="OnClickAbrirApontamento"
          >
            Abrir
          </button>

          <button
            v-if="resource.motivo_parada > 0"
            type="button"
            class="btnModalCard btn btn-warning"
            :id="'btn-fecharMP'"
            @click="OnClickFechaMotivoParada"
          >
            Fechar MP
          </button>

          <button
            v-if="resource.motivo_parada == 0"
            type="button"
            @click="OnClickFechaApontamento"
            class="btnModalCard btn btn-danger"
            :id="'btn-fechar' + numeroCard"
          >
            Fechar
          </button>
        </div>
        <div
          v-if="habilitaAptSimultaneo"
          class="conteudoCards-btns"
          style="justify-content: flex-end"
        >
          <button
            type="button"
            class="btn-abrir"
            @click="SelecionaAptSimultaneo"
          >
            Selecionar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { FormatDate } from "../../../../../CPSWeb/front-end/library/utils";
import {
  ResquestGETMotivoDeParada,
  RequestGETTIPOServicos,
  GetChaveConexao,
  RequestListaPDF,
} from "../../../../../CPSWeb/front-end/services/ApiRequest";
import alertGRV from "../../../../../CPSWeb/front-end/Componentes/alert/alert.vue";
import {
  MensagemErroUsuarioNEncontrado,
  MensagemErroTokenNPreechido,
  MensagemTimeOut,
  JSONFechaApontamento,
  HABILITALOGS,
} from "../../../../../CPSWeb/front-end/library/Publics";

import LoadingModal from "../../../../../CPSWeb/front-end/Componentes/Modal/LoadingModal.vue";

export default {
  name: "GridCardsApontamentosServicosVue",
  emits: [
    "UpdateGrid",
    "FuncionarioSelecionado",
    "MaquinaSelecionada",
    "UpdateResourceShowModal",
    "OnHabilitaBtnsCards",
    "OnMotivoDeParada",
    "OnHabilitaDIVPrincipal",
    "OnShowModalTPServicos",
    "OnAbrirApontamento",
    "OnShowDetalhesOS",
    "VimDoCardServicos",
    "AbrindoApontamento",
    "TrocaAcionaMetodonoInput",
    "MostrarInfosCardServicos",
    "OnAtualizandoMotivosDeParada",
    "AddArrayAptSimultaneo",
  ],
  props: {
    resource: Object,
    numeroCard: String,
    tipoAtualizacao: String,
    acionaMetodoNoInput: Boolean,
    class_linha1: String,
    class_linha2: String,
    class_linha5: String,
    host: String,
    habilitaAptSimultaneo: Boolean,
  },
  components: { alertGRV, LoadingModal },
  data() {
    return {
      estilo_apontamento: null,
      titleModal: "Motivo de Parada",
      BtnsHabilitados: false,
      dataParamNotValid: "29/12/1900",
    };
  },

  computed: {
    dataEntrega() {
      let dataEntrega = new Date(this.resource.dt_prevista_os);

      if (
        localStorage.getItem("ordenacao_apontamento") == "DTENTREGAPRIORIDADE"
      ) {
        dataEntrega = new Date(this.resource.dt_prevista_os);
        dataEntrega.setHours(dataEntrega.getHours() + 3);

        if (dataEntrega > new Date(1989, 12, 29)) {
          return `Previsão de entrega: ${FormatDate(
            this.resource.dt_prevista_os.toLocaleString().substring(0, 10)
          )}`;
        } else {
          return "Sem data prevista para entrega.";
        }
      } else {
        dataEntrega = new Date(this.resource.dt_incio_previsto);
        dataEntrega.setHours(dataEntrega.getHours() + 3);

        if (dataEntrega > new Date(1989, 12, 29)) {
          const dataHora = new Date(dataEntrega);
          const dia = dataHora.getDate().toString().padStart(2, "0");
          const mes = (dataHora.getMonth() + 1).toString().padStart(2, "0");
          const ano = dataHora.getFullYear();
          const hora = dataHora.getHours().toString().padStart(2, "0");
          const minuto = dataHora.getMinutes().toString().padStart(2, "0");
          const segundo = dataHora.getSeconds().toString().padStart(2, "0");

          const dataHoraFormatada = `${dia}/${mes}/${ano} ${hora}:${minuto}:${segundo}`;

          return `Previsão de início: ` + dataHoraFormatada;
        } else {
          return "Sem data prevista de início.";
        }
      }
    },
  },

  created() {
    if (!(localStorage.getItem("estilo_apontamento_client") == undefined)) {
      if (localStorage.getItem("estilo_apontamento_client") == "Padrão") {
        this.estilo_apontamento = localStorage.getItem("estilo_apontamento");
      } else {
        this.estilo_apontamento = localStorage.getItem(
          "estilo_apontamento_client"
        );
      }
    } else {
      this.estilo_apontamento = localStorage.getItem("estilo_apontamento");
    }

    if (this.resource.aptSimultaneo != true) {
      this.resource.aptSimultaneo = false;
    }
  },

  methods: {
    SelecionaAptSimultaneo() {
      this.resource.aptSimultaneo = true;

      this.$emit("AddArrayAptSimultaneo", this.resource);
    },

    abrirDetalhamento(e) {
      if (!this.habilitaAptSimultaneo) {
        this.AnimacaoCard(e);

        if (localStorage.getItem("PossuiChaoFabricaSemPapel") == "true") {
          const JsonSend = {
            cod_empresa: this.resource.cod_empresa,
            estilo_apontamento: this.estilo_apontamento,
            cod_os: this.resource.cod_os,
            cod_os_aux: this.resource.cod_os_aux,
            PP: this.resource.linha4,
          };

          this.ShowModalLoadCards = false;

          RequestListaPDF(
            this.host,
            localStorage.getItem("token"),
            sessionStorage.getItem("chave"),
            JsonSend
          )
            .then(this.afterGETListaPDF)
            .catch(this.alertErro);

          this.$emit("VimDoCardServicos", true);
        } else {
          this.$emit(
            "MostrarInfosCardServicos",
            this.resource,
            this.dataEntrega
          );
        }
      } else {
        this.resource.aptSimultaneo = true;

        this.$emit("AddArrayAptSimultaneo", this.resource);
      }
    },

    afterGETListaPDF(res) {
      this.$emit("OnShowDetalhesOS", this.resource, res.data, true);
    },

    OnApontamentoPadrao() {
      sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);
      localStorage.setItem("PROCESSO_PRODUTIVO", this.resource.linha4);

      if(localStorage.getItem("versaoServer") > "3.0.1.13"){
        sessionStorage.setItem("OS", this.resource.cod_os);
        sessionStorage.setItem("COD_OS_COMPLETO", this.resource.cod_os_completo);
      }else{
        sessionStorage.setItem("OS", this.resource.cod_os_completo);
      }

      localStorage.setItem("qtdPecas", 0);      
    },
    
    alertErro(error) {
      console.log(error);

      if (
        !(
          error.response.status == 401 &&
          error.response.data.mensagem ==
            "Sem permissão para o uso desse recurso"
        )
      ) {
        let mensagemErro = error.response.status + " - ";

        if (
          error.code == "ERR_BAD_REQUEST" &&
          typeof error.response.data.mensagem != "undefined"
        ) {
          mensagemErro = mensagemErro + error.response.data.mensagem;
        }

        if (error.code == "ERR_BAD_RESPONSE") {
          mensagemErro = mensagemErro + error.response.data;
        }

        if (
          mensagemErro == MensagemErroUsuarioNEncontrado ||
          mensagemErro == MensagemErroTokenNPreechido
        ) {
          mensagemErro = mensagemErro + `\n` + "Atualizando token...";
          this.$refs.alert.alertGRV("warning", mensagemErro, this.Modulo);

          GetChaveConexao(this.host, localStorage.getItem("cnpj"))
            .then(this.Login)
            .catch(this.alertErro);
        } else if (mensagemErro == MensagemTimeOut) {
          GetChaveConexao(this.host, localStorage.getItem("cnpj"))
            .then(this.Login)
            .catch(this.alertErro);
        } else {
          if (typeof error.response.data.mensagem === "undefined") {
            mensagemErro =
              mensagemErro +
              "Erro ao atualizar, verifique os '''dados da configuração.";
            this.$refs.alert.alertGRV("warning", mensagemErro, this.Modulo);

            setTimeout(() => {
              this.$router.push("/config");
            }, "3000");
          }
        }
      }
    },

    AnimacaoCard(e) {
      let card;

      if (
        e.target.id == "linha-1" ||
        e.target.id == "linha-2" ||
        e.target.id == "linha-3" ||
        e.target.id == "linha-4" ||
        e.target.id == "linha-5"
      ) {
        card = document.getElementById(
          e.target.parentNode.parentNode.parentNode.id
        );
      } else {
        card = document.getElementById(e.target.parentNode.parentNode.id);
      }

      $(card).css("background", "rgba(0, 0, 0, 0.2)");
      $(card).css("animation", "1s");

      setTimeout(function () {
        $(card).css("animation", "1s");
        $(card).css("background", "#fff");
      }, 200);
    },

    AnimacaoBtns(btn) {
      $(btn).css("animation", "1s");
      $(btn).css("opacity", "0.5");

      setTimeout(function () {
        $(btn).css("animation", "1s");
        $(btn).css("opacity", "1");
      }, 200);
    },

    OnClickAbrirApontamento(e) {
      this.AnimacaoBtns("#" + e.target.id);

      localStorage.setItem("cod_os", this.resource.cod_os);
      sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
      sessionStorage.setItem("cod_empresa", this.resource.cod_empresa);

      this.$emit("OnHabilitaBtnsCards", true);
      this.$emit("OnHabilitaDIVPrincipal", true);

      document.querySelector(".btn-abrir").disabled = true;

      this.OnApontamentoPadrao();
      
      if (localStorage.getItem("informar_tipo_servico") == "true") {
        this.GETTiposDeServico();
      } else {
        this.$emit("AbrindoApontamento", true);
        this.$emit("OnAbrirApontamento");
      }
    },

    OnClickMotivosDeParada(e) {
      this.AnimacaoBtns("#" + e.target.id);

      localStorage.setItem("cod_os", this.resource.cod_os);
      sessionStorage.setItem("cod_os_aux", this.resource.cod_os_aux);
      this.$emit("OnHabilitaBtnsCards", true);
      this.$emit("OnHabilitaDIVPrincipal", true);
      this.OnApontamentoPadrao();
      this.$emit("OnAtualizandoMotivosDeParada", true);
      this.GETMotivosDeParada();
    },

    OnClickFechaMotivoParada(e) {
      this.AnimacaoBtns("#" + e.target.id);

      this.OnApontamentoPadrao();
      this.$emit("UpdateResourceShowModal", [], "fechar_mp");
    },

    OnClickFechaApontamento(e) {
      this.AnimacaoBtns("#" + e.target.id);

      this.OnApontamentoPadrao();
      this.$emit(
        "UpdateResourceShowModal",
        JSONFechaApontamento,
        "modalfechamento"
      );
    },

    GETTiposDeServico() {
      RequestGETTIPOServicos(
        this.host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave")
      )
        .then(this.afterGetTpServicos)
        .catch(this.alertErro);
    },

    afterGetTpServicos(res) {
      this.$emit("OnShowModalTPServicos", res.data);
    },

    GETMotivosDeParada() {
      ResquestGETMotivoDeParada(
        this.host,
        localStorage.getItem("token"),
        sessionStorage.getItem("chave")
      )
        .catch(this.alertErro)
        .then(this.afterGetMotivosDeParada);
    },

    afterGetMotivosDeParada(res) {
      this.$emit("OnAtualizandoMotivosDeParada", false);
      this.$emit("OnMotivoDeParada", res.data, "apontamento_normal");
    },
  },
};
</script>

<style scoped>
.cols {
  margin: 10px 0;
}

.conteudoCards {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 2px 5px 12px #a0a0a0;
  border: 1px solid #808080;
  transition: 0.5s;
  /* margin: 2%; */
}
.div-conteudo {
  cursor: pointer;
  width: 100%;
  height: auto;
}

#linha-1,
.linha-1 {
  font-size: clamp(0.7rem, 0.3rem + 1vw, 1vw);
  margin: 5px 0 5px 9px;
  overflow: hidden;
  word-wrap: break-word;
  font-weight: 900;
  font-family: "Poppins";
  color: blue;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.div-linha i {
  margin-right: 5px !important;
}

.div-linha .linha-1,
.linha-2 {
  display: -webkit-box;
  overflow: hidden;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  color: black;
}

#linha-2,
.linha-2,
#linha_tipo_servico,
#linha-3,
#linha-4,
#linha-seq,
#linha-5 {
  font-size: clamp(0.7rem, 0.3rem + 1vw, 1vw);
  margin: 0 0 5px 9px;
  word-wrap: break-word;
  overflow: hidden;
  font-weight: 900;
  font-family: "Poppins";

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  color: black;
}

#linha-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  color: black;
}

#linha-5 {
  margin: 0 0 0 9px;
}

.btnModalCard {
  width: 38%;
  margin: 0 5px;
  height: 100%;
  font-size: 12px;
}

.conteudoCards-btns {
  width: 100%;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-MP,
.btn-abrir {
  width: 38%;
  margin: 0 5px;
  height: 100%;
  font-size: 12px;
  padding: 6px 12px;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.btn-MP {
  background: black;
}

.btn-abrir {
  background: #157347;
}

@media handheld and (orientation: portrait) {
  #linha-1,
  #linha-2,
  #linha_tipo_servico,
  #linha-3,
  #linha-4,
  #linha-seq,
  #linha-5 {
    font-size: clamp(0.6rem, 0.2rem + 1vw, 3vw);
  }
}

@media handheld and (max-width: 480px), (max-height: 480px) {
  .conteudoCards-btns {
    margin-bottom: 10px;
  }

  #linha-1 {
    margin: 5px 0 0.3rem 9px;
  }

  #linha-2,
  #linha_tipo_servico,
  #linha-3,
  #linha-4,
  #linha-seq {
    margin: 0 0 0.3rem 9px;
  }

  #linha-5 {
    margin: 0 0 0 9px;
  }
}
</style>