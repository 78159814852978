<template>
    <v-dialog v-model="wsMensagem.showModalMsgWs" activator="parent" persistent style="width: 40%;">
        <v-card border="rounded-xl" height="100%">
            <v-row class="mt-5 mb-5 justify-center"><small style="font-size: 30px">{{ wsMensagem.mensagem
                    }}</small></v-row>

            <v-col cols="12">
                <v-btn width="100%" color="#004c89" height="40"@click="OnClickOnMessage" style="color: white;font-size: 25px">OK</v-btn>
            </v-col>
        </v-card>
    </v-dialog>
</template>

<script>

import { useAppConnectionWs } from "../../store/appConnectionWs"

export default {
    name: "ModalMensagemWsClient",
    watch: {

    },
    props: {

    },
    data() {
        return {
            dialog: false,
            SegLoads: 0
        }
    },
    computed: {
        wsMensagem() {
            return useAppConnectionWs().msgWs
        }
    },
    methods: {
        OnClickOnMessage() {
            useAppConnectionWs().msgWs.showModalMsgWs = false
        }
    },
    created() {

    },
    mounted() {

    },
}
</script>

<style>
.mp-modal-content {
    background-color: white;
    padding: 1vw 3vw;

}
</style>