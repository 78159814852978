<template>
    <v-row justify="space-around" style="width: 100%">
        <v-col cols="auto">
            <v-dialog transition="dialog-bottom-transition" width="100%" persistent v-model="showData">
                <v-card>
                    <h1 style="text-align: center; width: 80%; margin: 0 auto;">Qual tipo de finalização de apontamento você deseja?</h1>

                    <v-card-actions class="justify-center" style="margin: 20px 0;">
                        <v-btn class="btnModalOK" variant="text"
                        style="background-color: #157347; color: white; width: 35%; height: 80px; font-weight: bold; 
                        margin-right: 50px"
                            @click="this.$emit('FinalizarApontamentoAgrupadoCompleto')">Completa</v-btn>

                        <v-btn class="btnModalOK" style="background-color: #ffca2c; color: black; width: 35%; height: 80px; font-weight: bold;" variant="text"
                            @click="this.$emit('AbrirAbaFinalizarApontamentoAgrupadoParcial')">Parcial</v-btn>
                    </v-card-actions>

                    <v-card-actions class="justify-center">
                        <v-btn class="btnModalOK" style="background-color: #004c89; color: white; width: 30%; height: 60px; font-weight: bold;" variant="text"
                            @click="this.$emit('FecharModalFinalizar')">Cancelar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            showData : false,
        }
    },

    props: {
        show: Boolean,
    },

    watch: {
        show (newValue) {
            this.showData = newValue;
        }
    },

    emits: ["FecharModalFinalizar", "FinalizarApontamentoAgrupadoCompleto", "AbrirAbaFinalizarApontamentoAgrupadoParcial"],

    methods: {

    }
}
</script>

<style>

</style>