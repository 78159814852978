<template>
    <v-app>
        <v-main>
            <v-dialog v-model="dialog" activator="parent" persistent style="width: 40%;">
                <div class="mp-modal-content">
                    <h1>
                        Atualizando motivos de parada...
                    </h1>
                    <p>Aguarde.. {{ tempoDecorrido }}</p>
                </div>
            </v-dialog>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "ModalLoadMP",
    emits : ["OnResetMP"],
    watch: {
        showMP(newValue) {
            if (!newValue){
                this.SegLoads = 0;
            }
            this.dialog = newValue;
        },
        SegLoads(newValue){
            if (newValue > 20) {
                this.$emit("OnResetMP")
            }
        }
    },
    props: {
        showMP: Boolean
    },
    data() {
        return {
            dialog: false,
            SegLoads: 0
        }
    },
    computed: {
        tempoDecorrido() {
        
            return (20 - this.SegLoads)
            
        },
    },
    methods: {
        incrementarSegundos() {
            this.SegLoads++;
        }
    },
    created() {
        this.dialog = this.showMP;
    },
    mounted() {
        // Atualiza os segundos a cada segundo
        setInterval(this.incrementarSegundos, 1000);
    },
}
</script>

<style>
.mp-modal-content {
    background-color: white;
    padding: 1vw 3vw;
    border-radius: 1vw;
}
</style>